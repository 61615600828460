import React from 'react';
import PostsByCategoryItem from 'molecules/postsByCategoryItem/PostsByCategoryItem';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';

const PostsByCategoryItemList: React.FC = () => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const [categories] = useAllPrismicDocumentsByType('post_category', {
    lang: getPrismicLanguage(languageCode),
  });

  return (
    <>
      {
        categories?.map((category)=>{
          return(
            <PostsByCategoryItem
              key={category.id}
              categoryId={category.id}
              category={category.data.name}
            />
          )
        })
      }
    </>
  )
}

export default PostsByCategoryItemList