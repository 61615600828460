import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs} from 'react-pdf'
import { PdfButtonWrapper, PdfModal, PdfWrapper } from 'screens/certifications/Certifications.components'
import {CaretCircleRight, CaretCircleLeft, X} from 'phosphor-react';
import { whiteColor } from 'theme';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { PdfModalViewerProps } from './PdfModalViewer.types';
import { PDF_FILE } from 'screens/certifications/Certifications';
import { Breakpoints } from 'src/theme/breakpoints';
const pdfIntegral = require('../../../assets/pdf/certificado_200093.pdf');
const pdfIso37001 = require('../../../assets/pdf/certificado_37001.pdf');
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PdfModalViewer: React.FC<PdfModalViewerProps> = ({pdfFile, setIsShowPdf}) => {
  const [numPages, setNumPages] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
  const [pageWidth, setPageWidth] = useState(0);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  
  useEffect(() => {
    const mediaWatcher = window.matchMedia(`(min-width: ${Breakpoints.lg})`);
    setIsMobile(mediaWatcher.matches);
    
    function updateIsNarrowScreen(e: MediaQueryListEvent) {
      setIsMobile(e.matches);
    }
    mediaWatcher.addEventListener('change', updateIsNarrowScreen)

    // clean up after ourselves
    return function cleanup() {
      mediaWatcher.removeEventListener('change', updateIsNarrowScreen)
    }
  }, []);

  const pdfWrapperRef = useRef<any>(null);

  useLayoutEffect(() => {
    if (isMobile) {
      setPageWidth(window.innerWidth / 3);
    } else {
      setPageWidth(window.innerWidth / 1.3);
    }
  }, [isMobile]);
  
  const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
		setNumPages(numPages);
	};

  const goToPrevPage = () => {
    const prevPage = pageNumber - 1 <= 1 ? 1 : pageNumber - 1;
		setPageNumber(prevPage);
  }

	const goToNextPage = () => {
    const nextPage = pageNumber + 1 >= numPages ? numPages : pageNumber + 1
		setPageNumber(nextPage);
  }

  return (
    <PdfModal>
      <PdfWrapper ref={pdfWrapperRef}>
        <PdfButtonWrapper className='close-wrapper'>
          <X className='btnClose' onClick={() => {setIsShowPdf(false)}} color={whiteColor} />
        </PdfButtonWrapper>
        {/* <PdfButtonWrapper className='prev-wrapper'>
          <CaretCircleLeft className='btnPrev' onClick={goToPrevPage} size={64} color={whiteColor} />
        </PdfButtonWrapper>
        <PdfButtonWrapper className='next-wrapper'>
          <CaretCircleRight className='btnNext' onClick={goToNextPage} size={64} color={whiteColor} />
        </PdfButtonWrapper> */}
        <Document
          className='pdf-document'
          file={pdfFile === PDF_FILE.INTEGRAL ? pdfIntegral : pdfIso37001}
          onLoadSuccess={onDocumentLoadSuccess}
          onPassword={(cb) => cb(process.env.REACT_APP_PDF_PASSWORD)}
        >
          <Page
            pageNumber={pageNumber}
            width={pageWidth}
          />
        </Document>
      </PdfWrapper>
    </PdfModal>
  )
}

export default PdfModalViewer