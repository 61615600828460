import React from 'react';
import ScreenHeader from 'molecules/screenHeader/ScreenHeader';
import ImageContent from 'molecules/ImageContent/ImageContent';
import { Caption, SectionTitle, SText } from 'atoms/text/Text';
import EolicSector from 'organisms/eolicSector/EolicSector';
import WorkingEnergy from 'organisms/workingEnergy/WorkingEnergy';
import { imgEnergiaRenovable, imgEnergiaSolar, imgMantenimientoEolico, imgNosotrosHeader, imgPanelesSolares } from 'images';
import { SectionName } from 'custom-types/design/molecules/ScreenHeader';
import ImageWithContent from 'molecules/imageWidthContent/ImageWithContent';
import { useNavigate } from 'react-router-dom';
import { secondaryColor, backgroundGrayColor, whiteColor, Spacing, ColorWhite, backgroundLightColor } from 'theme';
import { PATH_NAMES } from 'organisms/mainMenu/MainMenu.types';
import { isMobileDevice } from '../../utils/isMobileDevice';
import { useTranslation } from 'react-i18next';


const About: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const isMobile = isMobileDevice();

  const handleNavigate = ()=> {
    navigate(PATH_NAMES.CERTIFICATIONS);
  }

  return (
    <>
      <ScreenHeader 
        image={imgMantenimientoEolico}
        title={t('about.header.title')}
        subtitle={t('about.header.subtitle')}
        sectionName={SectionName.NOSOTROS}
      />
      <ImageContent
        isSwitchMobile
        imageId='about-us'
        isShowDecorationLine={false}
        imagePlace='left'
        image={imgPanelesSolares}
        title={
          <div style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}>
            <Caption>
              {t('about.boundsTrust.caption')}
            </Caption>
            <SectionTitle>
              {t('about.boundsTrust.title')}
            </SectionTitle>
          </div>
        }
        content={
          <>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('about.boundsTrust.paragraph1')}
            </SText>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('about.boundsTrust.paragraph2')}
            </SText>
          </>
        }
      />
      <ImageContent
        isSwitchMobile
        imageId='our-vision'
        isFlexReverse
        isShowDecorationLine={false}
        backgroundColorSection={backgroundLightColor}
        imagePlace='right'
        image={imgEnergiaSolar}
        title={
          <div style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}>
            <Caption>
              {t('about.ourVision.caption')}
            </Caption>
            <SectionTitle>
              {t('about.ourVision.title')}
            </SectionTitle>
          </div>
        }
        content={
          <>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('about.ourVision.paragraph1')}
            </SText>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('about.ourVision.paragraph2')}
            </SText>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('about.ourVision.paragraph3')}
            </SText>
          </>
        }
      />      
      <EolicSector />
      <WorkingEnergy />
      <ImageWithContent
        isSwitchMobile
        imageId='sustentabilidad'
        isAnimated={false}
        backgroundColorContent={backgroundGrayColor}
        placeImage='left'
        image={imgEnergiaRenovable}
        title={
          <div style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}>
            <Caption color={whiteColor}>
              {t('about.sustainability.caption')}
            </Caption>
            <SectionTitle
              color={ColorWhite}
            >
              {t('about.sustainability.title1')} <br /> 
            <SectionTitle color={secondaryColor}>
              {t('about.sustainability.title2')}
              </SectionTitle>
            </SectionTitle>
          </div>
        }
        content={
          <>
            <SText
              color={ColorWhite}
              style={{marginTop: Spacing.xLargeSpacing, marginBottom: Spacing.xxxxLargeSpacing}}
            >
              {t('about.sustainability.paragraph1')}
            </SText>
          </>
        }
        showButton
        textButton={t('about.sustainability.buttonText')}
        onPressButton={handleNavigate}
      />
    </>
  )
}

export default About