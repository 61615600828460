import React from 'react';
import { SText } from 'atoms/text/Text';
import { CatgeoryLabelProps } from 'custom-types/design/atoms/CategoryLabel';
import { CategoryLabelWapper } from './CatgeoryLabel';
import { ColorWhite } from 'theme';

const CategoryLabel: React.FC<CatgeoryLabelProps> = ({category, typePostItem}) => {
  return (
    <CategoryLabelWapper
      typePostItem={typePostItem}
    >
        <SText
            color={ColorWhite}
            fontWeight='bold'
        >
            {category}
        </SText>
    </CategoryLabelWapper>
  )
}

export default CategoryLabel