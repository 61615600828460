import ScreenHeader from 'molecules/screenHeader/ScreenHeader'
import React from 'react'
import { SectionName } from '../../types/design/molecules/ScreenHeader';
import { projectsExperienceImg, projectsImgHeader } from 'images';
import ImageContent from 'molecules/ImageContent/ImageContent';
import { Spacing,  secondaryColor } from 'theme';
import { Caption, SText, SectionTitle } from 'atoms/text/Text';
import OurExperienceInTheWorld from './components/ourExperienceInTheWorld/OurExperienceInTheWorld';
import { isMobileDevice } from 'utils/isMobileDevice';
import { useTranslation } from 'react-i18next';

const ProjectsScreen: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <>
      <ScreenHeader 
        image={projectsImgHeader}
        title={t('projects.header.title')}
        subtitle={t('projects.header.subtitle')}
        sectionName={SectionName.PROYECTOS} />
      <ImageContent
        isSwitchMobile
        imageId='projects-experience'
        isShowDecorationLine={false}
        imagePlace='right'
        image={projectsExperienceImg}
        title={
          <div style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}>
            <Caption>
              {t('projects.ourExperience.caption')}
            </Caption>
            <SectionTitle>
              {t('projects.ourExperience.title1')} <span style={{color: secondaryColor}}>{t('projects.ourExperience.title2')}</span>
            </SectionTitle>
          </div>
        }
        content={
          <>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('projects.ourExperience.paragraph1')}
            </SText>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
               {t('projects.ourExperience.paragraph2')}
            </SText>
            <SText
              style={{marginBottom: Spacing.baseSpacing}}
            >
              {t('projects.ourExperience.paragraph3')}
            </SText>
          </>
        }
      />
      <OurExperienceInTheWorld />
    </>
  )
}

export default ProjectsScreen