import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { strongGrayColor, Spacing } from "theme";

export const ChangingWrapper = styled.section.attrs((props)=>({
    className: 'changing-wrapper'
}))`
    background: ${strongGrayColor};
    padding: 100px 0;
`;

export const ChangingHolder = styled.div.attrs((props)=>({
    className: 'changing-holder'
}))`
    display: flex;

    @media (max-width: ${Breakpoints.lg}){
        flex-direction: column;
    }
`;

export const ChangingColumn = styled.div.attrs((props)=>({
    className: 'changing-holder'
}))`
    flex: 1;

    @media (max-width: ${Breakpoints.lg}){
        padding: ${Spacing.baseSpacing};
    }
`;

export const VideoWrapper = styled.div.attrs((props)=>({
    className: 'video-wrapper'
  }))`
  
    iframe{
      width: 387px;
      height: 231px;
    }

    @media (max-width: ${Breakpoints.lg}){
        iframe{
            width: 100%;
            height: 400px;
        }
    }
  `;