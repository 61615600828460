import { useTranslation } from "react-i18next";

export const useContactFormController = () => {
  const { t } = useTranslation();
  const contactSubjects = [
    t("contact.contactForm.subjects.subject1"),
    t("contact.contactForm.subjects.subject2"),
    t("contact.contactForm.subjects.subject3"),
    t("contact.contactForm.subjects.subject4"),
    t("contact.contactForm.subjects.subject5"),
    t("contact.contactForm.subjects.subject6"),
    t("contact.contactForm.subjects.subject7"),
  ];

  return { 
    contactSubjects
  }
};