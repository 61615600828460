export const languageResources = {
  en: {
    translation:  {
      mainMenu: {
        labels: {
          home: 'Home',
          about: 'About',
          aboutUs: "About us",
          certifications: "Certifications",
          projects: "Projects",
          services: "Services",
          windFarms: "Wind farms",
          solarParks: "Solar parks",
          electricalInfrastructure: "Electrical infrastructure",
          meteorologicalTowers: "Meteorological towers",
          careers: "Careers",
          professionalProfiles: "Professional profiles",
          contact: "Contact",
          blog: "News",
        }
      },
      home: {
        building: {
          title: "Building the future of renewable energy",
          paragaph1: "We offer customized solutions and services about wind and solar power energy, electrical Infrastructure, meteorological towers, design and construction of wind and solar farms, scheduled service and corrective maintenance in wind and soler farms and decommissioning and disassembly old wind farms. We work around North, Central and South America and Europe.",
          paragaph2: "We are ISO 9001, ISO 14001, ISO 45001 and ISO 37001 certified.",
          buttonText: "About us",
        },
        whatWeDo: {
          title: 'Committed to the world and to you',
          paragraph1: 'Our first aim is to provide the best service for each of our customers, meeting their expectations and requirements about quality, terms, schedule and budget. We work in permanent contact with each customer by means of evidence, reports, meeting and audits.',
          paragraph2: ' It Is very important for us that our customers feel confidence when their needs (construction, service, maintenance) are in our hands. To achieve these goals our company have technical, logistic and administrative teams with wide experience and customer service commitment.',
        },
        ourServices: {
          title: 'Our services',
          paragraph: 'We meet your needs around renewable energy sector. We can deal with your project: design, construction, erection, electrical infrastructure, service and maintenances.',
          services: {
            windFarms: 'Wind farms',
            solarFarms: 'Solar farms',
            electricalInfrastructure: 'Electrical infrastructure',
            meteorologicalTowers: 'Meteorological towers',
          },
        },
        moveTheWorld: {
          title1: 'We move the world',
          title2: 'with tailwind',
          paragraph1: "To give our customers confidence is very important for us. Thus, we encourage, and carry out, good practices in our day-to-day activities.",
          paragraph2: " In REYCO we try hard every day to develop and implement improvements which optimize our performance. We believe strongly that following these principles we will prevail in the renewable energy market as highly competitive company.",
          buttonText: "Read more",
        },
        valueAdded: {
          caption: 'ADDED VALUE',
          title: 'We are a united workforce',
          paragraph1: "The most important thing for us is the security and well-being of our staff and we take proper care of parts, equipment and tools used in our projects, in order to minimize risks and delays. Additionally, REYCO is a ISO9001 certified company.",
          paragrpah2: "Finally, our environmental commitment is the basis of our work, trying to minimize environmental impacts and confirming renewable energy as the main power source around the world.",
          paragraph3: "",
        },
        clients: {
          caption: 'CLIENTS',
          title1: 'Companys that have their',
          title2: 'trust',
          title3: 'in us',
        },
        aboutReyco: {
          title: 'About Reyco',
          paragraph1: "Immerse you in the renewable power world and know about our view of a greener and clean world. We are experts in design, construction, erection, electrical infrastructure, service, maintenance and communications. Please, unload our brochure here and you will know better about our services.",
          buttonText: "Read more",
        },
        newsletter: {
          title1: 'Subscribe to our',
          title2: 'newsletter',
          paragraph1: "If you wish to be up to date about REYCO activity and the more important news in the renewable power world, please subscribe our newsletter.",
          acceptTyC: "I accept terms and conditions.",
          buttonText: "Subscribe",
          inputText: "Email",
          emailRequired: "Email is required.",
          emailInvalid: "Write a valid email.",
          termsRequired: "You must accept terms and conditions.",
          emailRegisteredSucess: "Your email has been registered successfully.",
          emailNotRegistered: "Your email has not been registered, try again later.",
        },
        latestNews: {
          title1: 'Our',
          title2: 'News',
          buttonText: "Read more",
        },
      },
      brochure: {
        title: "About Reyco",
        paragraph1: "Immerse yourself in the world of renewable energy and its importance, as well as our vision of a greener and cleaner world. Download our brochure here.",
        buttonText: "Download",
      },
      about: {
        header: {
          title: "About us",
          subtitle: "About us",
        },
        boundsTrust: {
          caption: 'ABOUT US',
          title: "We create bonds of trust with our clients",
          paragraph1: "Our company has 15 years of experience in the renewable energy sector. We stand looking for solutions for our customers´ requirements, who want personalized attention, efficiency and commitment.",
          paragraph2: " Since starting of the company, we have created bonds of trust, maintaining these along the time. So, we have been selected to develop and maintain different projects regarding renewable energy in many countries in the Americas and Europe. Today we are so proud that REYCO is a leading company in this sector."
        },
        ourVision: {
          caption: 'COMPANY’S VISION',
          title: "We want to remain leading the sector",
          paragraph1: "We are able to meet the needs of our customers. We offer competitive services and solutions, always meeting work safety, health, quality and environmental regulations as well as the customer requirements.",
          paragraph2: " In every project, in every country, all our personnel is committed to create bond of trust. Our mission is to provide the best local service to global customers. We are working hardly to reach more countries, training more skilled technical staff with the local and international qualifications.",
          paragraph3: " We are very confident that these commitment and dedication we will reach more customers, which will rely on our company as present customers do.",
        },
        eolicSector: {
          caption: 'KNOW US',
          title: "About the company",
        },
        workingEnergy: {
          title1: "We work to provide",
          title2: "integral solutions",
          items: {
            building: {
              title: 'Wind-farm building',
              text: 'More than fifteen years of experience building wind farms around the world.',
            },
            solutions: {
              title: 'Integral solutions',
              text: 'Innovation, industry knowledge, technical experience and effective communication.',
            },
            certifications: {
              title: 'Certifications',
              text: 'Garantizando excelencia en nuestro servicio y compromiso con la calidad, la gestión ambiental, la gestión de seguridad y salud ocupacional y la gestión antisoborno.',
            },
            technicalService: {
              title: 'Technical services',
              text: 'Major and minor maintenance. General overhauling, specific repairs, etc.',
            },
          },
        },
        sustainability: {
          caption: 'SUSTENTABILITY',
          title1: "Big actions.",
          title2: "Huge results.",
          paragraph1: "We are committed to you, ours workers and the environment. Our commitments are backed by ISO 9001, 14001, ISO 45001 and ISO 37001 quality seals.",
          buttonText: "Read more",
        },
      },
      certifications: {
        header: {
          title: "Our",
          subtitle: "Certifications",
        },
        weAreReyco: {
          title1: "We are Reyco.",
          title2: "A sustainable company.",
          paragraph1: "At REYCO, we believe that business progress and social responsibility go hand in hand. That’s why we have enthusiastically embraced the world’s most rigorous quality, sustainability and ethical standards, becoming certified under the prestigious ISO 9001, ISO 14001, ISO 45001 and ISO 37001.",
          paragraph2: " By obtaining and maintaining these certifications, we not only demonstrate our commitment to quality, sustainability, safety and ethics, but also contribute to improving the world. We help reduce pollution, promote workplace safety, fight bribery and set high standards for the entire industry.",
          paragraph3: " At REYCO, we see these certifications as our duty and our way of making a difference in the world. We believe that every step we take toward excellence and responsibility contributes to a brighter, more sustainable future for all.",
          paragraph4: "Our commitment to quality, the environment and health and safety is recognized in the following certifications:",
        },
        sustainability: {
          title1: "Sustainability",
          title2: "ISO 14001",
          paragraph1: "Certification to the international ISO 14001 standard supports our passion for environmental care and sustainability. We are aware that the wind energy industry must lead the transition to a cleaner and more sustainable future. With this certification, we have established robust environmental management systems that minimize our impact on nature, reducing our carbon footprint and promoting sustainable practices in everything we do.",
        },
        commitment: {
          title1: "Commitment",
          title2: "ISO 9001",
          paragraph1: "Certification to the ISO 9001 international standard reflects our commitment to excellence in quality management. This means that each of our services and projects we undertake is executed with precision and efficiency. Our goal is to exceed our clients’ expectations at every step, ensuring the highest quality results.",
        },
        security: {
          title1: "Security",
          title2: "ISO 45001",
          paragraph1: "The safety and health of our team and everyone involved is our number one priority. Certification to ISO 45001 demonstrates our unwavering commitment to occupational health and safety. Every day, we work tirelessly to ensure that our operations are safe and that our employees go home safe and sound. We believe that there can be no business success without ensuring the safety and health of our employees.",
        },
        ethics: {
          title1: "Business Ethics",
          title2: "ISO 37001",
          paragraph1: "Finally, certification to the international ISO 37001 standard places us at the forefront of the fight against bribery and corruption. We believe that integrity is essential in any business, and this certification supports us in our pursuit of ethical and transparent operations.",
        },
      },
      projects: {
        header: {
          title: "Projects",
          subtitle: "Our experience",
        },
        ourExperience: {
          caption: 'OUR EXPERIENCE',
          title1: "We are an international company",
          title2: "in the energy sector",
          paragraph1: "We have participated in projects around the world, from the U.S.A to Argentina, passing through Mexico, Chile, Costa Rica, Guatemala, the Dominican Republic, Nicaragua and Uruguay.",
          paragraph2: " During the last two years, we have participated in the following parks installing and maintaining technologies such as: Goldwind, Senvion, SiemensGamesa, Suzlon, Clipper, Vestas.",
          paragraph3: "Reyco is committed to continually improving its service, as well as reducing its environmental impact.",
        },
        inTheWorld: {
          title: "Our experience in the world",
          paragraph1: "Experience has allowed us to develop protocols that meet the most demanding standards. For us, distance does not prevent us from providing excellence in our service to each of our clients.",
        },
      },
      presenceCountries: {
        mexico: "MEXICO",
        argentina: "ARGENTINA",
        chle: "CHILE",
        guatemala: "GUATEMALA",
        dominicanRepublic: "DOMINICAN REPUBLIC",
        uruguay: "URUGUAY",
        usa: "USA",
        spain: "SPAIN",
        peru: "PERU",
        colombia: "COLOMBIA",
      },
      windFarms: {
        header: {
          title: "Our",
          subtitle: "Services",
        },
        subHeader: {
          caption: "OUR SERVICES",
          title1: "Infrastructure and development",
          title2: "wind farms",
        },
        mantto: {
          title: "Maintenance",
          paragraph1: "At Reyco we know that a correct maintenance of the wind turbine is one of the best strategies for the useful life and productivity of the equipment. For this reason, we have certified equipment and tools with the latest technology, which allow us to carry out more efficient work.",
          paragraph2: "Years in the sector have allowed us to create a committed and trained work team in scheduled and preventive maintenance",
          list1: {
            title: "Corrective maintenance",
            item1: "Main Correctives as. Main components replacement (Blades, gearbox, transformator, nacelle…)",
            item2: "Small correctives. Service lift ans life line correctives and inspection, bolt replacement, broken bolt extraction",
          },
          list2: {
            title: "​​Preventive",
            item1: "Bolt torque inspetion, Service lift anual inspection, oil change.",
          },
        },
        installation: {
          title: "Wind turbine installation",
          paragraph1: "More than 20 years of experience in the sector, allows us to offer clients, regardless of the technology to be installed, highly qualified teams for the installation of wind turbines anywhere in the world.",
          paragraph2: "Knowledge of everything that a project implies has led us to be an option for consulting and execution of the other phases of the project. Offering solutions to our clients in managing the entire process, from civil works, cranes or commissioning, or supervision if required.",
        },
        supplies: {
          title: "Supplies",
          paragraph1: "We help you get the equipment you need, where you need it. We take care from the on-site review for the application, to the installation and change of it in the facilities.",
          list1: {
            item1: "Cranes, transport and machinery for the execution of the works.",
            item2: "Supply of facilities.",
            item3: "Supply of hydraulic and manual tools.",
            item4: "Supply of components.",
            item5: "Supply of spare parts for elevators, lifelines and ladders.",
          },
        },
        supervision: {
          title: "Supervision and management",
          paragraph1: "We offer advice and follow-up on all your projects with professional and qualified personnel. We cover all your logistics needs for the optimal development of your wind projects.",
          list1: {
            title: "Supervision",
            item1: "Supervision for the assembly of wind turbines. (installation supervisor)",
            item2: "Quality supervision for transport, logistics and stock keeper.",
            item3: "Supervision of safety, quality and environment.",
          },
          list2: {
            title: "Management",
            item1: "NCR management.",
            item2: "Management of subcontractors.",
            item3: "Management for the reception of materials and logistics.",
            item4: "Management of certifications and permits needed.",
            item5: "Personalized management and audits.",
          },
        },
        waste: {
          title: "Waste Management",
          paragraph1: "We provide our customers the waste management service. Our service and waste management processes are carried out in accordance with government and environmental regulations.",
          list1: {
            item1: "Destruction of blades.",
            item2: "Waste Recycling.",
            item3: "Disassembly of Wind turbines.",
          },
        },
      },
      solarFarms: {
        header: {
          title: "Our",
          subtitle: "Services",
        },
        subHeader: {
          caption: "OUR SERVICES",
          title1: "Infrastructure and development of",
          title2: "solar parks",
        },
        mantto: {
          title: "Maintenance",
          paragraph1: "Correct maintenance of solar parks is one of the best strategies for the useful life and productivity of the equipment. For this reason, we have certified equipment and tools with the latest technology, which allow us to carry out more efficient work.",
          list1: {
            title: "Correctives",
            item1: "Change of solar panels.",
            item2: "Changes of fixed and mobile structures.",
            item3: "Detection and correction of faults in wiring and connections.",
            item4: "Detection and correction in calibration.",
            item5: "Detection and correction of electronic failures of internal components.",
            item6: "Detection and correction of faults in cells or in a group of cells.",
          },
          list2: {
            title: "Preventive",
            item1: "Scheduled review of panels and trackers.",
            item2: "Module cleaning.",
          },
        
        },
        installation: {
          title: "Installation",
          paragraph1: "Knowledge of everything that a project implies has led us to be an option for consulting and execution of the other phases of the project. Offering solutions to our clients in managing the entire process, from civil works, cranes or commissioning, or supervision if required.",
          list1: {
            item1: "Installation of modules.",
            item2: "Installation of solar panels.",
          },
        },
        supplies: {
          title: "Supplies",
          paragraph1: "We help you get the equipment you need for your solar park. We take care from the on-site review for the application, to the installation and change of it in the facilities.",
          list1: {
            item1: "Panels.",
            item2: "Modules.",
            item3: "Inverters.",
            item4: "Tools.",
            item5: "Facilities.",
          },
        },
        supervision: {
          title: "Supervision and management",
          paragraph1: "We monitor all your projects with professional and qualified personnel, in order to cover all the needs of the project",
          list1: {
            title: "Supervision",
            item1: "Supervision for installation of solar parks.",
            item2: "Quality supervision for transport, logistics and stock keeper review.",
            item3: "Supervision of safety, quality and environment",
          },
          list2: {
            title: "Management",
            item1: "NCR management.",
            item2: "Management of subcontractors.",
            item3: "Management for the reception of materials and logistics.",
            item4: "Management of certifications and permits needed.",
            item5: "Personalized management and audits.",
          },
        
        },
      },
      electricalInfrastructure: {
        header: {
          title: "Our",
          subtitle: "Services",
        },
        subHeader: {
          caption: "OUR SERVICES",
          title1: "Electrical",
          title2: "infrastructure",
        },
        infrastructure: {
          title: "Infrastructure",
          list1: {
            item1: "Electrical installations.",
            item2: "Cooling systems.",
            item3: "Design and development in industrial systems.",
            item4: "Luminaire and electromechanical systems.",
            item5: "Fire protection systems.",
            item6: "Fire alarms, fire extinguishers, detection systems, dry columns, gas detectors, sirens.",
          },
          list2: {
            item1: "Maintenance and management.",
          },
        },
      },
      meteorologicalTowers: {
        header: {
          title: "Our",
          subtitle: "Services",
        },
        subheader: {
          caption: "OUR SERVICES",
          title: "Meteorological towers",
        },
        paragraph1: "Research-grade weather stations for different applications such as: solar plants, wind farms, agriculture, ecology, and environment. Our systems are completely automatic and personalized, to the specific needs of our clients.",
        installation: {
          title: "Installation",
          list1: {
            item1: "Sale/rental of measuring towers for wind farm calibration.",
            item2: "Installation of self-supporting towers for the operation of wind projects.",
            item3: "Meteorological towers for wind and solar.",
            item4: "Towers for wind farm communication.",
            item5: "Obstruction lights for operational wind farms.",
            item6: "Maintenance of meteorological Towers",
          },
        },
      },
      careers: {
        header: {
          title: "Careers",
          subtitle: "Work with us",
        },
        workSector: {
          caption: "CARREERS",
          title1: "Work in the renewable",
          title2: "energy sector",
          paragraph1: "In 2021, the renewable energy sector added more than 12 million jobs around the world, according to a report by the International Renewable Energy Agency, in collaboration with the International Labor Organization.",
          paragraph2: "Furthermore, this report shows that the energy transition will not only bring environmental changes, but that more jobs will be created than are lost, estimating up to 25 million new jobs by 2030.",
          paragraph3: "Renewable energies are the present and the future, promoting a more environmentally friendly culture and creating new job opportunities.",
        },
        energyTeam: {
          title1: "The",
          title2: "Energy Team",
          paragraph1: "The renewable energy sector requires professionals from different areas so that each project is carried out efficiently and promoting a cleaner and more sustainable energy culture. You can also be part of Reyco. Learn about the advantages, aptitudes and soft or interpersonal skills most requested within the sector.",
          item1: {
            title: "Availability to travel",
            text: "Great flexibility for traveling, both nationally and internationally.",
          },
          item2: {
            title: "Autonomy",
            text: "Ability to adapt to change, as well as autonomy in decision-making.",
          },
          item3: {
            title: "Languages",
            text: "Fluent in English, it is advisable to learn the languages of neighboring countries, such as Portuguese or German.",
          },
          item4: {
            title: "Experience in the sector",
            text: "We are looking for experts in the renewable energy industry.",
          },
        },
        vacancies: {
          caption: "PROFESSIONAL PROFILES",
          title1: "Vacancies in the renewable",
          title2: "energy sector",
          paragraph1: "The renewable energy sector requires a wide range of professionals from different disciplines to achieve specific objectives. Therefore, here we mention some of the most requested.",
          buttonText: "Apply now",
          vacancy1: "Maintenance Technitians",
          vacancy2: "Electrical Engineer / Electromechanical",
          vacancy3: "Project Manager",
          vacancy4: "Renewable Energy Engineer",
          vacancy5: "Hygiene and Safety Specialist",
        },
        swiper: {
          swiper1: {
            caption: "ABOUT US",
            title1: "We are Reyco.",
            title2: "We are the change.",
            paragraph1: "For a more sustainable world, let’s build the future of renewable energies together.",
            paragraph2: " At Reyco Renovables we promote the use of wind energy in each of the projects in which we participate internationally. A united workforce, top-notch materials and constant improvement is what allows us to continue moving the world."
          },
          swiper2: {
            caption: "ABOUT US",
            title1: "Our priority:",
            title2: "your safety.",
            paragraph1: "The most important thing for us is the safety and well-being of each of our collaborators, taking maximum care of the components and tools we use in projects, reducing risks and improving internal processes.",
            paragraph2: " Our commitment to the environment is the basis of each service we provide, seeking a lower impact on the planet and consolidating renewable energies as the main source of energy in the world."
          },
          swiper3: {
            caption: "ABOUT US",
            title1: "Committed to the",
            title2: "world and to you",
            paragraph1: "We look for new ways of producing, at a lower economic cost and with a low environmental impact compared to fossil fuels.",
            paragraph2: " Our pillar: renewable energies, we have developed projects around the world, promoting the change of energy, as well as adequate infrastructure in different sectors; such as meteorology, communications and electricity, to be part of the constant increase in the production of clean energy in recent decades."
          },
        },
        together: {
          title: "Together, we can take energy further",
          paragraph1: "At Reyco Renovables we promote the professional development of our people. We firmly believe that teamwork allows us to achieve great results. A united workforce, top-notch materials and constant improvement are what allow us to continue moving the world.",
          paragraph2: "Our commitment to the environment is the basis of every service we provide. Be part of our company and together we seek to consolidate renewable energies in the world. We are a united workforce, top-notch materials and constant improvement is what allows us to continue moving the world.",
        },
        changingWorld: {
          title1: "We change the world.",
          title2: "Starting with us.",
          paragraph1: "Reyco’s commitment is not only to the environment, but to each of our employees, as we know that they are the pillar of the company. On both a social and professional level, Reyco is committed to a more sustainable and sustainable future.",
          paragraph2: "Be part of the change, together we can go further.",
        },
      },
      contact: {
        header: {
          title: "Contact us",
          subtitle: "Contact us",
        },
        contactForm: {
          title: "Together we can go further.",
          paragraph1: "Do you require additional information or a personalized quote? Share your information with us, we will contact you shortly",
          subject: "Subject*",
          subjects: {
            subject1: "Quote or services",
            subject2: "Open vacancies",
            subject3: "Complaints",
            subject4: "Suggestions",
            subject5: "Denounce",
            subject6: "Providers",
            subject7: "Other",
          },
          inputName: "Name*",
          inputPhone: "Phone*",
          inputEmail: "Email*",
          inputFile: "Attach file",
          inputMessage: "Message*",
          textButton: "Send",
          success: "Your message has been sent successfully.",
          errors: {
            name: "The name is required.",
            phone: "The phone is required.",
            subject: "The subject is required.",
            email: "The email is required.",
            validEmail: "Write a valid email.",
            message: "The message is required.",
            fileSize: "The file must be less than 2MB.",
            fileType: "The file must be a PDF",
          },
        },
        ourHeadquarters: {
          caption: "CORPORATE",
          title: "Our offices around the world",
        },
      },
      blog: {
        header: {
          title: "News",
          subtitle: "Blog",
        },
        subheader: "Posts",
        prevText: "Previous",
        nextText: "Next",
        readMore: "Read more",
        search: "Search",
        writtenBy: "Written by",
        canNotFoundContent: "We can't find any content with this search",
        linkCopySuccess: "Link copied successfully.",
        sidebar: {
          allPosts: "ALL POSTS",
          latestPosts: "LATEST NEWS",
          archive: "ARCHIVE",
          tags: "LABELS",
        },
      },
      footer: {
        copyRight: "All rights reserved. Reyco renewable.",
        legalLinks: {
          privacyPolicy: "Privacy policy",
          termsAndConditions: "Terms and conditions",
        },
      },
    }  
  },
  es: {
    translation: {
      mainMenu: {
        labels: {
          home: 'Inicio',
          about: 'Acerca de',
          aboutUs: "¿Quiénes somos?",
          certifications: "Certificaciones",
          projects: "Proyectos",
          services: "Servicios",
          windFarms: "Parques eólicos",
          solarParks: "Parques solares",
          electricalInfrastructure: "Infraestructura eléctrica",
          meteorologicalTowers: "Torres meteorológicas",
          careers: "Carreras",
          professionalProfiles: "Perfiles profesionales",
          contact: "Contacto",
          blog: "Blog",
        }
      },
      home: {
        building: {
          title: "Construyendo el futuro de las energías renovables",
          paragaph1: "Ofrecemos servicios personalizados de solución en el sector de las energías renovables, eólica, solar, infraestructura eléctrica y torres meteorológicas. Participamos en la construcción, mantenimiento e ingeniería de parques eólicos y solares en México, USA, Latinoamérica y Europa.",
          paragaph2: "Estamos certificados bajo las normas ISO 9001, ISO 14001, ISO 45001 e ISO 37001.",
          buttonText: "Sobre nosotros",
        },
        whatWeDo: {
          title: 'Comprometidos con el mundo y contigo',
          paragraph1: 'Nuestro principal objetivo es brindar a cada uno de nuestros clientes un servicio de calidad, que solucione sus necesidades y que cumpla sus expectativas. Nos esforzamos por mantener siempre una constante comunicación con nuestros clientes, mediante evidencias, reportes, reuniones y seguimientos.',
          paragraph2: 'Para nosotros es muy importante que nuestros clientes se sientan tranquilos al dejar sus necesidades de servicio en nuestras manos. Para lograrlo contamos con un equipo logístico y administrativo con gran experiencia en el sector de las energías y con alto sentido de servicio al cliente.',
        },
        ourServices: {
          title: 'Nuestros servicios',
          paragraph: 'Cubrimos tus necesidades de solución en el sector de las energías. Podemos atender y dar servicio a tu proyecto de energías renovables, infraestructura eléctrica y torres Meteorológicas en cualquier parte del mundo, somos capaces y confiables.',
          services: {
            windFarms: 'Parques eólicos',
            solarFarms: 'Parques solares',
            electricalInfrastructure: 'Infraestructura eléctrica',
            meteorologicalTowers: 'Torres meteorológicas',
          },
        },
        moveTheWorld: {
          title1: 'Movemos al mundo con el',
          title2: 'viento a nuestro favor',
          paragraph1: "La confianza de nuestros clientes es muy importante. Por ello, fomentamos y llevamos a cabo buenas prácticas en en nuestro día a día. En Reyco nos esforzamos por desarrollar y poner en marcha continuamente planes de mejora que atiendan las necesidades internas de la empresa. Creemos firmemente que atender, ejecutar y dar seguimiento a estrategias de mejora continua, nos permitirá mantenernos en el mercado del sector de las energías como una empresa altamente competitiva.",
          paragraph2: "",
          buttonText: "Ver experiencia",
        },
        valueAdded: {
          caption: 'VALOR AÑADIDO',
          title: 'Somos una fuerza de trabajo unida',
          paragraph1: "Lo más importante para nosotros es la seguridad y bienestar de cada uno de nuestros colaboradores, cuidando al máximo los componentes y herramientas que usamos en los proyectos, reduciendo riesgos y mejorando procesos internos.",
          paragrpah2: "Además, contamos con la certificación de la norma ISO 9001, para que nuestros clientes sepan que cada uno de nuestro proyecto está avalado por nuestra experiencia.",
          paragraph3: "Finalmente, nuestro compromiso con el medio ambiente es la base de cada servicio que proveemos, buscando un menor impacto al planeta y consolidar a las energías renovables como la principal fuente de energía en el mundo.",
        },
        clients: {
          caption: 'CLIENTES',
          title1: 'Empresas que han',
          title2: 'confiado',
          title3: 'en nosotros',
        },
        aboutReyco: {
          title: 'Conoce más sobre Reyco',
          paragraph1: "Sumérgete en el mundo de las energías renovables y de su importancia, así como de nuestra visión de un mundo más verde y limpio. Somos expertos en diseño e instalación de infraestructura eléctrica, meteorología renovable y comunicaciones, descarga nuestro brochure aquí y conoce nuestros servicios.",
          buttonText: "Ver más",
        },
        newsletter: {
          title1: 'Suscríbete a nuestro',
          title2: 'newsletter',
          paragraph1: "Si quieres enterarte sobre todas las actividades que realiza Reyco Renovables y las noticias más destacadas del sector de las energías renovables, suscríbete a nuestro boletín.",
          acceptTyC: "Acepto términos y condiciones",
          buttonText: "Suscribirme",
          inputText: "Correo electrónico",
          emailRequired: "Correo electrónico es requerido.",
          emailInvalid: "Ingresa un correo válido",
          termsRequired: "Debes aceptar términos y condiciones.",
          emailRegisteredSucess: "Correo registrado con éxito.",
          emailNotRegistered: "Correo no registrado, intenta más tarde.",
        },
        latestNews: {
          title1: 'Nuestros',
          title2: 'artículos',
          buttonText: "Ver noticias",
        },
      },
      brochure: {
        title: "Conoce más de Reyco",
        paragraph1: "Sumérgete en el mundo de las energías renovables y de su importancia, así como de nuestra visión de un mundo más verde y limpio. Descarga nuestro brochure aquí.",
        buttonText: "Descargar",
      },
      about: {
        header: {
          title: "Nosotros",
          subtitle: "Nosotros",
        },
        boundsTrust: {
          caption: 'SOBRE NOSOTROS',
          title: "Creamos lazos de confianza con nuestros clientes",
          paragraph1: "Somos una empresa con más de 15 años de experiencia en el sector de las energías. Nos mantenemos firmes, con el objetivo de brindar solución a las necesidades de servicio de nuestros clientes, quienes buscan atención personalizada, basada en la eficiencia y compromiso. Desde nuestros inicios, nos hemos ocupado en formar y mantener lazos de confianza con sus clientes, esto nos ha permitido ser seleccionados para el desarrollo y mantenimiento de proyectos para el sector de las energías y ejecutar nuestros servicios en varios países en el mundo. Hoy en día podemos sentirnos orgullos sabiendo que en Reyco nos caracterizamos por ser una empresa líder en el sector.",
          paragraph2: "",
        },
        ourVision: {
          caption: 'NUESTRA VISIÓN',
          title: "Queremos mantenernos como líderes en el sector",
          paragraph1: "Somos capaces de resolver las necesidades de nuestros clientes. Ofrecemos servicios de forma competitiva y de manera segura que cumplen sus requerimientos. Para lograrlo nos basamos en seguir y cumplir estándares de calidad, protección ambiental, seguridad del trabajador y salud ocupacional.",
          paragraph2: "Estamos comprometidos con nuestra gente para crear lazos confiables con ellos y nuestros clientes. Nuestra misión es prestar un servicio local a clientes globales.",
          paragraph3: "Trabajamos para llegar a más países formando técnicos más capaces. Estamos comprometidos con el sector. Creemos firmemente que somos un equipo que a través de su compromiso y dedicación llegaremos a más clientes que confiarán en nosotros como lo hacen nuestros clientes actuales.",
        },
        eolicSector: {
          caption: 'CONÓCENOS',
          title: "Acerca de la empresa",
        },
        workingEnergy: {
          title1: "Trabajamos para brindar",
          title2: "soluciones integrales",
          items: {
            building: {
              title: 'Construcción de parques',
              text: 'Más de 15 años de experiencia en construcción de parques eólicos en el mundo.',
            },
            solutions: {
              title: 'Soluciones integrales',
              text: 'Innovación, conocimiento sobre el sector, experiencia técnica y comunicación efectiva.',
            },
            certifications: {
              title: 'Certificaciones',
              text: 'Garantizando excelencia en nuestro servicio y compromiso con la calidad, la gestión ambiental, la gestión de seguridad y salud ocupacional y la gestión antisoborno.',
            },
            technicalService: {
              title: 'Servicios Técnicos',
              text: 'Correcciones de fallas grandes y pequeñas, reparaciones generales y específicas.',
            },
          },
        },
        sustainability: {
          caption: 'SUSTENTABILIDAD',
          title1: "Acciones grandes.",
          title2: "Resultados gigantescos.",
          paragraph1: "Estamos comprometidos contigo, con nuestros trabajadores y el medio ambiente. Nuestro compromiso está avalado por las certificaciones en las Normas ISO 9001, 14001, 37001 y 45001.",
          buttonText: "Ver más",
        },
      },
      certifications: {
        header: {
          title: "Nuestras",
          subtitle: "Certificaciones",
        },
        weAreReyco: {
          title1: "Somos Reyco.",
          title2: "Una empresa sostenible.",
          paragraph1: "En REYCO, creemos que el progreso empresarial y la responsabilidad social van de la mano. Es por eso que hemos abrazado con entusiasmo las normas de calidad, sostenibilidad y ética más rigurosas del mundo, certificándonos bajo las prestigiosas ISO 9001, ISO 14001, ISO 45001 e ISO 37001.",
          paragraph2: "Al obtener y mantener estas certificaciones, no solo demostramos nuestro compromiso con la calidad, la sostenibilidad, la seguridad y la ética, sino que también contribuimos a mejorar el mundo. Ayudamos a reducir la contaminación, fomentamos la seguridad en el trabajo, luchamos contra el soborno y establecemos estándares elevados para toda la industria.",
          paragraph3: "En REYCO, vemos estas certificaciones como nuestro deber y nuestra forma de marcar la diferencia en el mundo. Creemos que cada paso que damos hacia la excelencia y la responsabilidad contribuye a un futuro más brillante y sostenible para todos.",
          paragraph4: "Nuestro compromiso con la calidad, el medio ambiente y la seguridad y salud queda reconocido en los siguientes certificados:",
        },
        sustainability: {
          title1: "Sustentabilidad",
          title2: "ISO 14001",
          paragraph1: "La certificación en la norma internacional ISO 14001 respalda nuestra pasión por el cuidado del medio ambiente y la sostenibilidad. Somos conscientes de que la industria de la energía eólica debe liderar la transición hacia un futuro más limpio y sostenible. Con esta certificación, hemos establecido sólidos sistemas de gestión ambiental que minimizan nuestro impacto en la naturaleza, reduciendo nuestra huella de carbono y promoviendo prácticas sostenibles en todo lo que hacemos.",
        },
        commitment: {
          title1: "Compromiso",
          title2: "ISO 9001",
          paragraph1: "La certificación en la norma internacional ISO 9001 refleja nuestro compromiso con la excelencia en la gestión de calidad. Esto significa que cada uno de nuestros servicios y proyectos que emprendemos, se ejecuta con precisión y eficiencia. Nuestro objetivo es superar las expectativas de nuestros clientes en cada paso, garantizando resultados de la más alta calidad.",
        },
        security: {
          title1: "Seguridad",
          title2: "ISO 45001",
          paragraph1: "La seguridad y salud de nuestro equipo y de todos los involucrados es nuestra prioridad número uno. La certificación en la norma ISO 45001 demuestra nuestro firme compromiso con la salud y la seguridad ocupacional. Cada día, trabajamos incansablemente para garantizar que nuestras operaciones sean seguras y que nuestros empleados vuelvan a casa sanos y salvos. Creemos que no hay éxito empresarial si no es posible garantizar la seguridad y salud de nuestros colaboradores.",
        },
        ethics: {
          title1: "Ética empresarial",
          title2: "ISO 37001",
          paragraph1: "Finalmente, la certificación en la norma internacional ISO 37001 nos coloca en la vanguardia de la lucha contra el soborno y la corrupción. Consideramos que la integridad es esencial en cualquier negocio, y esta certificación nos respalda en nuestra búsqueda de operaciones éticas y transparentes.",
        },
      },
      projects: {
        header: {
          title: "Proyectos",
          subtitle: "Experiencia",
        },
        ourExperience: {
          caption: 'NUESTRA EXPERIENCIA',
          title1: "Somos una empresa internacional en el",
          title2: "sector de las energías",
          paragraph1: "Hemos participado en proyectos alrededor del mundo, Desde U.S.A hasta Argentina, pasando por México, Chile, Costa Rica, Guatemala, República Dominicana, Nicaragua y Uruguay.",
          paragraph2: "Durante los últimos dos años, hemos participado en los siguientes parques instalando y manteniendo tecnologías como: Goldwind, Senvion, SiemensGamesa, Suzlon, Clipper, Vestas.",
          paragraph3: "Reyco está comprometido en mejorar continuamente su servicio, así como reducir su impacto medioambiental.",
        },
        inTheWorld: {
          title: "Nuestra experiencia en el mundo",
          paragraph1: "La experiencia nos ha permitido desarrollar protocolos que cumplan los estándares más exigentes. Para nosotros, la distancia no impide que brindemos excelencia en nuestro servicio a cada uno de nuestros clientes.",
        },
      },
      presenceCountries: {
        mexico: "MÉXICO",
        argentina: "ARGENTINA",
        chle: "CHILE",
        guatemala: "GUATEMALA",
        dominicanRepublic: "REPÚBLICA DOMINICANA",
        uruguay: "URUGUAY",
        usa: "ESTADOS UNIDOS",
        spain: "ESPAÑA",
        peru: "PERÚ",
        colombia: "COLOMBIA",
      },
      windFarms: {
        header: {
          title: "Nuestros",
          subtitle: "Servicios",
        },
        subHeader: {
          caption: "NUESTROS SERVICIOS",
          title1: "Infraestructura y desarrollo de",
          title2: "Parques Eólicos",
        },
        mantto: {
          title: "Mantenimiento",
          paragraph1: "Desde Reyco sabemos que un mantenimiento correcto del aerogenerador, es una de las mejores estrategias para la vida útil y productividad a los equipos. Por eso, contamos con equipos y herramientas certificados y de ultima tecnología, que nos permiten la realización de un trabajo más eficiente.",
          paragraph2: "Los años en el sector nos han permitido crear un equipo de trabajo comprometido y capacitado en los mantenimientos programados y preventivos.",
          list1: {
            title: "Correctivos",
            item1: "Grandes correctivos. ​Cambio de componentes, multiplicadora, generadores, palas, entre otros.",
            item2: "Pequeños correctivos. Reparación de elevadores, escaleras, línea de vida, entre otros.​​",
          },
          list2: {
            title: "Preventivos",
            item1: "Revisión de pares y tensionados, cambio de aceite, revisión de alarma, inspecciones anuales de elevadores, escaleras, línea de vida, entre otros.",
          },
        },
        installation: {
          title: "Instalación de aerogeneradores",
          paragraph1: "Los más de 20 años de experiencia en el sector, nos permite ofrecer a los clientes, sin importar la tecnología a instalar, equipos altamente cualificados para la instalación de los aerogeneradores en cualquier lugar del mundo.",
          paragraph2: "El conocimiento de todo lo que implica un proyecto, nos ha llevado a ser una opción de consultoría y ejecución de las demás fases del proyecto. Ofreciendo soluciones a nuestros clientes en gestión de todo el proceso, desde la obra civil, grúas o puesta en marcha, o supervisión si se requiere.",
        },
        supplies: {
          title: "Suministros",
          paragraph1: "Te ayudamos a conseguir el equipo que necesites, donde lo necesites. Nos encargamos desde la revisión en sitio para la solicitud, hasta la instalación y cambio del mismo en las instalaciones.",
          list1: {
            item1: "Grúas, transportes y maquinaria para la ejecución de las obras.",
            item2: "Suministro de facilities.",
            item3: "Suministro de herramientas hidráulicas y manuales.",
            item4: "Suministro de componentes.",
            item5: "Suministro de repuestos para elevadores, líneas de vida y escaleras.",
          },
        },
        supervision: {
          title: "Supervisión y gestión",
          paragraph1: "Te ofrecemos asesoría y seguimiento en todos tus proyectos con personal profesional y cualificado. Cubrimos todas tus necesidades de logística para el óptimo desarrollo de tus proyectos eólicos.​",
          list1: {
            title: "Supervisión",
            item1: "Supervisión para los montajes de aerogeneradores.",
            item2: "Supervisión de calidad para revisión de transportes, logísticas y stock keeper.",
            item3: "Supervisión de seguridad, calidad y medio ambiente.",
          },
          list2: {
            title: "Gestión",
            item1: "Gestión NCR.",
            item2: "Gestión de subcontratistas.",
            item3: "Gestión para la recepción de materiales y logística.",
            item4: "Gestión para la tramitología de certificaciones y permisos.",
            item5: "Gestión personalizada y auditorías.",
          },
        },
        waste: {
          title: "Manejo de residuos",
          paragraph1: "Facilitamos a nuestros clientes el servicio de gestión de residuos. Nuestro servicio y procesos de manejo de residuos los realizamos apegados a las normativas gubernamentales y de medio ambiental.",
          list1: {
            item1: "Destrucción de palas.",
            item2: "Reciclaje de residuos.",
            item3: "Desmantelamiento de parques.",
          },
        },
      },
      solarFarms: {
        header: {
          title: "Nuestros",
          subtitle: "Servicios",
        },
        subHeader: {
          caption: "NUESTROS SERVICIOS",
          title1: "Infraestructura y desarrollo de",
          title2: "Parques Solares",
        },
        mantto: {
          title: "Mantenimiento",
          paragraph1: "Un mantenimiento correcto de los parques solares, es una de las mejores estrategias para la vida útil y productividad a los equipos. Por eso, contamos con equipos y herramientas certificados y de ultima tecnología, que nos permiten la realización de un trabajo más eficiente.",
          list1: {
            title: "Correctivos",
            item1: "Cambio de paneles solares.",
            item2: "Cambios de estructuras fijas y móviles.",
            item3: "Detección y corrección de fallos en cableado y conexiones.",
            item4: "Detección y corrección en calibración.",
            item5: "Detección y corrección en fallos electrónicos de componentes internos.",
            item6: "Detección y corrección de fallos en celdas o en conjunto de celdas.​",
          },
          list2: {
            title: "Preventivos",
            item1: "Revisión programada de paneles y trackers.",
            item2: "Limpieza de módulos.",
          },
        },
        installation: {
          title: "Instalación",
          paragraph1: "El conocimiento de todo lo que implica un proyecto, nos ha llevado a ser una opción de consultoría y ejecución de las demás fases del proyecto. Ofreciendo soluciones a nuestros clientes en gestión de todo el proceso, desde la obra civil, grúas o puesta en marcha, o supervisión si se requiere.",
          list1: {
            item1: "Instalación de módulos",
            item2: "Instalación de paneles solares",
          },
        },
        supplies: {
          title: "Suministros",
          paragraph1: "Te ayudamos a conseguir el equipo que necesites para tu parque solar. Nos encargamos desde la revisión en sitio para la solicitud, hasta la instalación y cambio del mismo en las instalaciones.",
          list1: {
            item1: "Paneles.",
            item2: "Módulos.",
            item3: "Inversores.",
            item4: "Herramientas.",
            item5: "Facilities.",
          },
        },
        supervision: {
          title: "Supervisión",
          paragraph1: "Realizamos el seguimiento en todos tus proyectos con personal profesional y cualificado, para poder cubrir todas las necesidades del proyecto",
          list1: {
            title: "Supervisión",
            item1: "Supervisión para montajes de parques solares.",
            item2: "Supervisión de calidad para revisión de transportes, logísticas y stock keeper.",
            item3: "Supervisión de seguridad, calidad y medio ambiente.",
          },
          list2: {
            title: "Gestión",
            item1: "Gestión NCR.",
            item2: "Gestión de subcontratistas.",
            item3: "Gestión para la recepción de materiales y logística.",
            item4: "Gestión para la tramitología de certificaciones y permisos.",
            item5: "Gestión personalizada y auditorías.",
          },
        },
      },
      electricalInfrastructure: {
        header: {
          title: "Nuestros",
          subtitle: "Servicios",
        },
        subHeader: {
          caption: "NUESTROS SERVICIOS",
          title1: "Infraestructura",
          title2: "Eléctrica",
        },
        infrastructure: {
          title: "Infraestructura",
          list1: {
            item1: "Instalaciones eléctricas.",
            item2: "Sistemas de enfriamiento.",
            item3: "Diseño y desarrollo en sistemas industriales.",
            item4: "Luminaria y sistemas electromecánicos.",
            item5: "Sistemas de protección contra incendios.",
            item6: "Alarmascontra incendios, extintores, sistemas de detección, columnas secas, detectores de gases, sirenas.",
          },
          list2: {
            item1: "Mantenimiento y gestión.",
          },
        },
      },
      meteorologicalTowers: {
        header: {
          title: "Nuestros",
          subtitle: "Servicios",
        },
        subheader: {
          caption: "NUESTROS SERVICIOS",
          title: "Torres meteorológicas",
        },
        paragraph1: "Estaciones meteorológicas con grado de investigación para distintas aplicaciones como son: Plantas Solares, eólicas, agricultura, ecología y medio ambiente. Nuestros sistemas son completamente automáticos y personalizados a la necesidad especifica de nuestros clientes.",
        installation: {
          title: "Instalación",
          list1: {
            item1: "Venta/alquiler de torres de medición para calibración de parque eólico.",
            item2: "Instalación de torres auto-soportadas para operación proyectos eólicos.",
            item3: "Estaciones meteorológicas para eólica y solar.",
            item4: "Torres para comunicación de parque eólico.",
            item5: "Luces de obstrucción para parques eólicos operativos.",
            item6: "Mantenimiento de estaciones meteorológicas.",
          },
        },
      },
      careers: {
        header: {
          title: "Profesional",
          subtitle: "Carreras",
        },
        workSector: {
          caption: "CARRERAS",
          title1: "Trabajar en el sector de",
          title2: "energías renovables",
          paragraph1: "Durante el año 2021, el sector de las energías renovables sumó más de 12 millones de empleos en todo el mundo, según un informe de la Agencia internacional de Energías Renovables, en colaboración con las Organización Internacional del trabajo.",
          paragraph2: "Además, este informe muestra que la transición energética no solo traerá cambios a nivel medioambiental, sino que se producirán más empleos de los que se pierdan, estimando hasta 25 millones de empleos nuevos para el año 2030.",
          paragraph3: "Las energías renovables son el presente y el futuro, promoviendo una cultura más amigable con el medioambiente y creando nuevas oportunidades laborales.",
        },
        energyTeam: {
          title1: "The",
          title2: "Energy Team",
          paragraph1: "El sector de las energías renovables requiere de profesionales de distintas áreas para que cada proyecto se realice de forma eficiente y promoviendo una cultura energética más limpia y sustentable. Es por esto que algunas de las habilidades soft o interpersonales más solicitadas dentro del sector.",
          item1: {
            title: "Disponibilidad de viajar",
            text: "Una gran flexibilidad para viajar, tanto a nivel nacional como internacional.",
          },
          item2: {
            title: "Autonomía",
            text: "Capacidad de adaptación al cambio, así como autonomía para la toma de decisiones.",
          },
          item3: {
            title: "Idiomas",
            text: "Dominio del inglés, es recomendable el aprender los idiomas de países vecinos, como el portugués o alemán.",
          },
          item4: {
            title: "Experiencia en el sector",
            text: "Estamos buscando expertos en la industria de las energías renovables.",
          },
        },
        vacancies: {
          caption: "PERFILES PROFESIONALES",
          title1: "Vacantes en el sector de",
          title2: "las energías renovables",
          paragraph1: "El sector de las energías renovables requiere de una amplia gama de profesionales de distintas disciplinas para lograr objetivos concretos. Por eso, aquí mencionamos algunos de los más solicitados.",
          buttonText: "Enviar CV",
          vacancy1: "Técnico de mantenimiento",
          vacancy2: "Ingeniero Eléctrico / Electromecánico",
          vacancy3: "Project Manager",
          vacancy4: "Ingeniero en Energías Renovables",
          vacancy5: "Especialista en Higiene y Seguridad",
        },
        swiper: {
          swiper1: {
            caption: "SOBRE NOSOTROS",
            title1: "Somos Reyco",
            title2: "Somos el cambio",
            paragraph1: "Por un mundo más sostenible, construyamos el futuro de las energías renovables juntos.",
            paragraph2: "En Reyco Renovables promovemos el uso de energía eólica en cada uno de los proyectos en los que participamos de manera internacional. Una fuerza de trabajo unida, materiales de primera categoría y mejora constante es lo que nos permite seguir moviendo el mundo."
          },
          swiper2: {
            caption: "SOBRE NOSOTROS",
            title1: "Nuestra prioridad:",
            title2: "tu seguridad.",
            paragraph1: "Lo más importante para nosotros es la seguridad y bienestar de cada uno de nuestros colaboradores, cuidando al máximo los componentes y herramientas que usamos en los proyectos, reduciendo riesgos y mejorando procesos internos.",
            paragraph2: "Nuestro compromiso con el medio ambiente es la base de cada servicio que proveemos, buscando un menor impacto al planeta y consolidar a las energías renovables como la principal fuente de energía en el mundo."
          },
          swiper3: {
            caption: "SOBRE NOSOTROS",
            title1: "Comprometidos con",
            title2: "el mundo y contigo.",
            paragraph1: "Buscamos nuevas formas de producir energía, a un menor costo económico y un impacto ambiental bajo en comparación a los combustibles fósiles.",
            paragraph2: "Nuestro pilar: las energías renovables, hemos desarrollado proyectos por todo el mundo, fomentando el cambio de energía, así como la infraestructura adecuada en diferentes sectores; como la meteorología, comunicaciones y eléctrica, para lograr ser parte del incremento constante en la producción de energías limpias en las últimas décadas."
          },
        },
        together: {
          title: "Juntos, podemos llevar la energía más lejos",
          paragraph1: "En Reyco Renovables promovemos el desarrollo profesional de nuestra gente. Creemos firmemente que el trabajo en equipo nos permite lograr grandes resultados. Una fuerza de trabajo unida, materiales de primera categoría y mejora constante, son lo que nos permite seguir moviendo al mundo.",
          paragraph2: "Nuestro compromiso con el medio ambiente es la base de cada servicio que proveemos. Forma parte de nuestra empresa y busquemos juntos consolidar las energías renovables en el mundo. Somos una fuerza de trabajo unida, materiales de primera categoría y mejora constante es lo que nos permite seguir moviendo el mundo.",
        },
        changingWorld: {
          title1: "Cambiamos el mundo,",
          title2: "comenzando con nosotros.",
          paragraph1: "El compromiso de Reyco no es solamente con el medioambiente, sino con cada uno de nuestros empleados, pues sabemos que son el pilar de la empresa. Tanto a nivel social y profesional, Reyco está comprometido con un futuro más sostenible y sustentable.",
          paragraph2: "Sé parte del cambio, juntos podemos llegar más lejos.",
        },
      },
      contact: {
        header: {
          title: "Contact us",
          subtitle: "Contáctanos",
        },
        contactForm: {
          title: "Juntos podemos llegar más lejos.",
          paragraph1: "¿Requieres información extra o una cotización? Puedes contactarnos aquí.",
          subject: "Asunto*",
          subjects: {
            subject1: "Cotización o servicios",
            subject2: "Bolsa de trabajo",
            subject3: "Quejas",
            subject4: "Sugerencias",
            subject5: "Denuncias",
            subject6: "Proveedores",
            subject7: "Otro",
          },
          inputName: "Nombre*",
          inputPhone: "Teléfono*",
          inputEmail: "Correo*",
          inputFile: "Adjuntar archivo",
          inputMessage: "Mensaje*",
          textButton: "Enviar",
          success: "Tu mensaje se ha enviado correctamente.",
          errors: {
            name: "El nombre es requerido.",
            phone: "El teléfono es requerido.",
            subject: "El asunto es requerido.",
            email: "El email es requerido.",
            validEmail: "El escribe un email válido.",
            message: "El mensaje es requerido.",
            fileSize: "El archivo debe ser menor a 2 MB.",
            fileType: "El archivo debe ser PDF.",
          },
        },
        ourHeadquarters: {
          caption: "CORPORATIVOS",
          title: "Nuestras sedes en el mundo",
        },
      },
      blog: {
        header: {
          title: "Noticias",
          subtitle: "Blog",
        },
        subheader: "Publicaciones",
        prevText: "Anterior",
        nextText: "Siguiente",
        readMore: "Leer más",
        search: "Buscar",
        writtenBy: "Escrito por",
        canNotFoundContent: "No podemos encontrar contenido con esta búsqueda",
        linkCopySuccess: "Enlace copiado correctamente.",
        sidebar: {
          allPosts: "TODAS LAS PUBLICACIONES",
          latestPosts: "ÚLTIMAS NOTICIAS",
          archive: "ARCHIVO",
          tags: "ETIQUETAS",
        },
      },
      footer: {
        copyRight: "Todos los derechos reservados. Reyco Renovables",
        legalLinks: {
          privacyPolicy: "Política de privacidad",
          termsAndConditions: "Términos y condiciones",
        },
      },
    }
  }
};