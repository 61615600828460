import styled from "styled-components";
import { ColorWhite, Spacing } from "theme";

export const CheckBoxWrapper = styled.div.attrs((props)=>({
    className: 'checkbox-wrapper'
}))`
    display: flex;
    gap: ${Spacing.tinySpacing}
`;

export const Check = styled.input.attrs((props)=>({
    className: 'checkbox-input',
    type: 'checkbox'
}))`
    background: transparent;
    border: solid 2px ${ColorWhite};
    width: 22;
    height: 24px;
`;