import React, { useState, useEffect, useContext } from 'react';
import { PostsByCategoryItemProps } from 'custom-types/design/molecules/PostsByCategoryItem';
import { CategoryItemWrapper, CountPostsWrapper, PostsByCategoryItemHolder, PostsByCategoryItemWrapper } from './PostByCategoryItem.components';
import { SText } from 'atoms/text/Text';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import { useNavigate } from 'react-router-dom';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';

const PostsByCategoryItem: React.FC<PostsByCategoryItemProps> = ({category, categoryId}) => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const navigate = useNavigate();
  const [postsCount, setPostsCount] = useState<number>(0);
  const {handleSearchCategoryId} = useContext(SearchPostContext);

  const [postsByCategory] = useAllPrismicDocumentsByType('post',{
    fetchLinks: ['post_category.name'],
    lang: getPrismicLanguage(languageCode)
  });

  useEffect(()=>{
    if(postsByCategory?.length){
      setPostsCount(postsByCategory.filter(posts => posts.data.category.data.name === category).length);
    }
  },[postsByCategory, category]);

  const handleCategorySelected = ()=> {
    handleSearchCategoryId && handleSearchCategoryId(categoryId, category);
    navigate('/Blog');
  }

  return (
    <PostsByCategoryItemWrapper>
      <PostsByCategoryItemHolder>
        <CategoryItemWrapper>
          <SText
            onClick={handleCategorySelected}
          >
            {category}
          </SText>
        </CategoryItemWrapper>
        <CountPostsWrapper>
          <SText>
            {`(${postsCount})`}
          </SText>
        </CountPostsWrapper>
      </PostsByCategoryItemHolder>
    </PostsByCategoryItemWrapper>
  )
}

export default PostsByCategoryItem