import { useTranslation } from "react-i18next";
import { LanguageCodes, LanguageLabels, LanguageProps } from "./SelectLanguage.types";
import { SingleValue } from "react-select";
import { SelectOption } from "atoms/select/Select";

export const useSelectLenguageConroller = (handleMenuVisible: () => void) => {
  const {i18n} = useTranslation();
  const language = i18n.language;

  const languages: LanguageProps[] = [
    { label: LanguageLabels.EN, value: LanguageCodes.EN },
    { label: LanguageLabels.ES, value: LanguageCodes.ES }
  ];

  const handleLanguageChange = (newValue: SingleValue<SelectOption>) => {
    i18n.changeLanguage(newValue?.value || LanguageCodes.EN);
    handleMenuVisible();
  };

  return {
    language,
    handleLanguageChange,
    languages,
  };
};