import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { imgParquesEolicosInstalacionAerogeneradres, imgParquesEolicosManttoMobile, imgParquesEolicosResiduos, imgParquesEolicosSuministros, imgParquesEolicosSupervision, imgServiciosParquesEolicos } from "images";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import React from "react";
import { Container } from 'src/design/containers/Container';
import { AerogeneradorIcon, ResiduosIcon, SupervisionIcon, TrascaboIcon } from "src/icons"; 
import ServiceSubitem from "organisms/serviceSubitem/ServiceSubitem";
import Mantenimiento from "./Mantenimiento";
import InstalacionEerogeneradores from "./InstalacionEerogeneradores";
import { imgParquesEolicosMantto } from "images";
import Suministros from "./Suministros";
import Supervision from './Supervision';
import Residuos from "./Residuos";
import { ServiceWrapper } from "screens/infraestructuraElectrica/InfraestructuraElectrica.styled";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const ParquesEolicos: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <>
      <ScreenHeader
        image={imgServiciosParquesEolicos}
        title={t("windFarms.header.title")}
        subtitle={t("windFarms.header.subtitle")}
        sectionName={SectionName.PARQUES_EOLICOS}
      />
      <ServiceWrapper>
        <Container>
          <ServiceSubitem 
            imageId="parques-eolicos-mantenimiento"
            content={
              <Mantenimiento />
            }
            imgMobile={isMobile ? imgParquesEolicosManttoMobile : undefined}
            img={isMobile ? undefined : imgParquesEolicosMantto}
            imgPosition="right"
          />
          <ServiceSubitem
            imageId="parques-eolicos-instalacion"
            icon={<AerogeneradorIcon width={49} height={49} />}
            content={
              <InstalacionEerogeneradores />
            }
            img={imgParquesEolicosInstalacionAerogeneradres}
            imgPosition="left"
          />
          <ServiceSubitem 
            imageId="parques-eolicos-suministros"
            icon={<TrascaboIcon width={49} height={49} />}
            content={
              <Suministros />
            }
            imgMobile={isMobile ? imgParquesEolicosSuministros : undefined}
            img={isMobile ? undefined : imgParquesEolicosSuministros}
            imgPosition="right"
          />
          <ServiceSubitem 
            imageId="parques-eolicos-supervision"
            icon={<SupervisionIcon width={49} height={49} />}
            content={
              <Supervision />
            }
            img={imgParquesEolicosSupervision}
            imgPosition="left"
          />
          <ServiceSubitem 
            imageId="parques-eolicos-residuos"
            icon={<ResiduosIcon width={49} height={49} />}
            content={
              <Residuos />
            }
            imgMobile={isMobile ? imgParquesEolicosResiduos : undefined}
            img={isMobile ? undefined : imgParquesEolicosResiduos}
            imgPosition="right"
          />
        </Container>
      </ServiceWrapper>
    </>
  )
}

export default ParquesEolicos