import { SText, SectionTitle } from 'atoms/text/Text'
import React, { useCallback, useState } from 'react'
import { CountryList, CountryListHolder, CountryListWrapper, CountryListWrapperMobile, OurExperienceWorldWrapper, WorldMapTitleWrapper, WorldMapWrapper } from './OurExperienceInTheWorld.styled'
import { Container, Content } from 'src/design/containers/Container'
import { grayd8d8d8, secondaryColor } from 'theme'
import WorldMap, {DataItem} from "react-svg-worldmap";
import { getTooltipText, projectsByCountryCode, mapDefaultStyles, COUNTRIES_WITH_PROJECTS } from 'src/utils/mapDefaultStyles'
import type {CountryContext} from 'react-svg-worldmap';
import ProjectList from '../projectsList/ProjectList';
import gsap from 'gsap'
import { useTranslation } from 'react-i18next'
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types'

const OurExperienceInTheWorld: React.FC = () => {
  const {t, i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const [countrySelected, setCountrySelected] = useState<COUNTRIES_WITH_PROJECTS>();
  const [isMobile] = useState<boolean>(window.innerWidth < 768 ? true : false);

  const projectsByCountry: DataItem[] = projectsByCountryCode.map(countryCode => ({
    country: countryCode,
    value: 1,
  }));

  const goToProjectsList = () => {
    const projectsListsElement = document.getElementById('country-list-holder');
    if (projectsListsElement) {
      projectsListsElement.style.opacity = '0';
      window.scrollTo({
        top: projectsListsElement?.offsetTop - 200,
        behavior: 'smooth',
      });
      setTimeout(() => {
        gsap.to('#country-list-holder', {opacity: 1, duration: 1})
      }, 500);
    }
  };

  // useEffect(() => {
  //   if(countrySelected) {
  //     goToProjectsList();
  //   }
  // }, [countrySelected]);

  const onCountryPress = useCallback(
    ({ countryCode }: CountryContext) => {
      if (!isMobile) {
        return projectsByCountryCode.includes(COUNTRIES_WITH_PROJECTS[countryCode as keyof typeof COUNTRIES_WITH_PROJECTS]) ? 
        setCountrySelected(COUNTRIES_WITH_PROJECTS[countryCode as keyof typeof COUNTRIES_WITH_PROJECTS]) : null;
      }
    },[isMobile]);

  return (
    <OurExperienceWorldWrapper>
      <Container>
        <WorldMapTitleWrapper>
          <Content>
            <SectionTitle>
              {t('projects.inTheWorld.title')}
            </SectionTitle>
            <SText>
              {t('projects.inTheWorld.paragraph1')}
            </SText>
          </Content>
        </WorldMapTitleWrapper>
        <WorldMapWrapper>
          <WorldMap
            tooltipBgColor={secondaryColor}
            color={grayd8d8d8}
            styleFunction={(countryContext: CountryContext) => {
              return mapDefaultStyles(countryContext);
            }}
            size='xxl'
            data={projectsByCountry}
            // onClickFunction={onCountryPress}
            tooltipTextFunction={(countryContext) => {
              return getTooltipText(countryContext, languageCode);
            }}
          />
        </WorldMapWrapper>
        {/* <CountryListWrapper>
          {
            countrySelected ? (
              <ProjectList countrySelected={countrySelected} />
            ) : null
          }
        </CountryListWrapper> */}
        <Content>
          <CountryListWrapperMobile>
            <CountryListHolder className='mexico'>
              <SText color={secondaryColor}>{t('presenceCountries.mexico')}</SText>
              <CountryList>
                <li>Tres Mesas III</li>
                <li>Reynosa I y V</li>
                <li>Los Altos</li>
                <li>Vicente Guerrero</li>
                <li>Tizimin</li>
                <li>La Amistad</li>
                <li>2 Arbolitos</li>
                <li>El Porvenir</li>
                <li>El Retiro</li>
                <li>La Mesa</li>
                <li>Le Ventosa III</li>
                <li>Peñasco II</li>
                <li>Piedra Larga I</li>
                <li>Puerto Peñasco</li>
                <li>San Jacinto</li>
                <li>Salitrillos</li>
                <li>Santo Domingo</li>
                <li>Stipa Naya</li>
                <li>Vientos del Altiplano</li>
                <li>Zopiloapan</li>
                <li>La Bufa</li>
                <li>Arriaga</li>
              </CountryList>
            </CountryListHolder>
            <CountryListHolder className='argentina'>
              <SText color={secondaryColor}>{t('presenceCountries.argentina')}</SText>
              <CountryList>
                <li>El llano</li>
                <li>Villalonga</li>
                <li>Banderitas</li>
                <li>Puerto Madryn</li>
                <li>Manantiales</li>
                <li>Cori II</li>
                <li>Pepe II y III</li>
                <li>Miramar</li>
                <li>Loma Blanca I y II</li>
                <li>Achiras</li>
                <li>Mataco</li>
                <li>Nerochea</li>
              </CountryList>
            </CountryListHolder>
            <CountryListHolder className='guatemala'>
              <SText color={secondaryColor}>{t('presenceCountries.guatemala')}</SText>
              <CountryList>
                <li>Las Cumbres</li>
              </CountryList>
            </CountryListHolder>
            <CountryListHolder className='chile'>
              <SText color={secondaryColor}>CHILE</SText>
              <CountryList>
                <li>Sierra Gorda</li>
                <li>San Pedro</li>
                <li>Cabo León</li>
              </CountryList>
            </CountryListHolder>
            <CountryListHolder className='dominicana'>
              <SText color={secondaryColor}>{t('presenceCountries.dominicanRepublic')}</SText>
              <CountryList>
                <li>Matafongos</li>
                <li>Larimar I</li>
                <li>Cocos II</li>
              </CountryList>
            </CountryListHolder>
            <CountryListHolder className='uruguay'>
              <SText color={secondaryColor}>{t('presenceCountries.uruguay')}</SText>
              <CountryList>
                <li>Maldonado</li>
              </CountryList>
            </CountryListHolder>
          </CountryListWrapperMobile>
        </Content>
      </Container>
    </OurExperienceWorldWrapper>
  )
}

export default OurExperienceInTheWorld