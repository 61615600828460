import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { Container, Content } from "src/design/containers/Container";
import { ColorWhite, Spacing } from "theme";
import {
  ImgWhatWeDoMobile,
  WhatWeDoContentWrapper,
  WhatWeDoHolder,
  WhatWeDoWrapper,
} from "./WhatWeDo.components";
import { aeroGeneradorHomeMobileImg } from "images";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const WhatWeDo: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <WhatWeDoWrapper>
      <Container backgroundColor="transparent">
        <WhatWeDoHolder>
          <WhatWeDoContentWrapper>
            <Content>
              <SectionTitle
                color={ColorWhite}
                style={{
                  marginBottom: Spacing.largeSpacing,
                  paddingTop: isMobile ? Spacing.largeSpacing : undefined,
                  maxWidth: 600,
                }}
                fontWeight="bold"
              >
                {t('home.whatWeDo.title')}
              </SectionTitle>
            </Content>
              {isMobile ? (
                <ImgWhatWeDoMobile src={aeroGeneradorHomeMobileImg} />
              ) : null}
            <Content>
              <SText
                color={ColorWhite}
                style={{ marginBottom: Spacing.largeSpacing }}
              >
                {t('home.whatWeDo.paragraph1')}
              </SText>
              <SText
                color={ColorWhite}
                style={{ marginBottom: Spacing.largeSpacing }}
              >
                {t('home.whatWeDo.paragraph2')}
              </SText>
            </Content>
          </WhatWeDoContentWrapper>
          <WhatWeDoContentWrapper />
        </WhatWeDoHolder>
      </Container>
    </WhatWeDoWrapper>
  );
};

export default WhatWeDo;
