import { imgPuestaSolPuente } from "images";
import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const VacanciesWrapper = styled.section.attrs((props)=>({
    className: 'vacancies-wrapper'
}))`
    background: url(${imgPuestaSolPuente}) top center no-repeat;
    background-size: cover;
    height: 777px;
    padding: 149px 0;
    position: relative;

    @media (max-width: ${Breakpoints.lg}){
        height: auto;
        background: transparent;
        padding: 0px 0;
    }
`;

export const MainVacancyHolder = styled.div.attrs((props)=>({
    className: 'vacancy-holder'
}))`
    max-width: 1213px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 3fr 1fr;

    @media (max-width: ${Breakpoints.lg}){
        grid-template-columns: 1fr;
    }
`;

export const VacanciesContentWrapper = styled.div.attrs((props)=>({
    className: 'vacancies-content-wrapper'
}))`
    flex: 1;

    .vacancy-item-wrapper {
        width: 280px;
        margin: 0 auto;
    }

    @media (max-width: ${Breakpoints.lg}){
        margin-bottom: ${Spacing.xxxxLargeSpacing};
    }
`;

export const VacanciesHolderSwiper = styled.div.attrs((props)=>({
    className: 'vacancies-holder-swiper'
}))`
    position: relative;
    margin-top: ${Spacing.xxxLargeSpacing};
    max-width: 1213px;
    margin: 0 auto;
    margin-top: 56px;
  
    .swiper-pagination {
      text-align: center;
      z-index: 10000;
      bottom: 30%;
      
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 55px;
      height: 55px;
      background-color: transparent;
      border-bottom: solid 6px #D82520;
      border-radius: 0;
    }

    .swiper-pagination-bullet{
      width: 55px;
      height: 55px;
      background-color: transparent;
      border-bottom: solid 3px white;
      border-radius: 0;
      opacity: 1;
    }

      .swiper-pagination {
        text-align: center;
        z-index: 10000;
        bottom: 0;
      }

    @media (max-width: ${Breakpoints.lg}){
        margin-top: 0;
      padding: 0px 0 60px 0;

      .swiper {

        .swiper-slide {
          text-align: center;
            width: 280px;
        }
      }

      .swiper-pagination {
        text-align: center;
        z-index: 10000;
        bottom: 0;
      }
    }
`;

export const VacanciesHolder = styled.div.attrs((props)=>({
    className: 'vacancies-holder'
}))`
    display: grid;
    grid-template-columns: repeat(4, 280px);
    gap: 25px;
    position: relative;
    justify-content: center;
    margin-top: ${Spacing.xxxLargeSpacing};

    @media (max-width: ${Breakpoints.xl}){
        grid-template-columns: 1fr 1fr;
        
        .vacancy-item-wrapper {
            margin: 0 auto;
            margin-bottom: ${Spacing.xLargeSpacing};
        }
    }

    @media (max-width: ${Breakpoints.sm}){
        grid-template-columns: 1fr;
    }
`;

export const VacancyHolder = styled.div.attrs((props)=>({
    className: 'vacancy-holder'
}))``;