import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { ColorWhite, Spacing } from "theme";

export const ClientsWrapper = styled.section.attrs((props)=>({
  className: 'clients-wraper'
}))`
  background-color: ${ColorWhite};
  padding: 160px 0;

  @media (max-width: ${Breakpoints.lg}){
    padding: 60px 0;
  }
`;

export const ClientsHolder = styled.div.attrs((props)=>({
  className: 'clients-holder'
}))``;

export const ClientsTitleWrapper = styled.div.attrs((props)=>({
  className: 'clients-title-wrapper'
}))`
  text-align: center;
  margin-bottom: 112px;

  @media (max-width: ${Breakpoints.lg}){
    margin-bottom: 0px;
  }
`;

export const ListClientsWrapper = styled.div.attrs((props)=>({
  className: 'list-clients-wrapper'
}))`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: ${Spacing.baseSpacing};
  text-align: center;

  @media (max-width: ${Breakpoints.lg}){
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${Breakpoints.md}){
    grid-template-columns: 1fr 1fr;
  }
`;

export const ClientImgWrapper = styled.div.attrs((props)=>({
  className: 'client-img-wrapper'
}))`
  width: auto;
  height: auto;
  margin-bottom: ${Spacing.baseSpacing};
  opacity: 0;

  @media (max-width: ${Breakpoints.lg}){
    margin-bottom: 0;
  }
`;

export const ClientImg = styled.img.attrs((props)=>({
  src: props.src
}))`
  width: 85%;
  height: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;