import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import { Breakpoints } from 'src/theme/breakpoints';
import styled from 'styled-components';
import { ColorLightGray, ColorRed, ColorWhite, Spacing } from 'theme';

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const Slider = styled(AutoplaySlider)`
  height: 700px;

  @media (max-height: ${Breakpoints.lg}){
    height: 45vh;
  }

  @media (max-width: ${Breakpoints.lg}){
    height: 320px;
  }

  .awssld__content div{
    z-index: 1;
  }

  .awssld__controls {
    span {
      ::before {
        background-color: ${ColorRed};
      }

      ::after {
        background-color: ${ColorRed};
      }
    } 
  }

  .awssld__bullets {
    align-items: flex-end;
    bottom: ${Spacing.baseSpacing};
    justify-content: flex-start;
    align-items: end;
    padding-left: 10%;
    z-index: 2;

    button {
      background-color: ${ColorLightGray};
      border-radius: 0;
      height: 5px;
      transition: none;
      width: 89px;

      :hover {
        transform: none;
      }
    }

    .awssld__bullets--active {
      transform: none;
      background-color: ${ColorRed};
      border-radius: 0;
      height: 10px;
      width: 89px;
    }
  }
`;

export const TitleSlider = styled.h1`
  font-size: 120px;
  color: ${ColorWhite};
  text-align: center;
  line-height: 96px;

  @media (max-width: ${Breakpoints.xl}){
    font-size: 100px;
    line-height: 80px;
  }

  @media (max-width: ${Breakpoints.lg}){
    font-size: 40px;
    line-height: 30px;
  }
`;