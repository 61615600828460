import { useTranslation } from "react-i18next";
import { OfficeProps } from "./Offices.types";

export const useOfficesController = () => {
  const { t } = useTranslation();

  const headquarters: OfficeProps[] = [
    {
     country: t('presenceCountries.mexico'),
     headQuarterName: 'Reyco Renovables',
     address: 'México Calle Niza 77 Piso 7, Col. Juárez, Del. Cuauhtémoc CDMX 06600, México',
     phone: '+52 (55) 3473 2533',
     email: 'Info@reycorenovables.com', 
    },
    {
      country: t('presenceCountries.usa'),
      headQuarterName: 'Reyco USA LLC',
      address: '5900 Balcones drive, set 100 Austin Tx. 78731 ',
      phone: '',
      email: 'Info@reycorenovables.com', 
     },
     {
      country: t('presenceCountries.spain'),
      headQuarterName: 'Monte Louro Renovables Plaza',
      address: 'Europa Num 15 Portal A planta 4 pt. C Complemem. Área central Localidad Fontiñas 15707 Santiago de Compostela.',
      phone: '',
      email: '', 
     },
     {
      country: t('presenceCountries.dominicanRepublic'),
      headQuarterName: 'Reyco Louro Dominicana',
      address: 'Av. Enrique Jiménez Moya no. 32, edificio corporativo Franco Acra primer nivel, Bella vista, Santo Domingo, Distrito nacional, Capital de la República Dominicana.',
      phone: '',
      email: '', 
     },
     {
      country: t('presenceCountries.peru'),
      headQuarterName: 'Reyco Louro Perú',
      address: 'Cal. German Shereiber 291, Urb. Santa Ana San Isidro, Lima.',
      phone: '',
      email: '', 
     },
     {
      country: t('presenceCountries.argentina'),
      headQuarterName: 'Reyco Renovables',
      address: 'Av. Pte. Roque Saenz Peña 616, Piso 8, Of. 804 C1035AAO, Buenos Aires, Argentina.',
      phone: '',
      email: '', 
     },
     {
      country: t('presenceCountries.colombia'),
      headQuarterName: 'Especialistas Renovables',
      address: ' Carrera 53 c no. 130-49 apt 501 Int. 4, Bogota, Colombia.',
      phone: '',
      email: '', 
     },
  ];

  return {
    headquarters,
  };
};