import { Breakpoints } from 'src/theme/breakpoints';
import styled from 'styled-components';
import { backgroundDarkColor, ColorWhite, Spacing } from 'theme';

export const ContentWrapper = styled.section.attrs((props)=>({
  className: 'content-wrapper'
}))<{backgroundColor?: string}>`
  background-color: ${(props) => props.backgroundColor || backgroundDarkColor};
  display: flex;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
    padding: 0 0 ${Spacing.xLargeSpacing};
  }
`;

export type ImageWrapperProps = {
  isSwitchMobile?: boolean;
};

export const ImgWrapper = styled.div.attrs((props)=>({
  className: 'img-wrapper'
}))<ImageWrapperProps>`
  height: auto;
  object-fit: cover;
  overflow: hidden;
  flex: 1;

  @media (max-width: ${Breakpoints.lg}){
    display: ${props => props.isSwitchMobile ? 'none' : 'block'};
    background-color: ${backgroundDarkColor};
    width: 100%;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`;

type ContentHolderProps = {
  isSwitchMobile?: boolean;
}

export const ContentHolder = styled.div.attrs(()=> ({
  className: 'content-holder'
}))<ContentHolderProps>`
  flex: 1;
  padding: ${Spacing.xxxxLargeSpacing};
  padding-top: 100px;

  @media (max-width: ${Breakpoints.lg}){
    padding: 0;
  }
`;

export const ImageMobile = styled.img.attrs(props => ({
  className: 'img-mobile'
}))`
  display: none;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: ${Spacing.largeSpacing};
  }
`;