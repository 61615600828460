import * as React from "react";
import { SVGProps } from "react";
const SvgCeldaSolar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 182.47 141.38"
    {...props}
  >
    <path
      d="M19.85 141.38c-.6-.7-1.39-1.3-1.76-2.1-1.05-2.31.66-4.43 3.59-4.44 9.51-.03 19.02-.01 28.53-.01h2.69c0-3.01-.01-5.89 0-8.76.02-3.36 1.03-4.37 4.44-4.38 7.11-.02 14.23 0 21.34 0h2.52v-18.53H5.35c-4.4 0-4.86-.78-5.21-5.2-.88-11.3 2.28-22 4.52-32.83C8.9 44.67 13.27 24.26 17.59 3.84 18.27.67 19.07.02 22.35.01c5.3-.02 10.6 0 15.9 0h120.86c4.78 0 5.19.36 6.18 5.08 5.58 26.6 11.31 53.17 16.62 79.82.99 4.96.38 10.26.33 15.4-.01 1.6-1.19 2.6-2.86 2.76-.79.08-1.59.07-2.39.07h-75.66v18.53h23.65c3.71 0 4.66.95 4.67 4.59v8.55h2.46c9.51 0 19.02.01 28.53 0 1.73 0 3.2.37 3.87 2.16.66 1.78-.07 3.09-1.58 4.06-.11.07-.15.23-.23.35H19.85Zm-13-44.96h168.77v-6.43H37.87c-2.95 0-4.49-1.12-4.52-3.22-.03-2.12 1.56-3.31 4.44-3.32 4.43-.01 8.85 0 13.28 0h7.87c.5-7.18.99-14.09 1.48-21.14H11.97c-1.47 6.98-2.93 13.96-4.44 21.14h2.66c3.92 0 7.84-.04 11.76.02 2.24.03 3.61 1.3 3.64 3.19.04 2.01-1.34 3.26-3.71 3.34-.94.03-1.89 0-2.83 0H6.86v6.42Zm110.18-13.05c-.51-7.14-1-14.05-1.5-21.05H67.02c-.5 7.08-.98 14.03-1.48 21.05h51.49Zm53.55-21.04h-48.45c.5 7.08.99 14 1.48 21.01h51.41c-1.49-7.06-2.95-14-4.43-21.01Zm-56.99-27.82H68.96c-.49 7.03-.96 13.94-1.45 20.91h47.53c-.49-7.05-.97-13.91-1.46-20.91Zm-95.77.01c-1.48 7.01-2.94 13.93-4.42 20.99h47.51c.48-7 .96-13.91 1.45-20.99H17.83ZM121.65 55.5h47.5c-1.48-7.04-2.94-13.96-4.42-20.99h-44.55c.49 7.07.98 13.98 1.47 20.99ZM70.92 6.76c-.49 7.03-.97 13.92-1.46 20.89h43.66c-.5-7.04-.99-13.94-1.49-20.89H70.92Zm-51.64 20.9h43.57c.49-7.01.98-13.9 1.48-20.91-.68-.06-1.1-.13-1.52-.13-12.47 0-24.94.01-37.42-.03-1.39 0-1.9.51-2.1 1.82-.27 1.79-.74 3.54-1.11 5.31-.96 4.59-1.91 9.18-2.9 13.94Zm98.94-20.93c.51 7.14 1 13.98 1.5 20.93h43.44c-.07-.63-.09-1.13-.19-1.61-1.21-5.74-2.54-11.45-3.6-17.22-.35-1.88-1.11-2.26-2.86-2.25-11.96.07-23.93.04-35.89.04-.71 0-1.41.06-2.4.11ZM123 128.4H59.64v6.3h63.35v-6.3Zm-28.54-6.85v-18.24h-6.32v18.24h6.32Z"
      style={{
        fill: "#d82620",
      }}
    />
  </svg>
);
export default SvgCeldaSolar;
