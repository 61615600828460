import * as React from "react";
import { SVGProps } from "react";
const SvgSuministros = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 176.38 167.03"
    {...props}
  >
    <path
      d="M83.17 61.25c-5.18-4.24-3.73-9.33-3.77-14.04 0-.69 1.31-1.95 1.98-1.93.73.02 1.94 1.15 2.06 1.93.3 2.04.11 4.14.13 6.22.02 3.05 1.82 5.09 4.51 5.16 2.8.06 4.72-2.03 4.73-5.18.01-4.49.07-8.98-.05-13.46-.03-.93-.56-1.99-1.16-2.75-4.22-5.35-8.62-10.56-12.75-15.97-2.58-3.37-.64-7.38 3.59-7.76 1.9-.17 3.82-.08 5.73-.1 1.82-.01 3.64 0 4.95 0-.09-3.98-.26-7.79-.18-11.58.01-.63 1.03-1.61 1.71-1.74.66-.12 2.1.59 2.14 1.03.35 3.98.9 8-.49 12.29h7.22c1.33 0 2.67-.05 3.99.08 4.23.4 6.2 4.41 3.63 7.76-4.25 5.53-8.77 10.85-13.12 16.31-.51.64-.84 1.58-.87 2.39-.1 3.32-.11 6.65-.02 9.97.11 4.13.27 8.23-3.57 11.16 7.12 8.07 14.04 15.91 20.97 23.76 14.24 16.14 28.51 32.26 42.69 48.46 1.51 1.72 3.01 2.55 5.31 2.43 3.4-.17 6.81-.07 10.22-.04 3.21.03 3.65.46 3.65 3.58.01 8.06.01 16.13 0 24.19 0 2.97-.64 3.59-3.69 3.62-2.33.02-4.65 0-6.98 0H5.14c-4.97 0-5.11-.14-5.11-5.2v-22.69c0-3.04.44-3.72 3.47-3.43 3.43.32 5.66-1.09 7.98-3.42 22.79-22.94 45.68-45.78 68.56-68.62.82-.81 1.84-1.42 3.15-2.4ZM4.51 139.91v22.65h167.41v-22.65H4.51Zm148.99-4.47c-1.21-1.4-2-2.34-2.81-3.26l-59.55-67.5c-2.33-2.64-3.49-2.67-6.06-.1-22.87 22.86-45.73 45.73-68.59 68.59-.6.6-1.14 1.24-2 2.19h39.87v-18.18c0-3.87.42-4.3 4.25-4.3h58.35c4.91 0 5.07.15 5.07 4.98v17.57h31.47Zm-94.64-18.11v18.11h58.72v-18.11H58.86Zm36.01-82.79c4.61-5.67 8.91-10.95 13.6-16.71H81.5c4.65 5.81 8.86 11.09 13.36 16.71Z"
      style={{
        fill: "#d82620",
      }}
    />
  </svg>
);
export default SvgSuministros;
