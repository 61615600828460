import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing, backgroundLightColor } from "theme";

export const OurServicesWrapper = styled.section.attrs(()=>({
  className: 'our-services-wrapper'
}))`
  padding: ${Spacing.xLargeSpacing} 0;

  @media (max-width: ${Breakpoints.lg}){
    background-color: ${backgroundLightColor};
  } 
`;

export const OurServicesHolder = styled.div.attrs(()=> ({
  className: 'our-services-holder'
}))`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 
    "title text"
    "swiper swiper"
  ;

  & .title {
    grid-area: title;
  }

  & .text {
    grid-area: text;
  }

  & .swiper {
    grid-area: swiper;

  }

  @media (max-width: ${Breakpoints.lg}){
    .swiper {

      .swiper-slide {
        text-align: center;
          width: 280px;
      }
    }

    grid-template-columns: 1fr;
    grid-template-areas: 
    "title"
    "swiper"
    "text"
    ;
  } 
`;

export const OurServicesTitlesWrapper = styled.div.attrs(()=> ({
  className: 'our-services-title-wrapper'
}))`
`;

export const OurServicesTitlesHolder = styled.div.attrs(()=> ({
  className: 'our-service-title-holder'
}))`
`;

export const OurServicesContentHolder = styled.div.attrs(()=> ({
  className: 'our-services-content-holder'
}))`
  flex: 1;

  @media (max-width: ${Breakpoints.lg}){
   display: block;
 } 
`;