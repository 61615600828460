import { GroupBase, StylesConfig } from "react-select";
import { Spacing, blackColor, grayc9c9c9, grayd8d8d8, primaryColor, secondaryColor, whiteColor } from "theme";
import { SelectOption } from "./Select";



export const getContainerStyles = (isMobile?: boolean, isLanguageSelect?: boolean): StylesConfig<SelectOption, false, GroupBase<SelectOption>> | undefined => {
  return {
    container: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'transparent',
      border: isLanguageSelect ? 'none' : `1px ${isMobile ? blackColor : whiteColor} solid`,
      color: isMobile ? blackColor : whiteColor,
      fontSize: '16px',
      height: '52px',
      marginBottom: Spacing.baseSpacing,
      outline: 'none',
      width: '100%',
    }),
    control: (baseStyles) => ({
      ...baseStyles,
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      outline: 'none',
      border: 'none',
      boxShadow: 'none',
    }),
    indicatorSeparator: (baseStyles) => ({
      display: 'none',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: isMobile ? blackColor : whiteColor
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: isMobile ? blackColor : whiteColor,
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'gray',
    }),
    option: (baseStyles, { isSelected }) => ({
      ...baseStyles,
      backgroundColor: isSelected ? secondaryColor : 'transparent',
      color: whiteColor,
      ':hover': {
        backgroundColor: secondaryColor,
      },
    }),
  };
};


