import styled, { keyframes } from "styled-components";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { Breakpoints } from "src/theme/breakpoints";
import { blackColor } from "theme";

export const ScreenHeaderWrapper = styled.section.attrs((props)=>({
  className: 'screen-header'
}))`
  background-size: cover;
  height: 700px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  ::before {
    content: '';
    display: block;
    background-color: ${blackColor};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-height: ${Breakpoints.lg}){
    height: 45vh;
  }

  @media (max-width: ${Breakpoints.lg}){
    height: 320px;
    margin-top: 43px;
  }
`;

type ScreenHeaderImgProps = {
  sectionName: SectionName;
};

export const ScreenHeaderImgAnim = styled.img.attrs((props)=>({
  className: 'screen-header-img-anim'
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  transform-origin: center;
  transform: scale(1.4);
`;

export const ScreenHeaderImg = styled.img.attrs((props)=>({
  className: 'screen-header-img'
}))<ScreenHeaderImgProps>`
  width: ${props => 
    props.sectionName === SectionName.NOSOTROS ? '40%' 
    : props.sectionName === SectionName.SERVICIOS ? '55%'
    : props.sectionName === SectionName.PARQUES_EOLICOS ? '25%'
    : props.sectionName === SectionName.SUSTENTABILIDAD ? '55%'
    : props.sectionName === SectionName.CARRERAS ? '40%'
    : props.sectionName === SectionName.PERFILES ? '45%'
    : props.sectionName === SectionName.NOTICIAS ? '35%'
    : props.sectionName === SectionName.BROCHURE ? '25%'
    : props.sectionName === SectionName.CONTACTO ? '50%'
    : props.sectionName === SectionName.PROYECTOS ? '55%'
    : null
  };
  z-index: 2;
  margin-left: 0;
  opacity: 0;

  @media (max-width: ${Breakpoints.md}){
    width: ${props => 
        props.sectionName === SectionName.NOSOTROS ? '50%' 
      : props.sectionName === SectionName.SERVICIOS ? '65%'
      : props.sectionName === SectionName.SUSTENTABILIDAD ? '65%'
      : props.sectionName === SectionName.CARRERAS ? '55%'
      : props.sectionName === SectionName.PERFILES ? '70%'
      : props.sectionName === SectionName.NOTICIAS ? '55%'
      : props.sectionName === SectionName.BROCHURE ? '40%'
      : props.sectionName === SectionName.CONTACTO ? '55%'
      : null
    };
  }
`;

const headerTitleAnimation = keyframes`
  from {
    left: -5%;
    opacity: 0;
  }
  to {
    left: 10%;
  }
`;

const headerTitleAnimationMobile = keyframes`
  from {
    left: -5%;
    opacity: 0;
  }
  to {
    left: 5%;
  }
`;

export const HeaderTitleWrapper = styled.div.attrs((props) => ({
  className: 'header-title-wrappper'
}))`
  position: absolute;
  left: 10%;
  z-index: 1;
  animation: ${headerTitleAnimation} 2s forwards;

  @media (max-width: ${Breakpoints.xl}) {
    animation: ${headerTitleAnimationMobile} 2s forwards;
  }
`;

export const HeaderTitle = styled.h1.attrs((props)=>({
  clasName: 'header-title'
}))`
  font-size: 150px;
  color: white;
  -webkit-text-stroke: 1px white;
  color: transparent;

  @media (max-width: ${Breakpoints.xl}) {
    font-size: 120px;
  }

  @media (max-width: ${Breakpoints.md}) {
    font-size: 80px;
  }

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 60px;
  }
`;

export const HeaderSubtitle = styled.h1.attrs((props)=>({
  clasName: 'header-subtitle'
}))`
  font-size: 80px;
  color: white;
  position: absolute;
  top: 25%;
  left: 2%;
  width: 110%;

  @media (max-width: ${Breakpoints.xl}) {
    font-size: 65px;
  }

  @media (max-width: ${Breakpoints.md}) {
    font-size: 45px;
  }

  @media (max-width: ${Breakpoints.sm}) {
    font-size: 33px;
  }
`;