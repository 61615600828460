import React from 'react';
import TagItem from 'atoms/tagItem/TagItem';
import { TagListSideBarWrapper } from './TagListSideBar.components';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';

const TagListSideBar: React.FC = () => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const [tags] = useAllPrismicDocumentsByType('post_tag', {
    lang: getPrismicLanguage(languageCode),
  });
  
  return (
    <TagListSideBarWrapper>
      {
        tags &&
        tags.map((tag)=>{
          return(
            <TagItem 
              key={tag.id}
              tagId={tag.id}
              tag={tag.data.name} 
            />
          )
        })
      }
    </TagListSideBarWrapper>
  )
}

export default TagListSideBar