import React from "react";
import { SText } from "atoms/text/Text";
import { LastPostItemProps } from "custom-types/design/molecules/LastPostItem";
import { getPostDate } from "fixtures/dates";
import { Spacing } from "theme";
import {
  LastPostItemHolder,
  LastPostItemImage,
  LastPostItemImageWrapper,
  LastPostItemContentWrapper,
  LastPostItemWrapper,
} from "./LastPostItem.components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageCodes } from "molecules/selectLanguage/SelectLanguage.types";

const LastPostItem: React.FC<LastPostItemProps> = ({
  topImage,
  title,
  postedOn,
  uid,
}) => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const navigate = useNavigate();

  const handlePostClick = (uid: string | null) => {
    navigate(`/Blog/${uid}`);
  };

  return (
    <LastPostItemWrapper
      onClick={() => {
        handlePostClick(uid);
      }}
    >
      <LastPostItemHolder>
        <LastPostItemImageWrapper>
          <LastPostItemImage src={topImage} />
        </LastPostItemImageWrapper>
        <LastPostItemContentWrapper>
          <SText marginBottom={Spacing.tinySpacing}>{title}</SText>
          <SText fontWeight="bold">
            {getPostDate(postedOn, languageCode)}
          </SText>
        </LastPostItemContentWrapper>
      </LastPostItemHolder>
    </LastPostItemWrapper>
  );
};

export default LastPostItem;
