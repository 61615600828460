import React, { useRef } from "react";
import SButton from "atoms/button/Button";
import Input from "atoms/input/Input";
import { SText } from "atoms/text/Text";
import TextArea from "atoms/textArea/TextArea";
import { Container } from "src/design/containers/Container";
import {
  ContactFormHolder,
  ContactFormWrapper,
  FieldsWrapper,
} from "./ContactForm.components";
import useContactForm from "hooks/useContactForm";
import Loader from "atoms/loader/Loader";
import { ColorBlack, ColorWhite } from "theme";
import InputDummy from "atoms/input/InputDummy";
import { Paperclip } from "phosphor-react";
import { useContactFormController } from "./ContactForm.controller";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";
import Select from "atoms/select/Select";

const ContactForm: React.FC = () => {
  const {t} = useTranslation();
  const {
    isLoading,
    name,
    email,
    phone,
    message,
    file,
    handleName,
    handleEmail,
    handlePhone,
    handleSubject,
    handleMessage,
    handleSelectFile,
    validateForm,
  } = useContactForm();

  const isMobile = isMobileDevice();

  const {contactSubjects} = useContactFormController();

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleClickFileInput = () => {
    inputFileRef.current?.click();
  }

  return (
    <ContactFormWrapper>
      <Container>
        <ContactFormHolder>
          <SText fontWeight="bold" color={isMobile ? undefined : ColorWhite} variant="sectionText">
            {t("contact.contactForm.title")}
          </SText>
          <SText color={isMobile ? undefined : ColorWhite}>
            {t("contact.contactForm.paragraph1")}
          </SText>
          <FieldsWrapper>
            <div style={{ flex: 1 }}>
              <Input
                isMobile={isMobile}
                placeholder={t("contact.contactForm.inputName")}
                value={name}
                onChange={handleName}
                type="text"
                required
              />
              <Input
                isMobile={isMobile}
                placeholder={t("contact.contactForm.inputPhone")}
                value={phone}
                onChange={handlePhone}
                type="number"
                pattern="[0-9]*"
                required
                maxLength={12}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Input
                isMobile={isMobile}
                placeholder={t("contact.contactForm.inputEmail")}
                value={email}
                onChange={handleEmail}
                type="email"
                required
              />
              <Select
                options={
                  contactSubjects.map((contactSubject) => ({label: contactSubject, value: contactSubject}))
                }
                onChange={handleSubject}
                isMobile={isMobile}
                placeholder={t("contact.contactForm.subject")}
              />
            </div>
          </FieldsWrapper>
          <InputDummy 
            isMobile={isMobile}
            style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
            onClick={handleClickFileInput}
          >
            <Paperclip
              style={{marginRight: '16px'}}
              color={isMobile ? ColorBlack : ColorWhite}
              size={32}
              weight="thin"
            />
            {
              file ? (
                <SText
                  style={{"lineHeight": '100%'}}
                  marginBottom="0px"
                  color={isMobile ? ColorBlack : ColorWhite}
                >
                  {file.name}
                </SText>
              ) : (
                <SText
                  style={{"lineHeight": '100%'}}
                  marginBottom="0px"
                  color={isMobile ? ColorBlack : ColorWhite}
                >
                  {t("contact.contactForm.inputFile")}
                </SText>
              )
            }
          </InputDummy>
          <Input
            isMobile={isMobile}
            ref={inputFileRef}
            style={{"display": "none"}}
            type="file"
            placeholder={t("contact.contactForm.inputFile")}
            onChange={handleSelectFile}
          />
          <TextArea
            isMobile={isMobile}
            width="100%"
            placeholder={t("contact.contactForm.inputMessage")}
            value={message}
            onChange={handleMessage}
            required
          />
          <SButton
            title="Enviar"
            onClick={validateForm}
            variant={isMobile ? "accent" : "primary-white"}
            style={{ width: "100%" }}
          >
            {t("contact.contactForm.textButton")}
          </SButton>
        </ContactFormHolder>
      </Container>
      {isLoading && <Loader />}
    </ContactFormWrapper>
  );
};

export default ContactForm;
