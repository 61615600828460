import React from 'react';
import { BrochureItemPprops } from 'custom-types/design/molecules/BrochureItem';
import { BrochureItemContent, BrochureItemHolder, BrochureItemWrapper } from './BrochureItem.components';
import {SectionTitle, SText} from 'atoms/text/Text';
import { ColorWhite, Spacing } from 'theme';
import { Container, Content } from 'src/design/containers/Container';
import SButton from 'atoms/button/Button';
import { isMobileDevice } from 'utils/isMobileDevice';

const BrochureItem: React.FC<BrochureItemPprops> = ({imgBackground, title, text, textButton, onClickButton, imageMobile}) => {
  const isMobile = isMobileDevice();

  return (
    <BrochureItemWrapper
      imgBackground={isMobile  ? undefined:  imgBackground}
    >
      <Container>
        <Content>

          <BrochureItemHolder>
            <BrochureItemContent>
              <SectionTitle
                color={isMobile ? undefined : ColorWhite}
              >
                {title}
              </SectionTitle>
              {isMobile ? (
                <img style={{height: 261, width: '100%', marginBottom: Spacing.xLargeSpacing}} src={imageMobile} alt='Brochure spanish' />
              ) : null}
              <SText
                  color={isMobile ? undefined : ColorWhite}
              >
                {text}
              </SText>
            </BrochureItemContent>
            <BrochureItemContent>
              <SButton
                style={isMobile ? {width: '100%', marginTop: Spacing.largeSpacing} : undefined}
                title={textButton}
                onClick={onClickButton}
                variant={isMobile ? 'primary' : 'primary-white'}
              >
                {textButton}
              </SButton>
            </BrochureItemContent>
          </BrochureItemHolder>  
        </Content>
      </Container>
    </BrochureItemWrapper>
  )
}

export default BrochureItem