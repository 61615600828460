import React from 'react';
import { usePrismicDocumentsByType } from '@prismicio/react';
import LastPostItem from 'molecules/lastPostItem/LastPostItem';
import {asText} from '@prismicio/helpers'
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';

const LatestPostList: React.FC = () => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;

  const [latestPosts] = usePrismicDocumentsByType('post',{
    graphQuery: `{
      post {
        top_image
        title
        posted_on
      }
    }`,
    orderings: ['my.post.posted_on desc'],
    lang: getPrismicLanguage(languageCode),
    pageSize: 5
  });

  return (
    <>
      {
        latestPosts?.results.map((lastPost: any)=>{
          return(
            <LastPostItem 
              key={lastPost.id}
              topImage={lastPost.data.top_image.url}
              title={asText(lastPost.data.title) || ''}
              postedOn={lastPost.data.posted_on}
              uid={lastPost.uid || ''}
            />
          )
        })
      }
    </>
  )
}

export default LatestPostList