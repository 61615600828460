import React, { useRef } from 'react';
import { SText } from 'atoms/text/Text';
import { ServiceItemProps } from 'custom-types/design/molecules/ServiceItem';
import { ColorWhite } from 'theme';
import { ServiceItemImg, ServiceItemOverlay, ServiceItemWrapper } from './ServiceItem.components';
import { gsap } from 'gsap';

const ServiceItem: React.FC<ServiceItemProps> = ({service, image, onClick}) => {

  const overlayRef = useRef(null);
  const textRef = useRef(null);
  const imgRef = useRef(null);

  // const handleOverlayHover = ()=> {
  //   gsap.to(overlayRef.current, {opacity: 1});
  //   gsap.to(textRef.current, {opacity: 0});
  //   gsap.to(imgRef.current, {transform: 'scale(1.5)', duration: 1});
  // }

  // const handleOverlayLeave = ()=> {
  //   gsap.to(overlayRef.current, {opacity: 0});
  //   gsap.to(textRef.current, {opacity: 1});
  //   gsap.to(imgRef.current, {transform: 'scale(1)', duration: 1});
  // }

  return (
    <ServiceItemWrapper
      onClick={onClick}
      // onMouseEnter={handleOverlayHover}
      // onMouseLeave={handleOverlayLeave}
    >
      <ServiceItemImg ref={imgRef} src={image} />
      <SText
        ref={textRef}
        color={ColorWhite}
        fontWeight='bold'
      >
        {service}
      </SText>
      <ServiceItemOverlay ref={overlayRef}>
        <SText
          color={ColorWhite}
          marginBottom='0'
          fontWeight='bold'
        >
          {service}
        </SText>
      </ServiceItemOverlay>
    </ServiceItemWrapper>
  )
}

export default ServiceItem