import styled from "styled-components";
import { Spacing, blackColor, whiteColor } from "theme";
import { InputProps } from "./Input";

const InputDummy = styled.div.attrs(props => ({
  className: 'input-dummy'
}))<InputProps>`
  background-color: transparent;
  border: 1px ${({isMobile}) => isMobile ? blackColor : whiteColor} solid;
  padding: 16px;
  font-size: 16px;
  width: 100%;
  height: 52px;
  margin-bottom: ${Spacing.baseSpacing};
  color: ${({isMobile}) => isMobile ? blackColor : whiteColor};
  cursor: pointer;
`;

export default InputDummy;

