import { TextAreaType } from "custom-types/design/atoms/TextArea";
import styled from "styled-components";
import { blackColor, whiteColor } from "theme";

const TextArea = styled.textarea.attrs(props => ({
  placeholder: props.placeholder
}))<TextAreaType>`
  background: transparent;
  border: 1px ${({isMobile}) => isMobile ? blackColor : whiteColor} solid;
  font-size: 16px;
  height: ${props => props.height || '218px'};
  padding: 16px;
  resize: none;
  width: ${props => props.width || '621px'};
  color: ${({isMobile}) => isMobile ? blackColor : whiteColor};
  
  ::placeholder{
    color: ${({isMobile}) => isMobile ? blackColor : whiteColor};
  }
`;

export default TextArea;