import React, { useEffect } from 'react';
import { SectionTitle, SText } from 'atoms/text/Text';
import { Container, Content } from 'src/design/containers/Container';
import { ColorWhite, Spacing } from 'theme';
import { MoreAboutReycoContentWrapper, MoreAboutReycoHolder, MoreAboutReycoImg, MoreAboutReycoImgWrapper, MoreAboutReycoWrapper } from './MoreAboutReyco.components';
import SButton from 'atoms/button/Button';
import { imgMolinoDeViento } from 'images';
import { useNavigate } from 'react-router-dom';
import gsap from 'gsap';
import { isMobileDevice } from 'utils/isMobileDevice';
import { useTranslation } from 'react-i18next';


const MoreAboutReyco: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = isMobileDevice();

  const handleNav = ()=> {
    navigate('/Brochure');
  }

  useEffect(()=>{
    gsap.fromTo('.more-about-reyco-img', {left: '-100%', opacity: 0}, {left: 0, opacity: 1, scrollTrigger: {trigger: '.more-about-reyco-img', start: 'top top+=120px'}});
  },[]);

  return (
    <MoreAboutReycoWrapper>
      <Container>
        <MoreAboutReycoHolder>
          {!isMobile ? (
            <MoreAboutReycoImgWrapper style={{marginBottom: Spacing.xLargeSpacing}}>
              <MoreAboutReycoImg src={imgMolinoDeViento} />
            </MoreAboutReycoImgWrapper>
          ) : null}
          <MoreAboutReycoContentWrapper>
            <Content>
              <SectionTitle
                style={isMobile ? {paddingTop: Spacing.largeSpacing} : undefined}
                color={ColorWhite}
                fontWeight='bold'
              >
                {t('home.aboutReyco.title')}
              </SectionTitle>
            </Content>
              {isMobile ? (
                <MoreAboutReycoImgWrapper style={{marginBottom: Spacing.xLargeSpacing}}>
                  <MoreAboutReycoImg src={imgMolinoDeViento} />
                </MoreAboutReycoImgWrapper>
              ) : null}
            <Content>
              <SText
                color={ColorWhite}
                style={{
                  marginBottom: Spacing.xxxxLargeSpacing
                }}
              >
                {t('home.aboutReyco.paragraph1')}
              </SText>
              <SButton
                title='Ver más' 
                onClick={handleNav}
                variant='primary-white'
              >
                {t('home.aboutReyco.buttonText')}
              </SButton>
            </Content>
          </MoreAboutReycoContentWrapper>
        </MoreAboutReycoHolder>
      </Container>
    </MoreAboutReycoWrapper>
  )
}

export default MoreAboutReyco