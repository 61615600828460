import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { backgroundDarkColor, ColorBlack, ColorRed, secondaryColor, Spacing, whiteColor } from "theme";
import {List, X, CaretDown, CaretUp} from 'phosphor-react';
import { Breakpoints } from "src/theme/breakpoints";

export const LogoWrapper = styled.div.attrs(()=>({
  className: "logo-wrapper"
}))`
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.lg}){
    margin-right: 0px;
    justify-content: space-between;
    height: 100%;
  }
`;

export const MenuMobileWrapper = styled.div.attrs((props)=>({
  className: 'menu-mobile-wrapper'
}))`
  width: 32;
  height: 32;
  display: none;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
  }
`;

export const MenuMobile = styled(List)``;

export const CloseIcon = styled(X)`
  display: none;
  cursor: pointer;
  margin: 8px 12px;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
  }
`;

export const SCaretDown = styled(CaretDown)`
  display: none;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
  }
`;

export const SCaretUp = styled(CaretUp)`
  display: none;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
  }
`;

export const MainMenuWrapperRelative = styled.div.attrs((props)=>({
  className: 'main-menu-wrapper-relative'
}))`
  position: relative;
  height: 75px;

  @media (max-width: ${Breakpoints.lg}) {
    height: auto;
  }
`;

export const MainMenuWrapperFixed = styled.div.attrs((props)=>({
  className: 'main-menu-wrapper-fixed'
}))`
  background: ${backgroundDarkColor};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
  width: 100%;
  box-shadow: 0 2px 22px ${ColorBlack};
`;

export const MainMenuWrapper = styled.div.attrs((props)=>({
  className: 'main-menu-wrapper'
}))`
  display: flex;
  align-items: center;
  height: 75px;
  justify-content: space-around;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    height: 43px;
  }
`;

export const MenuWrapper = styled.ul.attrs((props)=>({
  className: 'menu-wrapper'
}))`
  list-style: none;

  @media (max-width: ${Breakpoints.lg}) {
    position: fixed;
    right: -100%;
    top: 0;
    width: 75%;
    height: 100%;
    background-color: ${backgroundDarkColor};
    padding-left: ${Spacing.smallSpacing};
    overflow-y: scroll;
  }
`;

export const SubMenuWrapper = styled.ul.attrs((props)=>({
  className: 'submenu-wrapper'
}))`
  list-style: none;
  position: absolute;
  top: 100%;
  width: max-content;
  background: ${backgroundDarkColor};
  display: none;

  &.open {
    display: block;
  }

  @media (max-width: ${Breakpoints.lg}) {
    display: none;
    position: relative;
    padding-bottom: ${Spacing.smallSpacing};
    margin-top: ${Spacing.baseSpacing};
  }
`;

export const MenuLine = styled.div.attrs((props)=>({
  className: 'menu-line'
}))`
  width: 74px;
  height: 2px;
  background: ${ColorRed};
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 0;
`;

export const MenuLink = styled.li`
  display: inline-block;
  position: relative;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    margin-bottom: ${Spacing.smallSpacing};
    position: relative;
  }
`;

export const SelectLanguageWrapper = styled.div.attrs((props)=>({
  className: 'select-lanhguage-wrapper'
}))`
  display: inline-block;
  position: relative;
`;  

export const SubMenuLink = styled.li`
  display: block;
  position: relative;
  padding: ${Spacing.smallSpacing};

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    margin-bottom: ${Spacing.smallSpacing};
    padding: ${Spacing.tinySpacing};
  }
`;

export const Link = styled(NavLink).attrs((props)=>({
  style: props.style,
}))` 
  color: ${whiteColor};
  text-decoration: none;
  padding: 0 13px;

  :hover {
    color: ${secondaryColor} !important;
  }

  @media (max-width: ${Breakpoints.lg}) {
    display: inline-block;
    margin: 0;
  }
`;

export const FalseLink = styled.span`
  color: ${whiteColor};
  text-decoration: none;
  padding: 0 13px;
  cursor: pointer;

  :hover {
    color: ${ColorRed} !important;
  }

  @media (max-width: ${Breakpoints.lg}) {
    display: inline-block;
    margin: 0;
  }
`;