import React, { useEffect } from 'react';
import { Caption, SectionTitle, SSpan } from 'atoms/text/Text'
import { ColorRed, secondaryColor } from 'theme'
import { ClientsHolder, ClientsTitleWrapper, ClientsWrapper, ListClientsWrapper, ClientImgWrapper, ClientImg } from './Clients.components';
import { accionaLogo, aeromexicoLogo, aurigaLogo, bbvaLogo, cfeLogo, dphLogo, enelLogo, enerconLogo, goldwindLogo, grupoDragonLogo, iberdrolaLogo, senvionLogo, siemensLogo, suzlonLogo, totalLogo, vestasLogo } from 'images';
import { Container, Content } from 'src/design/containers/Container';
import gsap from 'gsap';
import { useTranslation } from 'react-i18next';

const Clients: React.FC = () => {
  const {t} = useTranslation();

  useEffect(()=>{
    gsap.to('.client-img-wrapper', {opacity: 1, stagger: 0.25, scrollTrigger: {
      trigger: '.clients-wraper',
      start: 'top top'
    }});
  });

  return (
    <ClientsWrapper>
      <Container>
        <ClientsHolder>
          <Content>
            <ClientsTitleWrapper>
              <Caption
                fontSize='14px'
                style={{textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold'}}
                color={secondaryColor}
              >
                {t('home.clients.caption')}
              </Caption>
              <SectionTitle
                fontWeight='bold'
              >
                {t('home.clients.title1')} <SSpan variant='sectionTitle' color={ColorRed}> {t('home.clients.title2')} </SSpan> {t('home.clients.title3')}
              </SectionTitle>
            </ClientsTitleWrapper>
          </Content>
          <ListClientsWrapper>
            <ClientImgWrapper>
              <ClientImg src={enerconLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={iberdrolaLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={vestasLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={grupoDragonLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={siemensLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={accionaLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={suzlonLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={senvionLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={totalLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={aurigaLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={enelLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={goldwindLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={aeromexicoLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={cfeLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={dphLogo} />
            </ClientImgWrapper>
            <ClientImgWrapper>
              <ClientImg src={bbvaLogo} />
            </ClientImgWrapper>
          </ListClientsWrapper>
        </ClientsHolder>
      </Container>
    </ClientsWrapper>
  )
}

export default Clients