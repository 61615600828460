import React, {useContext, useState, useEffect} from 'react';
import { PaginationNavItem, PaginationPostsItem, PaginationPostsItemsWrapper, PaginationPostsWrapper } from './PaginationPost.components';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import { useTranslation } from 'react-i18next';

const PaginationPosts: React.FC = () => {
  const {t} = useTranslation();
  const [pages, setPages] = useState<number[]>([]);
  const {totalPages, setCurrentPage, currentPage} = useContext(SearchPostContext);

  useEffect(()=>{
    var numPages = [];
    if(totalPages){
      for (var i = 1; i <= totalPages; i++) {
        numPages.push(i);
      }
    }
    setPages(numPages);
  },[totalPages]);

  const scrollToTopBlog = ()=> {
    const blogholderTop = (document.querySelector('.blog-holder') as HTMLDivElement).offsetTop;
    window.scrollTo(0, blogholderTop);
  }

  const hanldeCurrentPage = (currentPageSelected: number)=> {
    scrollToTopBlog();
    setCurrentPage && setCurrentPage(currentPageSelected);
  }

  const handlePrevPage = ()=> {
    if(currentPage && totalPages && currentPage - 1 >= 1){
      scrollToTopBlog();
      setCurrentPage && setCurrentPage(currentPage - 1 );
    }
  }

  const handleNextPage = ()=> {
    if(currentPage && totalPages && currentPage + 1 <= totalPages){
      scrollToTopBlog();
      setCurrentPage && setCurrentPage(currentPage + 1 );
    }
  }

  return (
    <PaginationPostsWrapper>
      {
        pages.length > 0 &&
          <PaginationPostsItemsWrapper>
            {
              currentPage && totalPages && currentPage > 1 &&
                <PaginationNavItem
                  onClick={handlePrevPage}
                >
                    {t('blog.prevText')}
                </PaginationNavItem>
            }
              {
                pages.map((page, index)=>{
                  return(
                    <PaginationPostsItem
                      key={page}
                      active={(index+1) === currentPage}
                      onClick={()=>{hanldeCurrentPage(page)}}
                    >
                      {page}
                    </PaginationPostsItem>
                  )
                })
              }
              {
                currentPage && totalPages && currentPage < totalPages &&
                  <PaginationNavItem
                    onClick={handleNextPage}
                  >
                      {t('blog.nextText')}
                  </PaginationNavItem>
              }
          </PaginationPostsItemsWrapper>
      }
    </PaginationPostsWrapper>
  )
}

export default PaginationPosts