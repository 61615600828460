export const config = {
    urls: {
        youtubeVideo: "https://www.youtube.com/embed/3u3yuX79M8U?rel=0",
        instagram: "https://www.instagram.com/reycorenovables/",
        youtube: "https://www.youtube.com/channel/UCQP8usUY59cO-X2q_h7UwBw",
        twitter: "https://twitter.com/reycorenovables",
        linkedin: "https://www.linkedin.com/company/reycorenovables/",
    },
    firebaseConfig: {
        apiKey: "AIzaSyDCpXHpOgCkRWrDTkaFalbh5xARQVys91o",
        authDomain: "reyco-f0d53.firebaseapp.com",
        projectId: "reyco-f0d53",
        storageBucket: "reyco-f0d53.appspot.com",
        messagingSenderId: "482326939222",
        appId: "1:482326939222:web:4073bef3d03e2e8d2d1df7",
        measurementId: "G-BM40KSC86D"
    }
}