import { clear } from 'console';
import React, { ChangeEvent, Dispatch, useEffect } from 'react';
import { createContext, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';


type SearchPostContextProps = {
    searchText: string;
    searchPost: string;
    handleSearchText: (e: ChangeEvent<HTMLInputElement>)=>void;
    handleSearchPost: ()=>void;
    searchCategoryId: string;
    handleSearchCategoryId: (categoryId: string, category: string)=>void;
    searchCategory: string;
    handleSearchMonth: (month: number)=>void;
    searchMonth: number;
    searchTag: SearchByTagProps;
    handleSearchTag: (tagInfo: SearchByTagProps)=>void;
    pageSize: number;
    currentPage: number;
    setCurrentPage: Dispatch<React.SetStateAction<number>>;
    totalPages: number;
    setTotalPages: Dispatch<React.SetStateAction<number | undefined>>;
    clearSearchByText: ()=>void;
    clearSearchByCategory: ()=>void;
    clearSearchByMonth: ()=>void;
    clearSearchByTag: ()=>void;
};

export enum FiltersType {
    BY_TEXT = 'by text',
    BY_CATEGORY = 'by category',
    BY_MONTH = 'by month',
    BY_TAG = 'by tag',
};

type SearchPostProviderProps = {
    children: ReactNode;
};

type SearchByTagProps = {
    tagId: string;
    tag: string;
};

export const SearchPostContext = createContext<Partial<SearchPostContextProps>>({});


const SearchPostProvider: React.FC<SearchPostProviderProps> = ({children})=> {
    const {i18n} = useTranslation();
    const [searchPost, setSearchPost] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [searchCategoryId, setSearchCategoryId] = useState<string>('');
    const [searchCategory, setSearchCategory] = useState<string>('');
    const [searchMonth, setSearchMonth] = useState<number | undefined>(undefined);
    const [searchTag, setSearchTag] = useState<SearchByTagProps>({
        tagId: '',
        tag: '',
    });
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
    const pageSize = 4;

    useEffect(()=>{
        resetCurrentPage();
        clearSearchByText();
        clearSearchByCategory();
        clearSearchByMonth();
        clearSearchByTag();
    },[i18n.language]);

    const resetCurrentPage = ()=> {
        setCurrentPage(1);
    }

    const clearSearchByText = ()=>{
        setSearchPost('');
        setSearchText('');
    }

    const clearSearchByCategory = ()=>{
        setSearchCategoryId('');
        setSearchCategory('');
    }

    const clearSearchByMonth = ()=>{
        setSearchMonth(undefined);
    }

    const clearSearchByTag = ()=>{
        setSearchTag({tagId: '', tag: ''});
    }

    const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    }

    const handleSearchPost = ()=>{
        resetCurrentPage();
        clearSearchByCategory();
        clearSearchByMonth();
        clearSearchByTag();
        setSearchPost(searchText);
    }

    const handleSearchCategoryId = (categoryId: string, category: string)=>{
        resetCurrentPage();
        clearSearchByText()
        clearSearchByMonth();
        clearSearchByTag();
        setSearchCategoryId(categoryId);
        setSearchCategory(category);
    }

    const handleSearchMonth = (month: number)=>{
        resetCurrentPage();
        clearSearchByCategory();
        clearSearchByText()
        clearSearchByTag();
        setSearchMonth(month);
    }

    const handleSearchTag = (tagInfo: SearchByTagProps)=>{
        resetCurrentPage();
        clearSearchByText()
        clearSearchByCategory();
        clearSearchByMonth();
        setSearchTag({
            tagId: tagInfo.tagId,
            tag: tagInfo.tag,
        });
    }
    
    return(
        <SearchPostContext.Provider 
        value={{
            searchText,
            searchPost,
            handleSearchText,
            handleSearchPost,
            searchCategoryId,
            handleSearchCategoryId,
            searchCategory,
            handleSearchMonth,
            searchMonth,
            searchTag,
            handleSearchTag,
            pageSize,
            currentPage,
            setCurrentPage,
            totalPages,
            setTotalPages,
            clearSearchByText,
            clearSearchByCategory,
            clearSearchByMonth,
            clearSearchByTag,
        }}>
            {children}
        </SearchPostContext.Provider>
    )
}

export default SearchPostProvider;