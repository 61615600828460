import styled from 'styled-components';
import { accentColor, blackColor, secondaryColor, whiteColor } from 'theme';
import { Breakpoints } from 'src/theme/breakpoints';
import { ButtonProps } from './Button.type';

const SButton = styled.button.attrs((props)=>({
  type: props.type || 'button',
}))<ButtonProps>`
  background-color: ${(props)=> props.variant === 'accent' ? secondaryColor : 'transparent'};
  border: 1px;
  border-style: solid;
  border-color: ${(props)=> 
    props.variant === 'primary' ? blackColor : 
    props.variant === 'primary-white' ? whiteColor : 'transparent'};
  color: ${(props)=>  props.variant === 'primary' ? blackColor : whiteColor};
  cursor: pointer;
  font-size: 18px;
  width: 272px;
  height: 47px;
  transition: all 0.4s;

  &:hover {
    border-color: ${accentColor};
    color: ${accentColor};
    background-color: transparent;
  }

  &:active {
    background-color: ${secondaryColor};
    color: ${whiteColor};
    border: none;
  }

  @media (max-width: ${Breakpoints.sm}){
    width: 250px;
  }
`;

export default SButton