import React, { useEffect, useRef } from 'react';
import { MapHolder } from './Map.components';

type MapComponentsProps = {
  center: google.maps.LatLngLiteral, 
  zoom: number, 
  children: React.ReactNode
};

const MapComponent: React.FC<MapComponentsProps> = ({center, zoom, children})=> {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();
  
    useEffect(() => {
        if(ref.current && !map){
            setMap(new window.google.maps.Map(ref.current, {center,zoom}));
        }
    }, [ref, map, center, zoom]);
  
    return(
        <>
            <MapHolder ref={ref} />
            {
                React.Children.map(children, (child) => {
                        if (React.isValidElement(child)) {
                        return React.cloneElement(child as React.ReactElement<any>, { map });
                    }
                })
            }
        </>
    )
  }

  export default MapComponent