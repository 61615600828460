import React from "react";
import {
  ChangingColumn,
  ChangingHolder,
  ChangingWrapper,
  VideoWrapper,
} from "./ChangingWorld.components";
import { Container } from "src/design/containers/Container";
import { SectionTitle, SSpan, SText } from "atoms/text/Text";
import { ColorRed, ColorWhite, Spacing } from "theme";
import { config } from "fixtures/config";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const ChangingWorld: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <ChangingWrapper style={isMobile ? {padding: `${Spacing.smallSpacing} 0px`} : undefined}>
      <Container>
        <ChangingHolder>
          <ChangingColumn>
            <SectionTitle color={ColorWhite}>
              {t("careers.changingWorld.title1")}
              <br />
              <SSpan color={ColorRed} variant="sectionTitle">
              {t("careers.changingWorld.title2")}
              </SSpan>
            </SectionTitle>
          </ChangingColumn>
          {isMobile ? (
            <VideoWrapper>
              <iframe
                width="100%"
                height="100%"
                src={config.urls.youtubeVideo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </VideoWrapper>
          ) : null}
          <ChangingColumn>
            <SText color={ColorWhite}>
              {t("careers.changingWorld.paragraph1")}
            </SText>
            <SText color={ColorWhite}>
              {t("careers.changingWorld.paragraph2")}
            </SText>
            {!isMobile ? (
            <VideoWrapper
              style={{
                marginTop: Spacing.xxxxLargeSpacing
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={config.urls.youtubeVideo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </VideoWrapper>
          ) : null}
          </ChangingColumn>
        </ChangingHolder>
      </Container>
    </ChangingWrapper>
  );
};

export default ChangingWorld;
