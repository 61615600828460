import React from 'react';
import { QuoteWrapper } from './Quote.components';
import { QuoteProps } from 'custom-types/design/atoms/Quote';
import {RichText} from 'prismic-reactjs';

const Quote: React.FC<QuoteProps> = ({text}) => {
  return (
    <QuoteWrapper>
        {RichText.render(text)}
    </QuoteWrapper>
  )
}

export default Quote