import { addDoc, collection } from 'firebase/firestore';
import { db, firebaseCollections } from 'fixtures/firebase';
import { isValidEmail } from 'fixtures/helpers';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const useNewsletter = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>)=> {
    setTermsAccepted(e.target.checked);
  }

  const handleEmail = (e: ChangeEvent<HTMLInputElement>)=> {
    setEmail(e.target.value);
  }

  const resetForm = ()=> {
    setEmail('');
    setTermsAccepted(false);
  }

  const validateForm = ()=> {
    if(!email){
      Swal.fire({
          text: t('home.newsletter.emailRequired'),
          icon: 'warning',
      });
      return;
    }
    if(!isValidEmail(email)){
      Swal.fire({
          text: t('home.newsletter.emailInvalid'),
          icon: 'warning',
      });
      return;
    }
    if(!termsAccepted){
      Swal.fire({
          text: t('home.newsletter.termsRequired'),
          icon: 'warning',
      });
      return;
    }
    saveEmail();
  }

  const saveEmail = async()=> {
    setLoading(true);
    const docRef = await addDoc(collection(db, firebaseCollections.newsletter), {
      email,
      createdAt: new Date(),
    });
    setLoading(false);
    if(docRef.id){
        Swal.fire({
            text: t('home.newsletter.emailRegisteredSucess'),
            icon: 'success',
        });
        resetForm();
    }else{
      Swal.fire({
        text: t('home.newsletter.emailNotRegistered'),
        icon: 'success',
    });
    }
  }

  return {
    termsAccepted,
    handleCheck,
    email,
    handleEmail,
    validateForm,
    loading
  }
}

export default useNewsletter