import { SText, SectionTitle } from 'atoms/text/Text';
import { imgParqueEolicoFuturo } from 'images';
import ImageWithContent from 'molecules/imageWidthContent/ImageWithContent';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorWhite, Spacing } from 'theme';
import { BuildingFutureWrapper } from './BuildingFuture.styled';
import { isMobileDevice } from 'utils/isMobileDevice';
import SButton from 'atoms/button/Button';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const BuildingFuture: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const isMobile = isMobileDevice();

  const handleNavigate = ()=> {
    navigate('/Nosotros');
  }

  return (
    <BuildingFutureWrapper>
      <ImageWithContent
        isSwitchMobile
        imageId='building-future'
        placeImage='left'
        image={imgParqueEolicoFuturo}
        title={
          <SectionTitle
            style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}
            color={ColorWhite}
            fontWeight='bold'
          >
            {t('home.building.title')}
          </SectionTitle>
        }
        content={
          <div style={isMobile ? {paddingBottom: Spacing.xLargeSpacing} : undefined}>
            <SText
              color={ColorWhite}
              style={{marginTop: Spacing.xLargeSpacing}}
            >
              {t('home.building.paragaph1')}
            </SText>
            <SText
            color={ColorWhite}
            style={{marginTop: Spacing.xLargeSpacing}}
          >
            {t('home.building.paragaph2')}
          </SText>
          <SButton
            title={'Sobre nosotros'} 
            onClick={handleNavigate}
            variant={'primary-white'}
          >
            {t('home.building.buttonText')}
          </SButton>
          </div>
        }
      />
    </BuildingFutureWrapper>
  )
}

export default BuildingFuture