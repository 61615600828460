import React, { useContext } from 'react';
import { ColorRed, Spacing } from 'theme';
import {XCircle} from 'phosphor-react';
import { SText } from 'atoms/text/Text';
import { FiltersType, SearchPostContext } from 'context/searchPostContext/SearchPostContext';

type ResultsTextProps = {
  text: string;
  filterType: FiltersType;
};

const ResultsText: React.FC<ResultsTextProps> = ({text, filterType}) => {
  const {
    clearSearchByCategory, 
    clearSearchByMonth, 
    clearSearchByText,
    clearSearchByTag,
  } = useContext(SearchPostContext);

  const handleClearFilter = ()=> {
    switch (filterType) {
      case FiltersType.BY_TEXT:
        clearSearchByText && clearSearchByText();
        break;
        
      case FiltersType.BY_CATEGORY:
        clearSearchByCategory && clearSearchByCategory();
        break;
      
      case FiltersType.BY_MONTH:
        clearSearchByMonth && clearSearchByMonth();
        break;


      case FiltersType.BY_TAG:
        clearSearchByTag && clearSearchByTag();
        break;
    
      default:
        break;
    }
  }

  return (
    <div style={{
        display: 'flex',
        justifyContent: 'cenetr',
      }}>
        <SText 
          color={ColorRed}
          style={{marginRight: Spacing.tinySpacing}}
        >
            {text}
        </SText>
        <XCircle         
          cursor='pointer'
          size={24} color={ColorRed} 
          onClick={handleClearFilter}
        />
    </div>
  )
}

export default ResultsText