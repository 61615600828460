import logo from './img/logo.png';
import instagramIcon from './img/instagram_icon.png';
import twitterIcon from './img/twitter_icon.png';
import youtubeIcon from './img/youtube_icon.png';
import farmPlaningImage from './img/farm_planing.png';
import energySolutionsImage from './img/energy_solutions.png';
import qualityStandarsImage from './img/quality_standars.png';
import technicalServiceImage from './img/technical_service.png';
import energiaInfraestructura from './img/energia-infrasetructura.jpg';
import palasEolicas from './img/palas-eolicas.jpg';
import parqueEolico from './img/parque-eolico.jpg';
import placeholderImgB from './img/placeholder_img.png';
import grupoDragon from './img/grupo_dragon.png';
import goldwind from './img/goldwind.png';
import senvion from './img/senvion.png';
import total from './img/total.png';
import ingenieros from './img/ingenieros.png';
import aeroGeneradorEolico from './img/aerogenerador-eolico.jpg';
import panelesSolares from './img/paneles-solares.png';
import sectorEolico from './img/sector-eolico.png';
import infraEstructura from './img/infraestructura.png';
import planetaVerde from './img/planeta-verda.png';
import trabajadoresSectorEolico from './img/trabajadores-sector-eolico.png';
import trabajadoresSectorEolicoCarreras from './img/imgTrabajadoresSectiorEolicoCarreras.png';
import molinoDeViento from './img/molino-de-viento.png';
import mantenimientoEolico from './img/mantenimiento-eolico.jpg';
import energiaSolar from './img/energia-solar.png';
import energiaRenovable from './img/energia-renovable.png';
import aerogeneradores from './img/aerogeneradores.jpg';
import aerogeneradorPaisaje from './img/aerogenerador-paisaje.png';
import panelSolar from './img/panel-solar.png';
import celdasSolares from './img/celdas-solares.png';
import planeacionInstalacion from './img/planeacion-instalacion.png';
import sistemaComunicaciones from './img/sistema-comunicaciones.png';
import sustentabilidad from './img/sustentabilidad.jpg';
import aerogeneradoresSustentabilidad from './img/aerogeneradores-sustentabilidad.png';
import ingenieroEolico from './img/ingeniero-eolico.png';
import aerogeneradorMontanias from './img/aerogenerador-montanias.jpg';
import aerogeneradoresPuestaSol from './img/aerogeneradorespuesta-de-sol.jpg';
import infraestructuraElectrica from './img/infrasectrucura-electrica.png';
import antenaComunicaciones from './img/antena-comunicaciones.png';
import energiasRenovables from './img/energias-renovables.png';
import estacionMeteorologica from './img/estacion-meteorologica.png';
import ingenierosPanelesSolares from './img/ingenieros-paneles-solares.png';
import planetaSustentable from './img/planeta-sustentable.png';
import mapa from './img/mapa.png';
import blogHeade from './img/img_blog_header.jpg';
import enercon from './img/enercon.png';
import iberdrola from './img/Iberdrola.png';
import vestas from './img/vestas.png';
import siemens from './img/siemens.png';
import acciona from './img/acciona.png';
import suzlon from './img/suzlon.png';
import auriga from './img/auriga.png';
import enel from './img/enel-green-power.png';
import aeromexico from './img/aeromexico.png';
import cfe from './img/cfe.png';
import dph from './img/dph.png';
import bbva from './img/bbva.png';
import perfilesProfesionales from './img/perfiles_profesionales.jpg';
import puestaSolPuente from './img/puesta_de_sol_puente.png';
import vacanteTecnicoMantenimiento from './img/vacante-tecnico-mantenimiento.png';
import vacanteDesarrolladorProyectos from './img/ vacante-desarrollador-proyectos.png';
import vacanteDesarrolladorSoftware from './img/vacante-desarrollador-software.png';
import vacanteIngenieroEnergias from './img/vacante-ingeniero-energias.png';
import vacanteGeografos from './img/vacante-geografos.png';
import brochure from './img/brochure.jpg';
import logoIso from './img/logo_iso.jpg';
import brochureHeader from './img/brochure-header.png';
import carrerasHeader from './img/carreras-header.png';
import contactoHeader from './img/contacto-header.png';
import nosotrosHeader from './img/nosotros-header.png';
import noticiasHeader from './img/noticias-header.png';
import perfilesHeader from './img/perfiles-header.png';
import serviciosHeader from './img/servicios-header.png';
import sustentabilidadHeader from './img/sustentabilidad-header.png';
import brochureSpanish from './img/brochure_spanish.png';
import brochureEnglish from './img/brochure_english.png';
import parqueEolicoFuturo from './img/parque_eolico_futuro.png';
import parqueEolicoFuturoV from './img/parque-eolico-futuro-vertical.png';
import iconRedWorld from './img/icon_red_world.png';
import iconRedCrossCheckPerson from './img/icon_red_cross_check_person.png';
import iconRedTRanslate from './img/icon_red_translate.png';
import iconRedHand from './img/icon_rend_hand.png';
import instalacionPanelSolar from './img/instalacion-paneles-solares.png';
import torresMeteorologicas from './img/torres-meteorologicas.png';
import btnWhats from './img/btn_whats.png';

export const enerconLogo = enercon;
export const iberdrolaLogo = iberdrola;
export const vestasLogo = vestas;
export const siemensLogo = siemens;
export const accionaLogo = acciona;
export const suzlonLogo = suzlon;
export const aurigaLogo = auriga;
export const enelLogo = enel;
export const aeromexicoLogo = aeromexico;
export const cfeLogo = cfe;
export const dphLogo = dph;
export const bbvaLogo = bbva;

export const logoReyco = logo;
export const instagramSocialIcon = instagramIcon;
export const twitterSocialIcon = twitterIcon;
export const youtubeSocialIcon = youtubeIcon;
export const farmPlaningImg = farmPlaningImage;
export const energySolutionsImg = energySolutionsImage;
export const qualityStandarsImg = qualityStandarsImage;
export const technicalServiceImg = technicalServiceImage;
export const placeHolderImgBlue = placeholderImgB;
export const grupoDragonLogo = grupoDragon;
export const goldwindLogo = goldwind;
export const senvionLogo = senvion;
export const totalLogo = total;
export const imgEnergiaInfraestructura = energiaInfraestructura;
export const imgPalasEolicas = palasEolicas;
export const imgParqueElolico = parqueEolico;
export const imgIngenieros = ingenieros;
export const imgAeroGeneradorEolico = aeroGeneradorEolico;
export const imgPanelesSolares = panelesSolares;
export const imgSectorEolico = sectorEolico;
export const imgInfraestuctura = infraEstructura;
export const imgPlanetaVerde = planetaVerde;
export const imgTrabajadoresSectorEolico = trabajadoresSectorEolico;
export const imgTrabajadoresSectorEolicoCarreras = trabajadoresSectorEolicoCarreras;
export const imgMolinoDeViento = molinoDeViento;
export const imgMantenimientoEolico = mantenimientoEolico;
export const imgEnergiaSolar = energiaSolar;
export const imgEnergiaRenovable = energiaRenovable;
export const imgAerogeneradores = aerogeneradores;
export const imgAerogeneradorPaisaje = aerogeneradorPaisaje;
export const imgPanelSolar = panelSolar;
export const imgCeldasSolares = celdasSolares;
export const imgPlaneacionInstalacion = planeacionInstalacion;
export const imgSistemaComunicaciones = sistemaComunicaciones;
export const imgSustentabilidad = sustentabilidad;
export const imgAerogeneradoresSustentabilidad = aerogeneradoresSustentabilidad;
export const imgIngenieroEolico = ingenieroEolico;
export const imgAerogeneradorMontanias = aerogeneradorMontanias;
export const imgAerogeneradoresPuestaSol = aerogeneradoresPuestaSol;
export const imgInfraestructuraElectrica = infraestructuraElectrica;
export const imgAntenaComunicaciones = antenaComunicaciones;
export const imgEnergiasRenovables = energiasRenovables;
export const imgEstacionMeteorologica = estacionMeteorologica;
export const imgIngenierosPanelesSolares = ingenierosPanelesSolares;
export const imgPlanetaSustentable = planetaSustentable;
export const imgMapa = mapa;
export const imgBlogHeader = blogHeade;
export const imgPerfilesProfesionales = perfilesProfesionales;
export const imgPuestaSolPuente = puestaSolPuente;
export const imgVacanteTecnicoMantenimiento = vacanteTecnicoMantenimiento;
export const imgVacanteDesarrolladorProyectos = vacanteDesarrolladorProyectos;
export const imgVacanteDesarrolladorSoftware = vacanteDesarrolladorSoftware;
export const imgVacanteIngenieroEnergias = vacanteIngenieroEnergias;
export const imgVacanteGeografos = vacanteGeografos;
export const imgBrochure = brochure;
export const imgLogoIso = logoIso;
export const imgBrochureHeader = brochureHeader;
export const imgCarrerasHeader = carrerasHeader;
export const imgContactoHeader = contactoHeader;
export const imgNosotrosHeader = nosotrosHeader;
export const imgNoticiasHeader = noticiasHeader;
export const imgPerfilesHeader = perfilesHeader;
export const imgServiciosHeader = serviciosHeader;
export const imgSustentabilidadHeader = sustentabilidadHeader;
export const imgBrochureSpanish = brochureSpanish;
export const imgBrochureEnglish = brochureEnglish;
export const imgParqueEolicoFuturo = parqueEolicoFuturo;
export const imgParqueEolicoFuturoV = parqueEolicoFuturoV;
export const imgIconRedWorld = iconRedWorld;
export const imgIconRedCrossCheckPerson = iconRedCrossCheckPerson;
export const imgIconRedTranslate = iconRedTRanslate;
export const imgIconRedHand = iconRedHand;
export const imgInstalacionPanelSolar = instalacionPanelSolar;
export const imgTorresMeteorologicas = torresMeteorologicas;
export const imgBtnWhats = btnWhats;

export {default as logoBlue} from './img/logo_blue.png'
export {default as imgServiciosParquesEolicos} from "./img/servicios_parques_eolicos.webp";
export {default as imgParquesEolicosMantto} from "./img/parques-eolicos-mantto.webp";
export {default as imgParquesEolicosManttoMobile} from "./img/parques-eolicos-mantto-mobile.png";
export {default as imgParquesEolicosInstalacionAerogeneradres} from "./img/parques-instalacion-aerogeneradores.webp";
export {default as imgParquesEolicosSuministros} from "./img/parques-eolicos-suministros.webp";
export {default as imgParquesEolicosSupervision} from "./img/parques-eolicos-supervision.webp";
export {default as imgParquesEolicosResiduos} from "./img/parques-eolicos-residuos.webp";
export {default as imgServiciosParquesSolares} from "./img/servicios-parques-solares.webp";
export {default as imgServiciosParquesSolaresMantto} from "./img/servicios-parques-solares-mantto.webp";
export {default as imgServiciosParquesSolaresInstalacion} from "./img/servicios-parques-solares-instalacion.webp";
export {default as imgServiciosParquesSolaresSuministros} from "./img/servicios-parques-solares-suministros.webp";
export {default as imgServiciosParquesSolaresSupervision} from "./img/servicios-parques-solares-supervision.webp";
export {default as imgServiciosInfraElectrica} from "./img/servicios-infra-electrica.webp";
export {default as imgServiciosInfraElectricaBodega} from "./img/servicios-infra-electrica-bodega.webp";
export {default as imgServiciosInfraElectricaCogenerador} from "./img/servicios-infra-electrica-cogenerador.webp";
export {default as imgServiciosTorresMeteoroInstalacion} from "./img/servicios-torres-meteoro-instalacion.webp";
export {default as imgServiciosTorresMeteoroMonitor} from "./img/servicios-torres-meteoro-monitor.webp";
export {default as imgServiciosTorresMeteoroEstacion} from "./img/servicios-torres-meteoro-estacion.webp";
export {default as youtubeIconBlack} from "./img/youtube_icon_black.png";
export {default as twitterIconBlack} from "./img/twitter_icon_black.png";
export {default as instagramIconBlack} from "./img/instagram_icon_black.png";
export {default as linkedinIconBlack} from "./img/linkedin_icon_black.png";
export {default as projectsImgHeader} from './img/projects-img-header.png';
export {default as IsoLogoB} from './img/iso-logo-b.png';
export {default as iso14001Img} from './img/iso-14001-img.png';
export {default as iso9001Img} from './img/iso-9001-img.png';
export {default as iso45001Img} from './img/iso-45001-img.png';
export {default as iso37001Img} from './img/iso-37001-img.png';
export {default as pdf37001Img} from './img/pdf-37001-img.png';
export {default as pdfIntegralImg} from './img/pdf-integral-img.png';
export {default as careersHeaderImg} from './img/careers-header-image.png';
export {default as careersHeaderMobileImg} from './img/careers-header-mobile-image.png';
export {default as careersHygieneAndSafetyImg} from './img/Especialista-en-higiene-y-seguridad.png';
export {default as contactHeaderImg} from './img/contact-img-header.png';
export {default as projectsExperienceImg} from './img/parque-eolico-nocturno.png';
export {default as electricInfraHeaderImg} from './img/infraestructura-electrica-header.png';
export {default as electricInfraHeaderMobileImg} from './img/infraestructura-electrica-header-mobile.png';
export {default as meteorologicalTowersHeaderImg} from './img/meteorolic-towers-header.png';
export {default as panelesSolaresSumnistrosImg} from './img/paneles-solares-suministros.png';
export {default as aeroGeneradorHomeMobileImg} from './img/aerogenerador-home-mobile.png';
export {default as imgBlogHeaderMobile} from './img/img-blog-header-mobile.png';
export {default as imgContactHeaderMobile} from './img/img-contact-header-mobile.png';
export {default as imgBrochureSpanishMobile} from './img/image-brochure-spanish-mobile.png';



