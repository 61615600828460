import { config } from "./config";
import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const app = initializeApp(config.firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const firebaseCollections = {
    contactForm: "contactForm",
    newsletter: "newsletter"
};