import React, { useRef, useEffect } from 'react';
import { Caption, SectionTitle, SText } from 'atoms/text/Text';
import { imgIngenierosPanelesSolares } from 'images';
import { Container, Content } from 'src/design/containers/Container';
import { ColorWhite, Spacing } from 'theme';
import { ValueAddedwrapper, ValueAddedContentWrapper, ValueAddedImgWrapper, ValueAddedImg, ValueAddedHolder, ValueAddedContentHolder } from './ValueAdded.components';
import { gsap } from 'gsap';
import { isMobileDevice } from 'utils/isMobileDevice';
import { useTranslation } from 'react-i18next';

const ValueAdded: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = isMobileDevice();
  const imgRef = useRef(null);

  useEffect(()=>{
    gsap.fromTo(imgRef.current, {right: '-100%', opacity: 0}, {right: 0, opacity: 1, scrollTrigger: {trigger: '.value-added-img-wrapper', start: 'top center'}});
    // gsap.to(imgRef.current, {transform: 'scale(1)', duration: 1, scrollTrigger: {
    //   trigger: ".value-added-img-wrapper",
    //   start: 'top top+=100px',
    // }});
  },[]);


  return (
    <ValueAddedwrapper style={isMobile ? {paddingTop: Spacing.largeSpacing} : undefined}>
      <Container backgroundColor='transparent'>
        <ValueAddedHolder>
          <ValueAddedContentWrapper>
            <ValueAddedContentHolder>
              <Content>
                <Caption>
                  {t('home.valueAdded.caption')}
                </Caption>
                <SectionTitle
                  color={isMobile ? ColorWhite : undefined}
                  fontWeight='bold'
                  style={{marginBottom: Spacing.largeSpacing}}
                >
                  {t('home.valueAdded.title')}
                </SectionTitle>
              </Content>
                {isMobile ? (
                  <ValueAddedImgWrapper style={{marginBottom: Spacing.smallSpacing}}>
                    <ValueAddedImg ref={imgRef} src={imgIngenierosPanelesSolares} />
                  </ValueAddedImgWrapper>
                ) : null}
              <Content>
                <SText
                  color={isMobile ? ColorWhite : undefined}
                  style={{
                    marginBottom: Spacing.xxLargeSpacing
                  }}
                >
                  {t('home.valueAdded.paragraph1')}
                </SText>
                <SText
                  color={isMobile ? ColorWhite : undefined}
                  style={{
                    marginBottom: Spacing.xxLargeSpacing
                  }}
                >
                  {t('home.valueAdded.paragrpah2')}
                </SText>
                <SText color={isMobile ? ColorWhite : undefined}>
                  {t('home.valueAdded.paragraph3')}
                </SText>
              </Content>
            </ValueAddedContentHolder>
          </ValueAddedContentWrapper>
          {!isMobile ? (
            <ValueAddedImgWrapper>
              <ValueAddedImg ref={imgRef} src={imgIngenierosPanelesSolares} />
            </ValueAddedImgWrapper>
            ) : null}
        </ValueAddedHolder>
      </Container>
    </ValueAddedwrapper>
  )
}

export default ValueAdded