import React from 'react';
import Footer from 'organisms/footer/Footer';
import MainMenu from 'organisms/mainMenu/MainMenu';
import { Routes, Route, Navigate} from "react-router-dom";
import About from 'screens/about/About';
import Home from 'screens/home/Home';
import Certifications from 'screens/certifications/Certifications';
import Careers from 'screens/careers/Careers';
import Contact from 'screens/contact/Contact';
import Blog from 'screens/blog/Blog';
import Post from 'screens/post/Post';
import ProfesionalProfiles from 'screens/profesionalProfiles/ProfesionalProfiles';
import Brochure from './screens/brochure/Brochure';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import gsap from 'gsap';
import WhatsAppButton from 'atoms/whatsAppButon/WhatsAppButton';
import ParquesEolicos from 'screens/parquesEolicos/ParquesEolicos';
import ParquesSolares from 'screens/parquesSolares/ParquesSolares';
import InfraestructuraElectrica from 'screens/infraestructuraElectrica/InfraestructuraElectrica';
import TorresMeteorologicas from 'screens/torresMeteorologicas/TorresMeteorologicas';
import ProjectsScreen from 'screens/pojectsScreen/ProjectsScreen';
import { PATH_NAMES } from 'organisms/mainMenu/MainMenu.types';
import PageView from 'utils/PageView';


gsap.registerPlugin(ScrollTrigger);

const App: React.FC = () => {
  return (
    <>
      <PageView />
      <MainMenu />
      <Routes>
        <Route path={PATH_NAMES.ROOT} element={<Home />} />
        <Route path={PATH_NAMES.ABOUT_US} element={<About />} />
        <Route path={PATH_NAMES.PROJECTS} element={<ProjectsScreen />} />
        <Route path={PATH_NAMES.CERTIFICATIONS} element={<Certifications />} />
        <Route path={PATH_NAMES.WIND_FARMS} element={<ParquesEolicos />} />
        <Route path={PATH_NAMES.SOLAR_PARKS} element={<ParquesSolares />} />
        <Route path={PATH_NAMES.ELECTRICAL_INFRASTRUCTURE} element={<InfraestructuraElectrica />} />
        <Route path={PATH_NAMES.METEOROLOGICAL_TOWERS} element={<TorresMeteorologicas />} />
        <Route path={PATH_NAMES.CAREERS} element={<Careers />} />
        <Route path={PATH_NAMES.PROFESSIONAL_PROFILES} element={<ProfesionalProfiles />} />
        <Route path={PATH_NAMES.BLOG} element={<Blog />} />
        <Route path={`${PATH_NAMES.BLOG}/:uidPost`} element={<Post />} />
        <Route path={PATH_NAMES.CONTACT} element={<Contact />} />
        <Route path={PATH_NAMES.BROCHURE} element={<Brochure />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Footer />
      {/* <WhatsAppButton /> */}
    </>
  )
}

export default App