import React from "react";
import {
  CopyRightHolder,
  CopyRightWrapper,
  FooterHolder,
  FooterLink,
  FooterLinksLi,
  FooterLinksUl,
  FooterWrapper,
  LegalLinkItem,
  LegalLinksUl,
  LegalLinksWrapper,
  NavLogoWrapper,
  OfficesWrapper,
  SocialWrapper,
} from "./Footer.styled";
import { Container, Content } from "src/design/containers/Container";
import Logo from "atoms/logo/Logo";
import SocialIcon from "atoms/socialIcon/SocialIcon";
import {
  imgLogoIso,
  IsoLogoB,
} from "images";
import { config } from "fixtures/config";
import { TextOSR } from "atoms/text/Text";
import { getYear } from "fixtures/dates";
import { grayc9c9c9 } from "theme";
import Offices from "organisms/Offices/Offices";
import { LogoIso } from "organisms/isoSlider/IsoSlider.components";
import { LABELS_MAIN_MENU, PATH_NAMES } from 'organisms/mainMenu/MainMenu.types';
import { InstagramLogo, LinkedinLogo, TwitterLogo, YoutubeLogo } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useFooterController } from "./Footer.controller";

const Footer: React.FC = () => {
  const {t} = useTranslation();
  const {legalLinks} = useFooterController();

  const hanldeSocialClick = (icon: string) => {
    switch (icon) {
      case "instagram":
        window.open(config.urls.instagram, "_blank");
        break;

      case "twitter":
        window.open(config.urls.twitter, "_blank");
        break;

      case "linkedin":
        window.open(config.urls.linkedin, "_blank");
        break;

      case "youtube":
        window.open(config.urls.youtube, "_blank");
        break;

      default:
        break;
    }
  };

  return (
    <FooterWrapper>
      <div style={{ padding: "64px 0px" }}>
        <Container>
          <FooterHolder>
            <OfficesWrapper>
              <Offices isShowContactInfo={false} isAnimated={false} gap="60px" justifyContent="start" />
            </OfficesWrapper>
            <NavLogoWrapper>
              <FooterLinksUl>
                <FooterLinksLi>
                  <FooterLink to="/">
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.HOME}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.ABOUT_US}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.ABOUT_US}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.CERTIFICATIONS}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.CERTIFICATIONS}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.PROJECTS}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.PROJECTS}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.WIND_FARMS}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.WIND_FARMS}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.SOLAR_PARKS}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.SOLAR_PARKS}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.ELECTRICAL_INFRASTRUCTURE}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.ELECTRICAL_INFRASTRUCTURE}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.METEOROLOGICAL_TOWERS}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.METEOROLOGICAL_TOWERS}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.CAREERS}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.CAREERS}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.CONTACT}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.CONTACT}`)}
                  </FooterLink>
                </FooterLinksLi>
                <FooterLinksLi>
                  <FooterLink to={PATH_NAMES.BLOG}>
                    {t(`mainMenu.labels.${LABELS_MAIN_MENU.BLOG}`)}
                  </FooterLink>
                </FooterLinksLi>
              </FooterLinksUl>
              <Content>
                <Logo
                  style={{
                    display: "block",
                    marginBottom: "48px",
                  }}
                  type="blue"
                />
                <LogoIso style={{ marginRight: "12px" }} src={imgLogoIso} />
                <LogoIso src={IsoLogoB} />
              </Content>
            </NavLogoWrapper>
          </FooterHolder>
        </Container>
      </div>
      <div style={{ height: "1px", background: `${grayc9c9c9}` }} />
      <Container>
        <Content>
          <CopyRightWrapper>
            <CopyRightHolder>
              <TextOSR>
                {`© ${getYear()} ${t("footer.copyRight")}`}
              </TextOSR>
            </CopyRightHolder>
            <LegalLinksWrapper>
              <LegalLinksUl>
                {legalLinks.map(legalLink => (
                  <LegalLinkItem key={legalLink.label}>
                    <a href={legalLink.link} target="_blank" rel="noreferrer">
                      <TextOSR
                        key={legalLink.label}
                        fontSize="12px"
                      >
                        {legalLink.label}
                      </TextOSR>
                    </a>
                  </LegalLinkItem>
                ))}
              </LegalLinksUl>
            </LegalLinksWrapper>
            <SocialWrapper>
              <SocialIcon
                icon={<InstagramLogo size={24} />}
                alt="Instagram Reyco Renovables"
                onClick={() => {
                  hanldeSocialClick("instagram");
                }}
              />
              <SocialIcon
                icon={<TwitterLogo size={24} weight="fill" />}
                alt="Twitter Reyco Renovables"
                onClick={() => {
                  hanldeSocialClick("twitter");
                }}
              />
              <SocialIcon
                icon={<LinkedinLogo size={24} />}
                alt="Linkedin Reyco Renovables"
                onClick={() => {
                  hanldeSocialClick("linkedin");
                }}
              />
              <SocialIcon
                icon={<YoutubeLogo size={24} weight="fill" />}
                alt="YouTube Reyco Renovables"
                onClick={() => {
                  hanldeSocialClick("youtube");
                }}
              />
            </SocialWrapper>
          </CopyRightWrapper>
        </Content>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
