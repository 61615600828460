import * as React from "react";
import { SVGProps } from "react";
const SvgTrascabo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="trascabo_svg__Capa_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 176.18 176.27"
    {...props}
  >
    <defs>
      <style>{".trascabo_svg__cls-1{fill:#d82620}"}</style>
    </defs>
    <g id="trascabo_svg__Capa_1-2">
      <path
        className="trascabo_svg__cls-1"
        d="M103.5 108.59h8.66c8.62.03 14.18 5.6 14.17 14.2v3.6c3.39.2 6.59.33 9.79.6 10.82.92 20.04 9.68 21.65 20.52 1.93 12.97-6.13 25.02-18.85 27.98-2.32.54-4.77.74-7.16.74-35.16.05-70.32.05-105.47.02-12.99 0-22.62-7.24-25.57-19.09-3.37-13.51 5.53-27.52 19.23-29.95 2.31-.41 2.76-1.24 2.76-3.33-.05-30.09.09-60.18-.13-90.27-.05-6.62 4.07-11.19 10.99-11.08 15.62.24 31.25.06 46.88.08 6.91.01 10.06 2.94 11.08 9.8 1.44 9.68 3.02 19.35 4.56 29.02.06.39.26.76.52 1.51.89-.84 1.59-1.47 2.26-2.14 19.52-19.51 39.03-39.02 58.54-58.54 3.05-3.05 3.43-3.04 6.56.08 3.53 3.53 7.14 6.97 10.53 10.63.91.98 1.62 2.59 1.63 3.91.09 27.84.07 55.69.06 83.53 0 2.79-.71 3.49-3.59 3.5-12.72.02-25.43 0-38.15.01-1.62 0-3.25-.29-3.27-2.21-.02-1.98 1.69-2.41 3.25-2.11 5.1.98 7.67-1.32 9.59-5.94 2.64-6.34 6.1-12.34 9.07-18.55.97-2.03 2.29-2.79 4.47-2.73 3.82.1 7.65.04 11.47.02.9 0 1.79-.09 2.79-.15V22.78l-.46-.31c-22.77 28.43-45.55 56.85-68.32 85.28l.49.84Zm-24.48 63.37h14.71c13.13 0 26.27.11 39.4-.03 14.19-.16 23.9-13.37 19.76-26.62-2.76-8.84-10.58-14.39-20.5-14.4-24.35-.03-48.71-.01-73.07-.01-11.39 0-22.78-.08-34.16.03-11.77.11-20.86 8.99-20.95 20.27-.1 11.72 9.03 20.72 21.19 20.76 17.87.06 35.74.01 53.61.02Zm20.01-63.49c-.37-2.57-.68-4.93-1.05-7.29-3.62-22.94-7.27-45.88-10.88-68.82-.62-3.92-2.33-5.6-6.27-5.61-15.95-.04-31.91-.04-47.86 0-4.25.01-6.18 2.06-6.19 6.36-.01 24.26.02 48.53-.06 72.79 0 2.27.73 2.74 2.86 2.73 22.27-.07 44.54-.04 66.81-.05.81 0 1.62-.07 2.64-.12Zm3.26-7.52.66.17c22.74-28.46 45.48-56.91 67.91-84.98-3.77-3.73-7.22-7.15-11.1-10.99-.4.55-.89 1.46-1.58 2.15-19.26 19.3-38.55 38.58-57.83 57.85-.64.64-1.24 1.46-2.03 1.8-1.35.59-1.25 1.42-1.05 2.6.97 5.81 1.83 11.64 2.76 17.46.74 4.65 1.51 9.29 2.27 13.93Zm19.58 25.51c1.56-9.24-2.65-13.81-11.44-13.71-26.74.29-53.49.11-80.24.11h-3.23v13.61h94.9Zm49.87-27.13V76.52c-4.24 0-8.29.09-12.34-.04-1.7-.05-2.48.6-3.19 2.06-3.01 6.19-6.16 12.31-9.25 18.46-.34.68-.58 1.42-.95 2.33h25.73Z"
      />
      <path
        className="trascabo_svg__cls-1"
        d="M122.03 149.4c1.57 0 3.19-.23 4.69.1.77.17 1.7 1.27 1.83 2.07.09.52-1.09 1.77-1.76 1.82-3.13.2-6.29.22-9.41 0-.68-.05-1.81-1.36-1.77-2.03.04-.7 1.16-1.72 1.97-1.9 1.42-.31 2.96-.09 4.45-.09v.03ZM31.49 153.47c-1.57 0-3.2.23-4.7-.1-.77-.17-1.7-1.29-1.82-2.1-.08-.52 1.14-1.75 1.83-1.79 3.14-.2 6.3-.21 9.43.02.68.05 1.64 1.31 1.76 2.11.07.53-1.06 1.65-1.79 1.78-1.52.28-3.13.09-4.7.09ZM76.64 153.51c-1.49 0-3.03.23-4.45-.09-.79-.18-1.9-1.25-1.91-1.94-.01-.68 1.13-1.94 1.83-1.99 3.05-.22 6.13-.23 9.18 0 .75.05 1.41 1.26 2.11 1.93-.68.69-1.27 1.82-2.06 1.98-1.5.32-3.13.09-4.7.09v.02ZM36.22 60.15c0-5.73-.01-11.46 0-17.2.01-4.42 2.27-6.73 6.73-6.75 9.22-.04 18.44-.04 27.66 0 3.94.02 5.87 1.77 6.48 5.72 2.08 13.52 4.15 27.05 6.22 40.58.34 2.25-.65 3.2-3.28 2.96-4.79-.44-9.57-.98-14.36-1.49-8.74-.94-17.48-1.98-26.24-2.79-2.62-.24-3.32-1.39-3.26-3.84.13-5.73.04-11.46.04-17.19Zm4.32 16.91c12.74 1.35 25.31 2.68 38.13 4.03-.08-1.09-.09-1.82-.2-2.54-1.75-11.57-3.52-23.13-5.26-34.7-.48-3.21-.73-3.52-3.87-3.53-8.39-.02-16.79-.02-25.18 0-3.4 0-3.74.34-3.75 3.69-.02 10.14 0 20.28 0 30.42 0 .82.08 1.63.13 2.63ZM47.3 95.09c2.16 0 4.34-.19 6.47.09.86.11 1.59 1.24 2.38 1.91-.71.76-1.38 2.14-2.12 2.18-4.47.2-8.97.2-13.44 0-.77-.04-1.48-1.41-2.22-2.17.82-.67 1.57-1.79 2.46-1.9 2.12-.28 4.31-.09 6.47-.09Z"
      />
    </g>
  </svg>
);
export default SvgTrascabo;
