import React from 'react';
import 'react-awesome-slider/dist/styles.css';
import { Slider, TitleSlider } from './HomeSlider.components';
import { imgEnergiaInfraestructura, imgPalasEolicas, imgParqueElolico } from 'images';



const HomeSlider: React.FC = () => {
  
  return (
    <Slider 
      play={true} 
      className='home-slider' 
    >
      <div data-src={imgEnergiaInfraestructura}>
        <TitleSlider>
          <span className='stroke-text'>Working to</span><br /> Create energy
        </TitleSlider>
      </div>
      <div data-src={imgParqueElolico}>
        <TitleSlider>
        <span className='stroke-text'>Building</span><br /> The future
        </TitleSlider>
      </div>
      <div data-src={imgPalasEolicas}>
        <TitleSlider>
        <span className='stroke-text'>Working to</span><br /> Create energy
        </TitleSlider>
      </div>
    </Slider>
  )
}

export default HomeSlider