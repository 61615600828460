import React, {useContext} from 'react';
import { TagItemProps } from 'custom-types/design/atoms/TagItem';
import { Tag, TagWrapper } from './TagItem.components';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import { useNavigate } from 'react-router-dom';

const TagItem: React.FC<TagItemProps> = ({tagId, tag}) => {
  const navigate = useNavigate();
  const {handleSearchTag} = useContext(SearchPostContext);

  const handleTagSelected = ()=> {
    handleSearchTag && handleSearchTag({tagId, tag});
    navigate('/Blog');
  }

  return (
    <TagWrapper>
      <Tag
        onClick={handleTagSelected}
      >
        {tag}
      </Tag>
    </TagWrapper>
  )
}

export default TagItem