import React from 'react';
import SearchPostForm from 'molecules/searchPostForm/SearchPostForm';
import BlogSideBarList from 'organisms/blogSideBarList/BlogSideBarList';
import PostsByCategoryItemList from 'organisms/postsByCategoryItemLists/PostsByCategoryItemList';
import LatestPostList from 'organisms/latestPostList/LatestPostList';
import PostArchivedList from 'organisms/postArchivedList/PostArchivedList';
import TagListSideBar from 'organisms/tagListSideBar/TagListSideBar';
import { isMobileDevice } from 'utils/isMobileDevice';
import { useTranslation } from 'react-i18next';

const BlogSidebar: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <>
      {isMobile ? null : (<SearchPostForm />)}
      {isMobile ? null : (
        <BlogSideBarList 
          title={t('blog.sidebar.allPosts')}
          list={<PostsByCategoryItemList />}
        />
      )}
      <BlogSideBarList 
        title={t('blog.sidebar.latestPosts')}
        list={<LatestPostList />}
      />
      {isMobile ? null : (
        <BlogSideBarList 
          title={t('blog.sidebar.archive')}
          list={<PostArchivedList />}
        />
      )}
      {isMobile ? null : (
        <BlogSideBarList 
          title={t('blog.sidebar.tags')}
          list={<TagListSideBar />}
        />
      )}
    </>
  )
}

export default BlogSidebar