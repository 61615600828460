import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { backgroundDarkColor, Spacing } from "theme";

export const ContactFormWrapper = styled.section.attrs(()=>({
    className: 'contact-form-wrapper'
}))`
    position: relative;
    padding-top: 24px;

    @media (max-width: ${Breakpoints.lg}){
        height: auto;
    }
`;

export const ContactFormHolder = styled.div.attrs(()=>({
    className: 'contact-form-holder'
}))`
    
    margin: 0 auto;
    z-index: 3;

    @media (max-width: ${Breakpoints.lg}){
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        width: 90%;

        input, textarea {
            width: 100%;
        }
    }

    @media (max-width: ${Breakpoints.md}){
        position: relative;
        left: auto;
        top: auto;
        transform: none;
        width: 100%;
        padding: ${Spacing.baseSpacing};

        input, textarea {
            width: 100%;
        }
    }
`;

export const FieldsWrapper = styled.div.attrs(()=>({
    className: 'fields-wrapper'
}))`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${Spacing.largeSpacing};

    @media (max-width: ${Breakpoints.md}){
        flex-direction: column;
        gap: 0;
    }
`;