import React, { useState } from "react";
import { SectionTitle, SSpan, SText } from "atoms/text/Text";
import ImageContent from "molecules/ImageContent/ImageContent";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import { backgroundLightColor, blue202B34, secondaryColor, Spacing, whiteColor } from "theme";
import {
  imgEnergiaRenovable,
  imgLogoIso,
  imgSustentabilidad,
  iso14001Img,
  iso37001Img,
  iso45001Img,
  iso9001Img,
  IsoLogoB,
  pdf37001Img,
  pdfIntegralImg,
} from "images";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { LogoIso } from "organisms/isoSlider/IsoSlider.components";
import { CertificationDocumentsHolder, CertificationDocumentsWrapper} from "./Certifications.components";
import PdfModalViewer from "organisms/pdfModalViewer/PdfModalViewer";
import { useTranslation } from "react-i18next";

export enum PDF_FILE {
  ISO = 'iso',
  INTEGRAL = 'integral',
};

const Certifications: React.FC = () => {
  const {t} = useTranslation();
  const [pdfFileSelected, setPdfFileSected] = useState<PDF_FILE>(PDF_FILE.INTEGRAL);
  const [isShowPdf, setIsShowPdf] = useState<boolean>(false);

  return (
    <>
      <ScreenHeader
        image={imgSustentabilidad}
        title={t('certifications.header.title')}
        subtitle={t('certifications.header.subtitle')}
        // imgTitle={imgSustentabilidadHeader}
        sectionName={SectionName.SUSTENTABILIDAD}
      />
      <ImageContent
        isSwitchMobile
        imageId="imgEnergiaRenovable"
        isFlexReverse
        imagePlace="right"
        isShowDecorationLine={false}
        image={imgEnergiaRenovable}
        title={
          <SectionTitle>
            {t('certifications.weAreReyco.title1')}
            <br />
            <SSpan color={secondaryColor} variant="sectionTitle">
              {t('certifications.weAreReyco.title2')}
            </SSpan>
          </SectionTitle>
        }
        content={
          <>
            <SText>
              {t('certifications.weAreReyco.paragraph1')}
            </SText>
            <SText>
              {t('certifications.weAreReyco.paragraph2')}
            </SText>
            <SText>
              {t('certifications.weAreReyco.paragraph3')}
            </SText>
            <SText>
              {t('certifications.weAreReyco.paragraph4')}
            </SText>
            <CertificationDocumentsWrapper>
              <CertificationDocumentsHolder>
                <img
                  onClick={() => {
                    setPdfFileSected(PDF_FILE.INTEGRAL);
                    setIsShowPdf(true);
                  }} 
                  src={pdfIntegralImg}
                  alt="pdf document" 
                />
                <img
                  onClick={() => {
                    setPdfFileSected(PDF_FILE.ISO);
                    setIsShowPdf(true);
                  }}
                  src={pdf37001Img} 
                  alt="pdf document" 
                />
              </CertificationDocumentsHolder>
              <div>
                <LogoIso style={{marginRight: '12px'}} src={imgLogoIso} />
                <LogoIso src={IsoLogoB} />
              </div>
            </CertificationDocumentsWrapper>
          </>
        }
      />
      <ImageContent
        isSwitchMobile
        imageId="iso14001Img"
        backgroundColorSection={backgroundLightColor}
        imagePlace="left"
        image={iso14001Img}
        title={
          <SectionTitle>
            {t('certifications.sustainability.title1')}
            <br />
            <SSpan color={secondaryColor} variant="sectionTitle">
              {t('certifications.sustainability.title2')}
            </SSpan>
          </SectionTitle>
        }
        content={
          <>
            <SText
              style={{
                marginBottom: Spacing.xLargeSpacing,
              }}
            >
              {t('certifications.sustainability.paragraph1')}
            </SText>
          </>
        }
      />
      <ImageContent
        isSwitchMobile
        imageId="iso9001Img"
        isFlexReverse
        backgroundColorSection={blue202B34}
        imagePlace="right"
        image={iso9001Img}
        title={
          <SectionTitle color={whiteColor}>
            {t('certifications.commitment.title1')} <br />
            {t('certifications.commitment.title2')}
          </SectionTitle>
        }
        content={
          <>
            <SText
              style={{
                marginBottom: Spacing.xLargeSpacing,
              }}
              color={whiteColor}
            >
              {t('certifications.commitment.paragraph1')}
            </SText>
          </>
        }
      />
      <ImageContent
        isSwitchMobile
        imageId="iso45001Img"
        imagePlace="left"
        image={iso45001Img}
        title={
          <SectionTitle>
            {t('certifications.security.title1')}
            <br />
            <SSpan color={secondaryColor} variant="sectionTitle">
            {t('certifications.security.title2')}
            </SSpan>
          </SectionTitle>
        }
        content={
          <>
            <SText
              style={{
                marginBottom: Spacing.xLargeSpacing,
              }}
            >
              {t('certifications.security.paragraph1')}
            </SText>
          </>
        }
      />
      <ImageContent
        isSwitchMobile
        imageId="iso37001Img"
        isFlexReverse
        backgroundColorSection={blue202B34}
        imagePlace="right"
        image={iso37001Img}
        title={
          <SectionTitle color={whiteColor}>
            {t('certifications.ethics.title1')} <br />
            {t('certifications.ethics.title2')}
          </SectionTitle>
        }
        content={
          <>
            <SText
              style={{
                marginBottom: Spacing.xLargeSpacing,
              }}
              color={whiteColor}
            >
              {t('certifications.ethics.paragraph1')}
            </SText>
          </>
        }
      />
      {isShowPdf ? (
        <PdfModalViewer
          pdfFile={pdfFileSelected}
          setIsShowPdf={setIsShowPdf}
        />
        ) : undefined
      }
    </>
  );
};

export default Certifications;
