import styled from "styled-components";
import { Spacing } from "theme";

export const List = styled.ul`
  margin-bottom: ${Spacing.xLargeSpacing};
  list-style-position: inside;

  li {
    margin-bottom: 8px;
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
`;