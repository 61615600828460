import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";

export const MapWrapper = styled.div.attrs((props)=>({
    className: 'map-wrapper'
}))``;

export const MapHolder = styled.div.attrs((props)=>({
    className: 'map-holder'
}))`
    height: 640px;

    @media (max-width: ${Breakpoints.lg}){
        height: 420px;
    }
`;