import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { List } from "./ParquesEolicos.components";
import { useTranslation } from "react-i18next";

const Residuos: React.FC = () => {
  const {t} = useTranslation();
  return (
    <>
      <SectionTitle>{t('windFarms.waste.title')}</SectionTitle>
      <SText>
        {t('windFarms.waste.paragraph1')}
      </SText>
      <List>
        <li>
          {t('windFarms.waste.list1.item1')}
        </li>
        <li>
          {t('windFarms.waste.list1.item2')}
        </li>
        <li>
          {t('windFarms.waste.list1.item3')}
        </li>
      </List>
    </>
  );
};

export default Residuos;
