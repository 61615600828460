import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { ColorLightGrayB, Spacing } from "theme";

export const BlogWrapper = styled.section.attrs((props)=>({
    className: 'blog-wrapper'
}))`
    background: ${ColorLightGrayB};
`;

export const BlogHolder = styled.div.attrs((props)=>({
    className: 'blog-holder'
}))`
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 100px 0;
    gap: ${Spacing.xxLargeSpacing};

    @media (max-width: ${Breakpoints.lg}){
        grid-template-columns: 1fr;
    }
`;

export const BlogColumnWrapper = styled.div.attrs((props)=>({
    className: 'blog-column-wrapper'
}))``;