import { SText } from 'atoms/text/Text';
import { PostArchivedItemProps } from 'custom-types/design/molecules/PostArchivedItem';
import React, { useContext } from 'react';
import { PostArchivedItemWrapper, PostArchivedItemHolder, DateItemWrapper, CountPostsWrapper } from './PostArchivedItem.components';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import { useNavigate } from 'react-router-dom';
import { getMonthName } from 'fixtures/dates';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';


const PostArchivedItem: React.FC<PostArchivedItemProps> = ({month, postsLenght}) => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const postedMonth = month+1;
  const navigate = useNavigate();
  const {handleSearchMonth} = useContext(SearchPostContext);

  const handleSelectedMonth = ()=> {
    handleSearchMonth && handleSearchMonth(postedMonth);
    navigate('/Blog');
  }


  return (
    <PostArchivedItemWrapper>
        <PostArchivedItemHolder>
            <DateItemWrapper
              onClick={handleSelectedMonth}
            >
                <SText>
                  {getMonthName(postedMonth, languageCode)}
                </SText>
            </DateItemWrapper>
            <CountPostsWrapper>
              <SText
                fontWeight='bold'
              >
                {`(${postsLenght})`}
              </SText>
            </CountPostsWrapper>
        </PostArchivedItemHolder>
    </PostArchivedItemWrapper>
  )
}

export default PostArchivedItem