import React from 'react';
import { LoaderItem, LoaderWrapper } from './Loader.components';

const Loader: React.FC = () => {
  return (
    <LoaderWrapper>
        <LoaderItem />
    </LoaderWrapper>
  )
}

export default Loader