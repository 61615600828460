import { Caption, SectionTitle } from "atoms/text/Text";
import React from "react";
import { secondaryColor } from "theme";
import { ServiceTitleWrapper } from "./ServiceTitle.components";
import { ServiceTitleProps } from "./ServiceTitle.types";

const ServiceTitle: React.FC<ServiceTitleProps> = ({
  caption,
  title,
  higlight,
  hasBreakLine = true,
  wrapperStyle,
}) => {
  return (
    <ServiceTitleWrapper style={wrapperStyle}>
      <Caption>
        {caption}
      </Caption>
      <SectionTitle variant="sectionText">
        {title} {hasBreakLine && <br />}
        <SectionTitle color={secondaryColor}>
          {higlight}
        </SectionTitle>
      </SectionTitle>
      {/* <ServiceTitleContenWrapper>
        <SText>
          Estaciones meteorológicas con grado de investigación para distintas
          aplicaciones como son: Plantas Solares, eólicas, agricultura, ecología y
          medio ambiente. Nuestros sistemas son completamente automáticos y
          personalizados a la necesidad especifica de nuestros clientes.
        </SText>
      </ServiceTitleContenWrapper> */}
    </ServiceTitleWrapper>
  );
};

export default ServiceTitle;
