import styled from "styled-components";
import { ColorGray, ColorLightGray, Spacing, secondaryColor } from "theme";

export const TagWrapper = styled.div.attrs((props)=>({
    className: 'tag-wrapper'
}))`
    border: solid 1px ${ColorLightGray};
    height: 30px;
    text-align: center;
    padding: 0 ${Spacing.tinySpacing};
    margin-right: ${Spacing.tinySpacing};
    margin-bottom: ${Spacing.tinySpacing};
    cursor: pointer;
    transition: all ease 0.5s;

    :hover {
        background-color: ${secondaryColor};
    }
    
    :hover span {
        color: white;
    }
`;

export const Tag = styled.span.attrs((props)=>({
    className: 'tag'
}))`
    line-height: 20px;
    color: ${ColorGray};
    font-size: 14px;
`;