import React from 'react';
import { SocialIconType } from 'custom-types/design/atoms/SocialIcon';
import { SocialIconWrapper } from './SocialIcon.components';

const SocialIcon: React.FC<SocialIconType> = ({icon, alt, onClick}) => {
  return (
    <SocialIconWrapper onClick={onClick}>
      {icon}
    </SocialIconWrapper>
  )
}

export default SocialIcon