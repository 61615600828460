import { ChangeEvent, useState } from "react";
import { isValidEmail } from "fixtures/helpers";
import Swal from 'sweetalert2';
import {addDoc, collection} from 'firebase/firestore';
import { db, firebaseCollections } from "fixtures/firebase";
import { uploadContactFile } from "src/utils/uploadContactFile";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { SelectOption } from "atoms/select/Select";


const useContactForm = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);

  const handleName = (e: ChangeEvent<HTMLInputElement>)=> {
    setName(e.target.value);  
  }

  const handleEmail = (e: ChangeEvent<HTMLInputElement>)=> {
    setEmail(e.target.value);  
  }

  const handlePhone = (e: ChangeEvent<HTMLInputElement>)=> {
    setPhone(e.target.value);  
  }

  const handleSubject = (newValue: SingleValue<SelectOption>)=> {
    setSubject(newValue?.value || '');  
  }

  const handleMessage = (e: ChangeEvent<HTMLTextAreaElement>)=> {
    setMessage(e.target.value);
  }

  const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const resetForm = ()=> {
    setName('');
    setEmail('');
    setPhone('');
    setSubject('');
    setMessage('');
    setFile(null);
  }

  const sendEmail = async()=> {
    try {
      setIsLoading(true);
      const attachmentFile = file && await uploadContactFile({
        name: name,
        cellphone: phone,
        file,
      });
      const textEmail = `
          Asunto: ${subject}
          Nombre: ${name}
          Teléfono: ${phone}
          Email: ${email},
          Mensaje: ${message}
      `;
      const docRef = await addDoc(collection(db, firebaseCollections.contactForm), {
          to: 'jagowm@gmail.com',
          // to: 'lmp@reycorenovables.com',
          // cc: 'jrf@reycorenovables.com',
          // bcc: 'jagowm@gmail.com',
          replyTo: `${name} <${email}>`,
          message: {
              subject: 'Nuevo correo desde Reyco Renovables',
              text: textEmail,
              attachments: file ? {
                filename: attachmentFile?.fileName,
                path: attachmentFile?.fileUrl,
              } : undefined,
          } 
      });
      if(docRef.id){
          Swal.fire({
              text: t("contact.contactForm.success"),
              icon: 'success',
          });
          resetForm();
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const validateForm = ()=> {
    if(!name){
        Swal.fire({
            text: t("contact.contactForm.errors.name"),
            icon: 'warning',
        });
        return;
    }
    if(!email){
        Swal.fire({
            text: t("contact.contactForm.errors.email"),
            icon: 'warning',
        });
        return;
    }
    if(!isValidEmail(email)){
        Swal.fire({
            text: t("contact.contactForm.errors.validEmail"),
            icon: 'warning',
        });
        return;
    }
    if(!phone){
        Swal.fire({
            text: t("contact.contactForm.errors.phone"),
            icon: 'warning',
        });
        return;
    }
    if(!subject){
      Swal.fire({
          text: t("contact.contactForm.errors.subject"),
          icon: 'warning',
      });
      return;
  }
    if(!message){
        Swal.fire({
            text: t("contact.contactForm.errors.message"),
            icon: 'warning',
        });
        return;
    }
    if (file) {
      if (file.size > (2 * 1024 * 1024)) {
        Swal.fire({
          text: t("contact.contactForm.errors.fileSize"),
          icon: 'warning',
        });
        return;
      }
      if (file.type !== 'application/pdf') {
        Swal.fire({
          text: t("contact.contactForm.errors.fileType"),
          icon: 'warning',
        });
        return;
      }
    }
    sendEmail();
  }
  
    return {
        isLoading,
        name,
        email,
        phone,
        subject,
        message,
        file,
        handleName,
        handleEmail,
        handlePhone,
        handleSubject,
        handleMessage,
        handleSelectFile,
        validateForm,
    }
  
}

export default useContactForm