import React from 'react'
import { OurHeadquartersWrapper, TitleWrapper } from './OurHeadquarters.styed'
import { SText, SectionTitle } from 'atoms/text/Text'
import { secondaryColor } from 'theme'
import { ContainerMax } from 'src/design/containers/Container'
import Offices from 'organisms/Offices/Offices'
import { useTranslation } from 'react-i18next'

const OurHeadquarters = () => {
  const { t } = useTranslation();

  return (
    <OurHeadquartersWrapper>
      <ContainerMax>
        <TitleWrapper>
          <SText
            color={secondaryColor}
            variant='caption'
            fontWeight='bold'
            marginBottom='12px'
          >
            {t('contact.ourHeadquarters.caption')}
          </SText>
          <SectionTitle>
            {t('contact.ourHeadquarters.title')}
          </SectionTitle>
        </TitleWrapper>
        <div style={{padding: '45px 0px'}} className='offices-headquarters-wrapper'>
          <Offices
            isAnimated
            titleTextColor={secondaryColor}
            titleFontSize="16px"
            titleFontWeight="800"
            subtitleFontSize="16px"
            subtitleFontWeight="800"
            contentFontSize="16px"
          />
        </div>
      </ContainerMax>
    </OurHeadquartersWrapper>
  )
}

export default OurHeadquarters