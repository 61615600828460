import React, { useEffect } from 'react';
import { ScreenHeaderType } from 'custom-types/design/molecules/ScreenHeader';
import { HeaderSubtitle, HeaderTitle, HeaderTitleWrapper, ScreenHeaderImg, ScreenHeaderImgAnim, ScreenHeaderWrapper } from './ScreenHeader.components';
import gsap from 'gsap';

const ScreenHeader: React.FC<ScreenHeaderType> = ({image, title, subtitle, imgTitle, sectionName}) => {

  useEffect(()=>{
    gsap.to('.screen-header-img-anim', {transform: 'scale(1)', duration: 2});
    gsap.to('.screen-header-img', {opacity: 1, marginLeft: '10%', delay: .5, duration: 1});
  },[]);

  return (
    <ScreenHeaderWrapper>
      <ScreenHeaderImgAnim src={image} />
      {
        title && subtitle ? (
          <HeaderTitleWrapper>
            <HeaderTitle>{title}</HeaderTitle>
            <HeaderSubtitle>{subtitle}</HeaderSubtitle>
          </HeaderTitleWrapper>
        ) : (
          <ScreenHeaderImg
            src={imgTitle} 
            sectionName={sectionName}
          />
        )
      }
    </ScreenHeaderWrapper>
  )
}

export default ScreenHeader