import styled from "styled-components";
import { Spacing, backgroundLightColor } from "theme";
import { Breakpoints } from "src/theme/breakpoints";

export const WorkingEnergyWrapper = styled.section.attrs((props)=>({
  className: 'working-energy-wrapper'
}))`
  background-color: ${backgroundLightColor};
  padding: 100px 0 150px 0;

  @media (max-width: ${Breakpoints.lg}){
    padding: ${Spacing.xLargeSpacing} 0;
  }
`;

export const WorkingEnergyListWrapper = styled.div.attrs(()=>({
  className: 'working-energy-list-wrapper'
}))`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.lg}){
    flex-direction: column;
  }
`;