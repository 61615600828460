import { LanguageCodes } from "molecules/selectLanguage/SelectLanguage.types";
export enum PRISMIC_LANGUAGES {
  SPANISH = 'es-mx',
  ENGLISH = 'en-us'
};

export const getPrismicLanguage = (languageCode: LanguageCodes) => {
  switch (languageCode) {
    case LanguageCodes.ES:
      return 'es-mx';
    case LanguageCodes.EN:
      return 'en-us';
    default:
      return 'en-us';
  }
};