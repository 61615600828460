import { VacancyItemProps } from "custom-types/design/molecules/VacancyItem";
import { careersHygieneAndSafetyImg, imgVacanteDesarrolladorProyectos, imgVacanteDesarrolladorSoftware, imgVacanteIngenieroEnergias, imgVacanteTecnicoMantenimiento } from "images";
import { useTranslation } from "react-i18next";
import { primaryColor, secondaryColor, whiteColor } from "theme";
import { isMobileDevice } from "utils/isMobileDevice";

export const useVanciesController = () => {
    const isMobile = isMobileDevice();
    const captionColor = isMobile ? secondaryColor : whiteColor;
    const titleColor = isMobile ? primaryColor : whiteColor;
    const textColor = isMobile ? primaryColor : whiteColor;
    const buttonVariant = isMobile ? 'primary' : 'primary-white'; 
    const buttonWidth = isMobile ? '100%' : undefined;
    const isShowMainVacancy = isMobile ? false : true;
    const {t} = useTranslation();

    const mainOpenVacancy: VacancyItemProps = {
        img: imgVacanteTecnicoMantenimiento,
        vacancy: t('careers.vacancies.vacancy1')
    };

    const openVacancies: VacancyItemProps[] = [
        {
            img: imgVacanteDesarrolladorProyectos,
            vacancy: t('careers.vacancies.vacancy2')
        },
        {
            img: imgVacanteDesarrolladorSoftware,
            vacancy: t('careers.vacancies.vacancy3')
        },
        {
            img: imgVacanteIngenieroEnergias,
            vacancy: t('careers.vacancies.vacancy4')
        },
        {
            img: careersHygieneAndSafetyImg,
            vacancy: t('careers.vacancies.vacancy5')
        },
    ];

    if (isMobile) {
        openVacancies.push(mainOpenVacancy);
    }

    return {
        openVacancies,
        captionColor,
        titleColor,
        textColor,
        buttonVariant,
        buttonWidth,
        isShowMainVacancy
    }
};