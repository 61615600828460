import styled from 'styled-components';
import { SPanType, TextType } from 'custom-types/design/atoms/Text';
import { blackColor, secondaryColor, Spacing } from 'theme';
import { Breakpoints } from 'src/theme/breakpoints';

export const SText = styled.p.attrs(props => ({
  className: 's-text',
  style: props.style,
}))<TextType>`
  color: ${props => props.color || blackColor};
  font-size: ${
    props => props.variant === 'caption' ? '14px' : 
    props.variant === 'sectionText' ? '22px' : "16px"
  };
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 'normal'};
  margin-bottom: ${props => props.marginBottom || Spacing.baseSpacing};
  line-height:  ${
    props => props.variant === 'caption' ? '20px' : 
    props.variant === 'sectionText' ? '28px' : "22px"
  };
  font-family: ${props => props.variant === "sectionText" ? 'Archivo, sans-serif' : undefined };

  @media (max-width: ${Breakpoints.lg}){
    text-align: justify;
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const SSpan = styled.span.attrs(props => ({
  className: 's-span',
  style: props.style,
}))<SPanType>`
  color: ${props => props.color || blackColor};
  font-size: ${props => props.variant === 'sectionTitle' ? '45px' : "16px"};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || props.variant === 'sectionTitle' ?  'bold' : 'normal'};

  @media (max-width: ${Breakpoints.lg}){
    font-size: ${props => props.variant === 'sectionTitle' ? '36px' : "16px"};
  }
`;

export const Caption = styled.span.attrs(props => ({
  className: 'caption',
  style: props.style,
}))<SPanType>`
  color: ${props => props.color || secondaryColor};
  font-size: 14px;
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 'bold'};
  margin-bottom: ${Spacing.tinySpacing};
  display: block;
`;

export const SectionTitle = styled.h2.attrs((props)=>({
  className: 'section-title'
}))<TextType>`
  color: ${props => props.color || blackColor};
  font-size: ${props => props.fontSize || 'calc(1.3875rem + 1.65vw)'};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 'bold'};
  margin-bottom: ${props => props.marginBottom || Spacing.baseSpacing};
  line-height: 51px;

  @media (max-width: ${Breakpoints.lg}){
    text-align: left;
  }

  @media (max-width: ${Breakpoints.xs}){
    font-size: ${props => props.fontSize || '36px'};
    line-height: 42px;
    text-align: left;
  }
`;

export const TextOSL = styled.p.attrs(props => ({
  className: 'text'
}))<TextType>`
  color: ${props => props.color ?? blackColor};
  font-size: ${props => props.fontSize ?? '14px'};;
  font-family: 'Open sans';
  font-weight: 300;
  line-height: ${props => props.lineHeight ?? '30px'};
`;

export const TextOSR = styled.p.attrs(props => ({
  className: 'text'
}))<TextType>`
  color: ${props => props.color ?? blackColor};
  font-size: ${props => props.fontSize || '14px'};
  font-family: 'Open sans';
  font-weight: 400;
  line-height: ${props => props.lineHeight ?? '30px'};
`;

export const TextOSSB = styled.p.attrs(props => ({
  className: 'text'
}))<TextType>`
  color: ${props => props.color ?? blackColor};
  font-size: ${props => props.fontSize ?? '14px'} ;
  font-family: 'Open sans';
  font-weight: ${props => props.fontWeight ?? '600'};
`;

export const TextOSB = styled.p.attrs(props => ({
  className: 'text'
}))<TextType>`
  color: ${props => props.color ?? blackColor};
  font-size: 14px;
  font-family: 'Open sans';
  font-weight: 700;
`;
