import styled from "styled-components";
import { ColorRed } from "theme";

export const LoaderWrapper = styled.div.attrs((props)=>({
    className: 'loader-wrapper'
}))`
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

export const LoaderItem = styled.span.attrs((props)=>({
    className: 'loader-item'
}))`
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;

    ::before, ::after {
        border-radius: 50%;
        content: "";
        display: block;
        height: 20px;
        width: 20px;
    }

    ::before {
        animation: ball1 1s infinite;
        background-color: #fff;
        box-shadow: 30px 0 0 ${ColorRed};
        margin-bottom: 10px;
    }

    ::after {
        animation: ball2 1s infinite;
        background-color: ${ColorRed};
        box-shadow: 30px 0 0 #fff;
    }

    @keyframes rotate {
        0% { transform: rotate(0deg) scale(0.8) }
        50% { transform: rotate(360deg) scale(1.2) }
        100% { transform: rotate(720deg) scale(0.8) }
    }

    @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 ${ColorRed};
    }
    50% {
      box-shadow: 0 0 0 ${ColorRed};
      margin-bottom: 0;
      transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 ${ColorRed};
      margin-bottom: 10px;
    }
  }

  @keyframes ball2 {
        0% {
        box-shadow: 30px 0 0 #fff;
        }
        50% {
        box-shadow: 0 0 0 #fff;
        margin-top: -20px;
        transform: translate(15px, 15px);
        }
        100% {
        box-shadow: 30px 0 0 #fff;
        margin-top: 0;
        }
    }

`;

