import { useEffect } from "react";
import { useLocation } from "react-router-dom";


const PageView = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.dataLayer.push({ event: "page_view" });
  }, [pathname]);

  return null;
}

export default PageView;