import styled from "styled-components";
import { Spacing, secondaryColor } from "theme";

export const LastPostItemWrapper = styled.div.attrs((props)=>({
    className: 'last-post-item-wrapper'
}))`
    margin-bottom: ${Spacing.smallSpacing};

    :hover {
        cursor: pointer;
    }
`;

export const LastPostItemHolder = styled.div.attrs((props)=>({
    className: 'last-post-item-holder'
}))`
    display: flex;
`;

export const LastPostItemImageWrapper = styled.div.attrs((props)=>({
    className: 'last-post-item-image-wrapper'
}))`
    margin-right: ${Spacing.smallSpacing};
`;

export const LastPostItemImage = styled.img.attrs((props)=>({
    className: 'last-post-item-image'
}))`
    width: 74px;
    height: 74px;
    object-fit: cover;
`;

export const LastPostItemContentWrapper = styled.div.attrs((props)=>({
    className: 'last-post-item-content-wrapper'
}))`
    flex: 1;

    &:hover p {
        color: ${secondaryColor};
    }
`;