import { useContext, useEffect, useState } from 'react';
import {usePrismicClient} from '@prismicio/react';
import {PrismicDocument} from '@prismicio/types';
import {predicate} from '@prismicio/client';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import Swal from 'sweetalert2';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { t } from 'i18next';

const useSearchPosts = () => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const prismicClient = usePrismicClient();
  const [loading, setLoading] = useState<boolean>(true);
  const [postsFounded, setPostsFounded] = useState<PrismicDocument<Record<string, any>, string, string>[]>([]);
  const {
    searchPost, 
    searchCategoryId, 
    searchCategory, 
    currentPage, 
    pageSize, 
    setTotalPages,
    searchMonth,
    searchTag,
  } = useContext(SearchPostContext);
  const [postsCategoryFounded, setPostsCategoryFounded] = useState<PrismicDocument<Record<string, any>, string, string>[]>([]);
  const [postsMonthFounded, setPostsMonthFounded] = useState<PrismicDocument<Record<string, any>, string, string>[]>([]);
  const [postsTagFounded, setPostsTagFounded] = useState<PrismicDocument<Record<string, any>, string, string>[]>([]);

  useEffect(()=>{
    if(searchPost && !searchCategory && !searchMonth && !searchTag?.tagId){
      getPostBySearchText();   
    }else{
      setPostsFounded([]);
    }
  },[searchPost, currentPage]);

  const getPostBySearchText = async()=>{
    try {
      setLoading(true);
      const postFounded = await prismicClient.getByType('post',{
        lang: getPrismicLanguage(languageCode),
        fetchLinks: ['post_author.name', 'post_category.name'],
        orderings: ['my.post.posted_on desc'],
        predicates: [
          predicate.at('document.type', 'post'),
          predicate.fulltext('document', searchPost || '')
        ],
        pageSize: pageSize,
        page: currentPage
      });
      setTotalPages && setTotalPages(postFounded.total_pages);
      setPostsFounded(postFounded.results);
    } catch (error) {
      console.log('getPostBySearchText =>', error);
      showErrorFetchPost();
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    if(searchCategoryId && !searchPost && !searchMonth && !searchTag?.tagId){
      getPostByCategory();   
    }else{
      setPostsCategoryFounded([]);
    }
  },[currentPage, searchCategoryId, languageCode]);

  const getPostByCategory = async()=>{
    try {
      setLoading(true);
      const postCategoryFounded = await prismicClient.getByType('post',{
        lang: getPrismicLanguage(languageCode),
        fetchLinks: ['post_author.name', 'post_category.name'],
        orderings: ['my.post.posted_on desc'],
        predicates: [
          predicate.at('my.post.category', searchCategoryId || ''),
        ],
        pageSize: pageSize,
        page: currentPage
      });
      setTotalPages && setTotalPages(postCategoryFounded.total_pages);
      setPostsCategoryFounded(postCategoryFounded.results);
      setLoading(false);
    } catch (error) {
      console.log('getPostByCategory =>', error);
      showErrorFetchPost();
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    if(searchMonth && !searchPost && !searchCategory && !searchTag?.tagId){
      getPostByMonth();   
    }else{
      setPostsMonthFounded([]);
    }
  },[currentPage, searchMonth]);

  const getPostByMonth = async()=>{
    try {
      setLoading(true);
      const postMonthFounded = await prismicClient.getByType('post',{
        fetchLinks: ['post_author.name', 'post_category.name'],
        orderings: ['my.post.posted_on desc'],
        predicates: [
          predicate.dateMonth('my.post.posted_on', searchMonth || ''),
        ],
        pageSize: pageSize,
        page: currentPage
      });
      setTotalPages && setTotalPages(postMonthFounded.total_pages);
      setPostsMonthFounded(postMonthFounded.results);
    } catch (error) {
      console.log('getPostByMonth =>', error);
      showErrorFetchPost();
    } finally {
      setLoading(false);
    }
  }

  useEffect(()=>{
    if(searchTag?.tagId && !searchPost && !searchCategory && !searchMonth ){
      getPostByTag();   
    }else{
      setPostsTagFounded([]);
    }
  },[currentPage, searchTag?.tagId, languageCode]);

  const getPostByTag = async()=>{
    try {
      setLoading(true);
      const postTagFounded = await prismicClient.getByType('post',{
        lang: getPrismicLanguage(languageCode),
        fetchLinks: ['post_author.name', 'post_category.name'],
        orderings: ['my.post.posted_on desc'],
        predicates: [
          predicate.at('my.post.post_tags.tag', searchTag?.tagId || ''),
        ],
        pageSize: pageSize,
        page: currentPage
      });
      setTotalPages && setTotalPages(postTagFounded.total_pages);
      setPostsTagFounded(postTagFounded.results);
    } catch (error) {
      console.log('getPostByTag =>', error);
      showErrorFetchPost();
    } finally {
      setLoading(false);
    }
  }

  const showErrorFetchPost = ()=> {
    Swal.fire({
      text: t('home.blog.canNotFoundContent'),
      icon: 'warning'
    });
  }

  return {
    loading,
    setLoading,
    postsFounded,
    postsCategoryFounded,
    postsMonthFounded,
    postsTagFounded,
  }
}

export default useSearchPosts