import { imgMapa } from "images";
import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const EolicSectorWrapper = styled.section.attrs((props)=>({
  className: 'eolic-sector-wrapper'
}))`
  background: url(${imgMapa}) top center no-repeat;
  background-size: contain;
  padding: ${Spacing.xxxxLargeSpacing} 0;

  @media (max-width: ${Breakpoints.lg}) {
    padding-bottom: 0;
  }
`;

export const EolicSectorHolder = styled.div.attrs((props)=>({
  className: 'eolic-sector-holder'
}))``;

export const EolicSectorTitleHolder = styled.div.attrs((props)=>({
  className: 'eolic-sector-title-holder'
}))`
  text-align: center;

  @media (max-width: ${Breakpoints.lg}) {
    text-align: left;
    margin-bottom: ${Spacing.xxxxLargeSpacing};
  }
`;

export const EolicNumberListWrapper = styled.div.attrs((props)=>({
  className: 'eolic-numbers-list-wrapper'
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 362px;

  @media (max-width: ${Breakpoints.lg}){
    flex-direction: column;
    margin-bottom: ${Spacing.xxxxLargeSpacing};
  }
`;

export const VideoWrapper = styled.div.attrs((props)=>({
  className: 'video-wrapper'
}))`
    display: flex;

  iframe{
    width: 1126px;
    height: 524px;
    margin: 0px auto;
  }
`;

