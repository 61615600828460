import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { imgServiciosParquesSolares, imgServiciosParquesSolaresInstalacion, imgServiciosParquesSolaresMantto, imgServiciosParquesSolaresSupervision, panelesSolaresSumnistrosImg } from "images";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import ServiceTitle from "molecules/serviceTitle/ServiceTitle";
import React from "react";
import { Container, Content } from "src/design/containers/Container";
import ServiceSubitem from 'organisms/serviceSubitem/ServiceSubitem';
import { CeldaSolarIcon, PlanoIcon, SuministrosIcon } from "src/icons";
import Mantenimiento from "./Mantenimiento";
import Instalacion from "./Instalacion";
import Suministros from "./Suministros";
import Supervision from "./Supervision";
import { ServiceWrapper } from "screens/infraestructuraElectrica/InfraestructuraElectrica.styled";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const ParquesSolares: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <>
      <ScreenHeader
        image={imgServiciosParquesSolares}
        title={t("solarFarms.header.title")}
        subtitle={t("solarFarms.header.subtitle")}
        sectionName={SectionName.PARQUES_EOLICOS}
      />
      <ServiceWrapper>
        <Container>
          <ServiceSubitem
            imageId="parques-solares-mantenimiento"
            content={
              <Mantenimiento />
            }
            imgMobile={isMobile ? imgServiciosParquesSolaresMantto : undefined}
            img={isMobile ? undefined : imgServiciosParquesSolaresMantto}
            imgPosition="right"
          />
          <ServiceSubitem
            imageId="parques-solares-instalacion"
            icon={<CeldaSolarIcon width={49} height={49} />}
            content={
              <Instalacion />
            }
            img={imgServiciosParquesSolaresInstalacion}
            imgPosition="left"
          />
          <ServiceSubitem
            imageId="parques-solares-suministros"
            icon={<SuministrosIcon width={49} height={49} />}
            content={
              <Suministros />
            }
            imgMobile={isMobile ? panelesSolaresSumnistrosImg : undefined}
            img={isMobile ? undefined : panelesSolaresSumnistrosImg}
            imgPosition="right"
          />
          <ServiceSubitem
            imageId="parques-solares-supervision"
            icon={<PlanoIcon width={49} height={49} />}
            content={
              <Supervision />
            }
            img={imgServiciosParquesSolaresSupervision}
            imgPosition="left"
          />
        </Container>
      </ServiceWrapper>
    </>
  )
}

export default ParquesSolares