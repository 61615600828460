import styled from "styled-components";
import { backgroundLightColor, grayc9c9c9 } from "theme";

export const OurHeadquartersWrapper = styled.section.attrs(props => ({
  className: 'our-headquarters-wrapper'
}))`
  padding: 80px 40px 0;
  background-color: ${backgroundLightColor};
`;

export const TitleWrapper = styled.div.attrs(props => ({
  className: 'title-wrapper'
}))`
  padding-bottom: 60px;
  border-bottom: solid 1px ${grayc9c9c9};
  text-align: center;
`;