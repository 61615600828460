import styled from "styled-components";
import { ColorWhite, Spacing } from "theme";

const InputNewsLetter = styled.input.attrs(props => ({
  placeholder: props.placeholder,
  type: props.type,
}))`
  background: transparent;
  border: 1px ${ColorWhite} solid;
  color: ${ColorWhite};
  padding: 16px;
  font-size: 16px;
  width: 100%;
  height: 52px;
  outline: none;
  
  ::placeholder {
    color: ${ColorWhite}
  }
`;

export default InputNewsLetter;

