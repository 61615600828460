import { Caption, SectionTitle } from 'atoms/text/Text';
import React from 'react';
import { Container, Content } from 'src/design/containers/Container';
import { EolicSectorTitleHolder, EolicSectorWrapper, VideoWrapper } from './EolicSector.components';
import { config } from 'fixtures/config';
import { useTranslation } from 'react-i18next';

const EolicSector: React.FC = () => {
  const {t} = useTranslation();

  return (
    <EolicSectorWrapper>
      <Container>
        <EolicSectorTitleHolder>
          <Content>
            <Caption>
              {t('about.eolicSector.caption')}
            </Caption>
            <SectionTitle>
              {t('about.eolicSector.title')}
            </SectionTitle>
          </Content>
        </EolicSectorTitleHolder>
        {/* <EolicNumberListWrapper>
          <EolicNumber 
            number={50}
            text='Around the worl'
            title='Ciudades'
          />
          <EolicNumber 
            number={50}
            text='Around the worl'
            title='Parques'
          />
          <EolicNumber 
            number={50}
            text='Around the worl'
            title='Energia'
          />
        </EolicNumberListWrapper> */}
        <VideoWrapper>
          <iframe width="100%" height="100%" src={config.urls.youtubeVideo} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        </VideoWrapper>
      </Container>
    </EolicSectorWrapper>
  )
}

export default EolicSector