import styled from "styled-components";
import {X, MagnifyingGlass} from 'phosphor-react';
import { Spacing } from "theme";

export const SearchPostF = styled.form.attrs((props)=>({
   className: 'search-post-form' 
}))`
   position: relative;
`;

export const SearchIcon = styled(MagnifyingGlass)`
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   left: ${Spacing.tinySpacing};
`;

export const ClearSearchIcon = styled(X)`
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: ${Spacing.tinySpacing};
`;