import React, {useEffect, useState, useContext} from 'react';
import {PrismicDocument} from '@prismicio/types';
import { PostListWrapper } from './PostList.components';
import PostItem from 'molecules/postItem/PostItem';
import { useNavigate } from 'react-router-dom';
import Loader from 'atoms/loader/Loader';
import useSearchPosts from 'hooks/useSearchPosts';
import PostListCategory from './PostListCategory';
import PostListSearch from './PostListSearch';
import { prismicClient } from 'fixtures/prismic';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import PostListMonth from './PostListMonth';
import PostListTag from './PostListTag';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';


const PostList: React.FC = () => {
  const {i18n, t} = useTranslation()
  const languageCode = i18n.language as LanguageCodes;
  const navigate = useNavigate();
  const [posts, setPosts] = useState<PrismicDocument<Record<string, any>, string, string>[]>([]);
  
  const {
    loading, 
    setLoading
  } = useSearchPosts();
  
  const {
    searchPost, 
    searchCategory, 
    searchMonth,
    searchTag,
    currentPage, 
    setTotalPages, 
    pageSize,
  } = useContext(SearchPostContext);

  useEffect(()=>{
    shouldScrollTop();
    if(!searchPost && !searchCategory && !searchMonth && !searchTag?.tagId){
      getPost();
    }
  },[currentPage, searchPost, searchCategory, searchMonth, searchTag, languageCode]);

  const shouldScrollTop = ()=> {
    if(searchPost || searchCategory || searchMonth || searchTag?.tagId){
      scrollToTopBlog();
    }
  }

  const scrollToTopBlog = ()=> {
    const blogholderTop = (document.querySelector('.blog-holder') as HTMLDivElement).offsetTop;
    window.scrollTo(0, blogholderTop);
  }

  const getPost = async()=>{
    setLoading(true);
    const postFounded = await prismicClient.getByType('post',{
      fetchLinks: ['post_author.name', 'post_category.name'],
      orderings: ['my.post.posted_on desc'],
      pageSize: pageSize,
      page: currentPage,
      lang: getPrismicLanguage(languageCode),
    });
    setTotalPages && setTotalPages(postFounded.total_pages);
    setPosts(postFounded.results);
    setLoading(false);
  }

  const handlePostClick = (uid: string | null)=> {
    navigate(`/Blog/${uid}`);
  }
    
  return (
    <>
      <PostListWrapper>
        {
          posts?.length > 0
          && !searchPost 
          && !searchCategory 
          && !searchMonth 
          && !searchTag?.tag &&
            posts?.map((post)=>{
              return(
                <PostItem
                    key={post.id} 
                    topImage={post.data.top_image.url}
                    category={post.data.category.data.name}
                    showCategoryImage
                    createdAt={post.data.posted_on}
                    author={post.data.author.data.name}
                    title={post.data.title}
                    contentTop={post.data.content_top[0].text}
                    textButton={t('blog.readMore')}
                    onClickButton={()=>{handlePostClick(post.uid)}}
                />
              )
            })
        }
        {
          searchPost &&
            <PostListSearch />
        }
        {
          searchCategory &&
            <PostListCategory />
        }
        {
          searchMonth &&
            <PostListMonth />
        }
        {
          searchTag?.tagId &&
            <PostListTag />
        }
      </PostListWrapper>
      {
        loading &&
          <Loader />
      }
    </>
  )
}

export default PostList