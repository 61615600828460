export enum PATH_NAMES {
  ROOT = "/",
  ABOUT_US = "/Nosotros",
  CERTIFICATIONS = "/Certificaciones",
  WIND_FARMS = "/Parques_eolicos",
  SOLAR_PARKS = "/Parques_solares",
  ELECTRICAL_INFRASTRUCTURE = "/Infraestructura_electrica",
  METEOROLOGICAL_TOWERS = "/Torres_meteorologicas",
  CAREERS = "/Carreras",
  PROFESSIONAL_PROFILES = "/Perfiles_profesionales",
  CONTACT = "/Contacto",
  BLOG = "/Blog",
  PROJECTS = "/Proyectos",
  BROCHURE = "/Brochure",
};

export enum LABELS_MAIN_MENU {
  HOME = "home",
  ABOUT = "about",
  ABOUT_US = "aboutUs",
  CERTIFICATIONS = "certifications",
  PROJECTS = "projects",
  SERVICES = "services",
  WIND_FARMS = "windFarms",
  SOLAR_PARKS = "solarParks",
  ELECTRICAL_INFRASTRUCTURE = "electricalInfrastructure",
  METEOROLOGICAL_TOWERS = "meteorologicalTowers",
  CAREERS = "careers",
  PROFESSIONAL_PROFILES = "professionalProfiles",
  CONTACT = "contact",
  BLOG = "blog",
}