import React, { useLayoutEffect, useRef } from 'react';
import { ImageContentType } from 'custom-types/design/molecules/ImageContent';
import { Container, Content } from 'src/design/containers/Container';
import { ContentWrapper, ImageContentHolder, ImageContentImg, ImageContentImgWrapper, ImageContentWrapper, TitleWrapper } from './ImageContent.components';
import { Caption } from 'atoms/text/Text';
import SButton from 'atoms/button/Button';
import gsap from 'gsap';
import { DecorationLine } from 'atoms/decorationLine/DecorationLine';
import { ImageMobile } from 'molecules/imageWidthContent/ImageWithContent.components';

const ImageContent: React.FC<ImageContentType> = ({
  backgroundColorSection,
  imagePlace, 
  image, 
  caption, 
  captionColor, 
  title, 
  content, 
  showButton, 
  textButton, 
  onPressButton,
  imageWidth,
  imageHeight,
  imageMaxWidth,
  isShowDecorationLine = true,
  isFlexReverse,
  imageId,
  isSwitchMobile = false,
}) => {

  const imgLeftRef = useRef(null);
  const imgRightRef = useRef(null);

  useLayoutEffect(()=>{
    if(imgLeftRef.current){
      gsap.fromTo(imgLeftRef.current, {left: '-100%', opacity: 0}, {left: 0, opacity: 1, scrollTrigger: {trigger: `#${imageId}`, start: 'top center'}});
    }
    if(imgRightRef.current){
      gsap.fromTo(imgRightRef.current, {right: '-100%', opacity: 0}, {right: 0, opacity: 1, scrollTrigger: {trigger: `#${imageId}`, start: 'top center'}});
    }
  }, [imageId]);
  
  return (
    <ImageContentWrapper
      backgroundColorSection={backgroundColorSection}
    >
      <Container>
        <ImageContentHolder isFlexReverse={isFlexReverse}>
          {
            imagePlace === 'left' &&
              <ImageContentImgWrapper
                isSwitchMobile={isSwitchMobile}
                imagePlace={imagePlace}
              >
                <ImageContentImg
                  id={imageId}
                  ref={imgLeftRef}
                  src={image} 
                  imagePlace={imagePlace}
                  style={{
                    maxWidth: imageMaxWidth || "100%",
                    width: imageWidth || "auto", 
                    height: imageHeight || "auto",
                  }}
                />
              </ImageContentImgWrapper>
          }
          <ContentWrapper>
            <Content>
              <Caption>
                {caption}
              </Caption>
              <TitleWrapper>
                {title}
              </TitleWrapper>
            </Content>
            {isSwitchMobile ? (
              <ImageMobile src={image} alt=''/>
            ) : undefined}
            <Content>
              {content}
              {
                showButton &&
                  <SButton 
                    title={textButton} 
                    onClick={onPressButton}
                    variant='primary-white'
                  >
                    {textButton || ''}
                  </SButton>
              }
              {isShowDecorationLine ? <DecorationLine /> : null}
            </Content>
        </ContentWrapper>
        {
          imagePlace === 'right' &&
            <ImageContentImgWrapper 
              isSwitchMobile={isSwitchMobile}
              imagePlace={imagePlace}
            >
              <ImageContentImg 
                id={imageId}
                imagePlace={imagePlace}
                ref={imgRightRef}
                src={image} 
                style={{
                  width: imageWidth || undefined, 
                  height: imageHeight || undefined,
                  objectFit: imageWidth ? 'cover' : undefined,
                }}
              />
            </ImageContentImgWrapper>
        }
        </ImageContentHolder>
      </Container>
    </ImageContentWrapper>
  )
}

export default ImageContent;