import styled from "styled-components";

export const BrochureWrapper = styled.section.attrs((props)=>({
    className: 'brochure-wrapper'
}))`
    padding: 117px 0;
`;

export const BrochureHolder = styled.div.attrs((props)=>({
    className: 'brochure-holder'
}))`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

