import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { secondaryColor } from "theme";
import { List } from "./ParquesEolicos.components";
import { useTranslation } from "react-i18next";
import ServiceTitle from "molecules/serviceTitle/ServiceTitle";
import { ManttoIcon } from "src/icons";


const Mantenimiento: React.FC = () => {
  const {t} = useTranslation();
  return (
    <>
      <ServiceTitle
        caption={t("windFarms.subHeader.caption")}
        title={t("windFarms.subHeader.title1")}
        higlight={t("windFarms.subHeader.title2")}
      />
      <ManttoIcon width={49} height={49} />
      <SectionTitle>{t('windFarms.mantto.title')}</SectionTitle>
      <SText>
        {t('windFarms.mantto.paragraph1')}
      </SText>
      <SText>
        {t('windFarms.mantto.paragraph2')}
      </SText>
      <SText color={secondaryColor} marginBottom="0">
        {t('windFarms.mantto.list1.title')}
      </SText>
      <List>
        <li>
            {t('windFarms.mantto.list1.item1')}
        </li>
        <li>
            {t('windFarms.mantto.list1.item2')}
        </li>
      </List>
      <SText color={secondaryColor} marginBottom="0">
        {t('windFarms.mantto.list2.title')}
      </SText>
      <List>
        <li>
            {t('windFarms.mantto.list2.item1')}
        </li>
      </List>
    </>
  );
};

export default Mantenimiento;
