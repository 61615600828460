import {StorageReference, getDownloadURL, ref, uploadBytes} from 'firebase/storage';
import { storage } from "fixtures/firebase";

export type FileData = {
  name: string;
  cellphone: string;
  file: File;
};

export type AttachmentFile = {
  fileName: string;
  fileUrl: string;
};

export const uploadContactFile = async (fileData: FileData): Promise<AttachmentFile> => {
  const {name, cellphone, file} = fileData;
  const fileName = `${name}_${cellphone}_${file.name}`;
  const filePath = `contact-form-files/${fileName}`;
  const contactFilesStorageReference = ref(storage, filePath);
  await uploadBytes(contactFilesStorageReference, file);
  const fileUrl = await getFileUrl(contactFilesStorageReference);
  return ({
    fileName,
    fileUrl,
  });
};

const getFileUrl = async (storageReference: StorageReference): Promise<string> => {
  const fileUrl = await getDownloadURL(storageReference)
  return fileUrl;
};