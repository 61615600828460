import Select from 'atoms/select/Select';
import { useSelectLenguageConroller } from './SelectLanguage.controller';
import { SelectLanguageProps } from './SelectLanguage.types';

const SelectLanguage: React.FC<SelectLanguageProps> = ({handleMenuVisible}) => {
  const {handleLanguageChange, languages, language} = useSelectLenguageConroller(handleMenuVisible);
  console.log('language =>', language);
  return (
    <Select
      defaultInputValue={languages.find((lang) => lang.value === language)}
      isLanguageSelect
      onChange={handleLanguageChange}
      options={languages.map((lang) => ({label: lang.label, value: lang.value}))}
    />
  );
};

export default SelectLanguage;