import styled from "styled-components";
import { logoBlue, logoReyco } from "images";
import { Breakpoints } from "src/theme/breakpoints";
import { LogoProps } from "./Logo.types";

const Logo = styled.img.attrs<LogoProps>(props => ({
  src: props.type === 'blue' ? logoBlue : logoReyco,
}))<LogoProps>`
  height: 37px;
  width: 163px;
  cursor: pointer;

  @media (max-width: ${Breakpoints.lg}){
    height: auto;
    width: 120px;
  }
`;

export default Logo;