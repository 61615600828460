import React from 'react';
import SImageService from 'atoms/imageService/SImageService';
import { WorkingEnergyItemWrapper } from './WorkingEnergyItem.components';
import { SText } from 'atoms/text/Text';
import { WorkingEnergyItemType } from 'custom-types/design/molecules/WorkingEnergyItem';
import { Spacing } from 'theme';

const WorkingEnergyItem: React.FC<WorkingEnergyItemType> = ({image, title, text, imageStyle}) => {
  return (
    <WorkingEnergyItemWrapper>
      <SImageService 
        image={image}
        alt={title}
        wrapperStyles={{marginBottom: Spacing.baseSpacing}}
        imageStyles={imageStyle}
      />
      <SText
        variant='sectionText'
        fontWeight='bold'
        style={{marginBottom: Spacing.tinySpacing}}
      >
        {title}
      </SText>
      <SText>
        {text}
      </SText>
    </WorkingEnergyItemWrapper>
  )
}

export default WorkingEnergyItem