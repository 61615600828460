import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { ColorLightGrayB, Spacing } from "theme";

export const PostViewWrapper = styled.div.attrs((props)=>({
    className: 'post-view-wrapper'
}))`
    background: ${ColorLightGrayB};
`;

export const PostViewHolder = styled.div.attrs((props)=>({
    className: 'post-view-holder'
}))`
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 0;
    gap: ${Spacing.xxxxLargeSpacing};

    @media (max-width: ${Breakpoints.lg}){
        grid-template-columns: 1fr;
    }
`;

export const PostColumnWrapper = styled.div.attrs((props)=>({
    className: 'post-column-wrapper'
}))``;