import React, { useEffect } from 'react';
import { SectionTitle } from 'atoms/text/Text';
import { Container, Content } from 'src/design/containers/Container';
import { ColorRed } from 'theme';
import { WorkingEnergyListWrapper, WorkingEnergyWrapper } from './WorkingEnergy.components';
import WorkingEnergyItem from 'molecules/workingEnergyItem/WorkingEnergyItem';
import { energySolutionsImg, farmPlaningImg, qualityStandarsImg, technicalServiceImg } from 'images';
import gsap from 'gsap';
import { useTranslation } from 'react-i18next';

const WorkingEnergy: React.FC = () => {
  const {t} = useTranslation();
  useEffect(()=>{
    gsap.to('.working-energy-item-wrapper', {marginTop: 0, opacity: 1, stagger: 0.5, scrollTrigger: {
      trigger: '.working-energy-item-wrapper',
      start: 'top center'
    }});
  },[]);

  return (
    <WorkingEnergyWrapper>
      <Container>
        <Content>
          <SectionTitle
            style={{
              marginBottom: '73px'
            }}
          >
            {t('about.workingEnergy.title1')} <br />
            <SectionTitle color={ColorRed}>
            {t('about.workingEnergy.title2')}
            </SectionTitle>
          </SectionTitle>
          <WorkingEnergyListWrapper>
            <WorkingEnergyItem 
              image={farmPlaningImg}
              title={t('about.workingEnergy.items.building.title')}
              text={t('about.workingEnergy.items.building.text')}
            />
            <WorkingEnergyItem 
              image={energySolutionsImg}
              title={t('about.workingEnergy.items.solutions.title')}
              text={t('about.workingEnergy.items.solutions.text')}
            />
            <WorkingEnergyItem 
              image={qualityStandarsImg}
              title={t('about.workingEnergy.items.certifications.title')}
              text={t('about.workingEnergy.items.certifications.text')}
            />
            <WorkingEnergyItem 
              image={technicalServiceImg}
              title={t('about.workingEnergy.items.technicalService.title')}
              text={t('about.workingEnergy.items.technicalService.text')}
            />
          </WorkingEnergyListWrapper>
        </Content>
      </Container>
    </WorkingEnergyWrapper>
  )
}

export default WorkingEnergy