export const ColorRed = '#D82520';
export const ColorBlue= '#0D2536';
export const ColorWhite = '#FFFFFF';
export const ColorBlack = '#000000';
export const ColorBlackText = '#0D2536';
export const ColorBlackContainer = '#32373E';
export const ColorGray = '#707070';
export const ColorGrayNavSlider = '#C7C6C6';
export const ColorLightGray = '#A6ADB4';
export const ColorLightGrayB = '#F2F2F2';
export const strongGrayColor = '#31373D';
export const primaryColor = "#202B34";
export const backgroundLightColor = "#F7F7F7";
export const backgroundGrayColor = "#31373D";
export const backgroundDarkColor = "#051521";
export const secondaryColor = "#D82520";
export const secondaryColorOpacity = "#D8252060";
export const accentColor = "#2B3E74";
export const whiteColor = '#FFFFFF';
export const blackColor = '#000000';
export const grayc9c9c9 = '#c9c9c9';
export const grayd8d8d8 = '#d8d8d8';
export const blue202B34 = '#202B34';
export const redFF4770 = '#FF4770';