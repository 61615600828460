import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const ServiceSubitemWrapper = styled.div.attrs((props)=>({
  className: "service-subitem-wrapper"
}))<{isFlexReverse?: boolean}>`
  display: flex;
  align-items: center;

  @media (max-width: ${Breakpoints.xl}){
    flex-direction: ${props => props.isFlexReverse ? 'column-reverse' : 'column'};
    padding: ${Spacing.xLargeSpacing} 0;
  }

  @media (max-width: ${Breakpoints.lg}){
    padding: 0;
  }
`;

interface ServiceSubitemContentWrapperProps {
  imgPosition?: "left" | "right";
};

export const ServiceSubitemContentWrapper = styled.div.attrs((props)=>({
  className: "service-subitem-content-wrapper"
}))<ServiceSubitemContentWrapperProps>`
  padding-right: ${props => props.imgPosition === "right" ? "100px" : undefined};
  padding: ${props => props.imgPosition === "left" ? "0 95px" : undefined};
  width: 40%;
  position: relative;

  @media (max-width: ${Breakpoints.xl}){
    padding-top: ${Spacing.xLargeSpacing};
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
`;

export const ServiceSubitemIconWrapper = styled.div.attrs((props)=>({
  className: "icon-wrapper"
}))`
  width: 49;
  height: 49;
  margin: ${Spacing.tinySpacing} 0;
`;

export const ServiceSubItemImgWrapper = styled.div.attrs((props)=>({
  className: "service-subitem-img-wrapper"
}))`
  width: 60%;

  @media (max-width: ${Breakpoints.xl}){
    width: 100%;
  }
`;

export const ServiceSubItemImg = styled.img.attrs((props)=>({
  className: "service-subitem-img"
}))`
  width: 100%;
  height: auto;
  position: relative;

  @media (max-width: ${Breakpoints.lg}){
    min-height: 346px;
    object-fit: cover;
  }
`;