import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const LastPostsCarouselWrapper = styled.section.attrs((props)=>({
    className: 'last-posts-carousel-wrapper'
}))`
  padding: 94px 0;

  .post-item-wrapper {
    height: 500px;
    overflow-y: hidden;
    box-shadow: 0px 3px 20px #0000000F;
    margin-bottom: 0;
    padding-bottom: 8px;
  }

  
  .post-item-content-wrapper {
    padding: ${Spacing.largeSpacing} 0;
  }
  
  .post-item-content-holder {
    padding: 0 ${Spacing.baseSpacing};

    & h1 {
      font-size: 20px;
    }
  }

  .s-text {
    margin-bottom: ${Spacing.smallSpacing}
  }
  
  .s-text:last-child {
    margin-bottom: 0;
  }
`;

export const LastPostsCarouselHolder = styled.div.attrs((props)=>({
    className: 'last-posts-carousel-holder'
}))`
  display: flex;
  gap: ${Spacing.xLargeSpacing};

  @media (max-width: ${Breakpoints.lg}){
    flex-direction: column;
  }
`;

export const PostsWrapper = styled.div.attrs((props)=>({
  className: 'posts-wrapper'
}))`
  flex: 1;
  overflow-x: hidden;

  @media (max-width: ${Breakpoints.xxl}){
    & .swiper-pagination {
      display: none;
    }
  }
`;

export const StrokedTextWrapper = styled.div.attrs(props => ({
  className: 'stroked-text-wrapper'
}))`
  position: absolute;
  left: -0.5rem;
  top: -12%;
  z-index: -1;

  & > h1 {
    font-size: 130px;
  }

  @media (max-width: ${Breakpoints.lg}){
    top: -50%;
  }
`;

export const LastPostsCarouselTitleWrapper = styled.div.attrs((props)=>({
    className: 'last-posts-carousel-title-wrapper'
}))`
  position: relative;
  width: 400px;

  @media (max-width: ${Breakpoints.lg}){
    margin-bottom: ${Spacing.xLargeSpacing};
  }
`;

