import React from 'react';
import PostView from 'templates/postView/PostView';

const Post: React.FC = () => {

  return (
    <>
      <PostView />
    </>
  )
}

export default Post