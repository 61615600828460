import { SText } from 'atoms/text/Text';
import { VacancyItemProps } from 'custom-types/design/molecules/VacancyItem';
import React from 'react';
import { ColorWhite } from 'theme';
import { VacancyItemHolder, VacancyItemImage, VacancyItemImageWrapper, VacancyItemTextWrapper, VacancyItemWrapper } from './VacancyItem.components';

const VacancyItem: React.FC<VacancyItemProps> = ({img, vacancy}) => {
  return (
    <VacancyItemWrapper>
      <VacancyItemHolder>
        <VacancyItemImageWrapper>
          <VacancyItemImage src={img} />
        </VacancyItemImageWrapper>
        <VacancyItemTextWrapper>
          <SText
            color={ColorWhite}
            fontWeight='bold'
            fontSize='18px'
          >
            {vacancy}
          </SText>
        </VacancyItemTextWrapper>
      </VacancyItemHolder>
    </VacancyItemWrapper>
  )
}

export default VacancyItem