import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { ColorWhite, Spacing} from "theme";

export const ASlider = styled.div.attrs(()=>({
    className: 'iso-slider-wrapper'
}))`
    padding-bottom: ${Spacing.xxxxLargeSpacing};

    .swiper-pagination {
        text-align: left;
        z-index: 10000;
        bottom: 0;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 55px;
        height: 6px;
        background-color: #D82520;
        border-radius: 0;
    }

    .swiper-pagination-bullet{
        width: 55px;
        height: 3px;
        
        border-radius: 0;
    }

    .image-content-wrapper{
        padding: 50px 0 0 0;
    }
`;

export const SliderWrapper = styled.div.attrs((props)=>({
    className: 'slider-wrapper'
}))`
    background-color: ${ColorWhite};
`;

export const LogoIso = styled.img.attrs((props)=>({
    className: 'logo-iso'
}))`
    width: 89px;
    height: 93px;
`;