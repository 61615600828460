import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { accentColor } from "theme";

export const CertificationDocumentsWrapper = styled.div.attrs<{className: string}>((props) => ({
  className: 'certification-documents-wrapper'
}))`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;

  @media (max-width: ${Breakpoints.md}){
    flex-direction: column-reverse;
  }
`;

export const CertificationDocumentsHolder = styled.div.attrs((props) => ({
  className: 'certification-documents-holder'
}))`
  display: flex;
  gap: 20px;

    img {
      width: 186px;
      height: 248px;
      cursor: pointer;
    }

  @media (max-width: ${Breakpoints.md}) {
      img {
        width: 143px;
        height: 191px;
        cursor: pointer;
      }
  }
`;

export const PdfModal = styled.div.attrs<{className: string}>((props) => ({
  className: 'pdf-modal'
}))`
  position: fixed;
  background-color: #2b3e7480;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PdfWrapper = styled.div.attrs<{className: string}>((props) => ({
  className: 'pdf-wrapper'
}))`
  position: absolute;
  border: solid 25px ${accentColor};

  @media (max-width: ${Breakpoints.lg}) {
    border: solid 16px ${accentColor};
  }
`;

export const PdfButtonWrapper = styled.div.attrs<{className: string}>((props) => ({
  className: 'pdf-button-wrapper'
}))`
  position: absolute;
  width: 80px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;


  
  &.close-wrapper {
    top: 0;
    right: 0;
    background: ${accentColor};
    transform: translate(100%, -25px);
    cursor: pointer;
    font-size: 42px;

    @media (max-width: ${Breakpoints.lg}){
      width: 40px;
      height: 50px;
      font-size: 24px;
      transform: translate(100%, -16px);
    }
  }

  &.prev-wrapper {
    top: 50%;
    left: 0;
    background: ${accentColor};
    transform: translate(-100%, -50%);
    cursor: pointer;
  }

  &.next-wrapper {
    top: 50%;
    right: 0;
    background: ${accentColor};
    transform: translate(100%, -50%);
    cursor: pointer;
  }
`