import React, { useLayoutEffect, useRef } from "react";
import { ServiceSubitemContentWrapper, ServiceSubitemIconWrapper, ServiceSubItemImg, ServiceSubItemImgWrapper, ServiceSubitemWrapper } from '../serviceSubitem/ServiceSubitem.components';
import { ServiceSubitemProps } from "./ServiceSubitem.types";
import { Content } from "src/design/containers/Container";
import { Spacing } from "theme";
import gsap from "gsap";


const ServiceSubItem: React.FC<ServiceSubitemProps> = ({icon, content, img, imageId, imgMobile, imgPosition, isFlexReverse}) => {
  const imgLeftRef = useRef(null);
  const imgRightRef = useRef(null);

  useLayoutEffect(()=>{
    if(imgLeftRef.current){
      gsap.fromTo(imgLeftRef.current, {left: '-100%', opacity: 0}, {left: 0, opacity: 1, scrollTrigger: {trigger: `#${imageId}`, start: 'top center'}});
    }
    if(imgRightRef.current){
      gsap.fromTo(imgRightRef.current, {right: '-100%', opacity: 0}, {right: 0, opacity: 1, scrollTrigger: {trigger: `#${imageId}`, start: 'top center'}});
    }
  }, [imageId]);

  return (
    <ServiceSubitemWrapper isFlexReverse={isFlexReverse}>
      {
        img && imgPosition === "left" ? (
          <ServiceSubItemImgWrapper>
            <ServiceSubItemImg id={imageId} ref={imgLeftRef} src={img} />
          </ServiceSubItemImgWrapper>
        ) : undefined
      }
      <ServiceSubitemContentWrapper imgPosition={imgPosition}>
        {
          imgMobile ? (
            <img style={{marginBottom: Spacing.xLargeSpacing, objectFit: 'cover'}} height={346} width={'100%'} src={imgMobile} alt="" />
          ) : undefined
        }
        <Content>
          {
            icon &&
            <ServiceSubitemIconWrapper>
              {icon}
            </ServiceSubitemIconWrapper>
          }
          {content}
        </Content>
      </ServiceSubitemContentWrapper>
      {
        img && imgPosition === "right" ? (
          <ServiceSubItemImgWrapper>
            <ServiceSubItemImg id={imageId} ref={imgRightRef} src={img} />
          </ServiceSubItemImgWrapper>
        ) : undefined
      }
    </ServiceSubitemWrapper>
  )
}

export default ServiceSubItem