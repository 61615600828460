import React, { useEffect, useState } from 'react';
import PostArchivedItem from 'molecules/postArchivedItem/PostArchivedItem';
import { useAllPrismicDocumentsByType } from '@prismicio/react';
import { PostsArchivedByYearProps } from './PostArchivedList.types';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { getPrismicLanguage } from '../../../utils/i18n/getPrismicLanguage';

const PostArchivedList: React.FC = () => {
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const [postByYear, setPostByYear] = useState<PostsArchivedByYearProps[]>();
  const [posts, state] = useAllPrismicDocumentsByType('post', {
    lang: getPrismicLanguage(languageCode),
  });

  const groupPostsByYearAndMonth = () => {
    if (posts && posts.length > 0) {
      const groupedPosts: PostsArchivedByYearProps[] = [];
      posts.forEach((post) => {
        const year = new Date(post.data.posted_on).getFullYear();
        const month = new Date(post.data.posted_on).getMonth();
        const existingYear = groupedPosts.find((group) => group.year === year);
        if (existingYear) {
          const existingMonth = existingYear.postsByMonth.find((group) => group.month === month);
          if (existingMonth) {
            existingMonth.posts.push(post);
          } else {
            existingYear.postsByMonth.push({
              month,
              posts: [post],
            });
          }
        } else {
          groupedPosts.push({
            year,
            postsByMonth: [
              {
                month,
                posts: [post],
              },
            ],
          });
        }
      });
      groupedPosts.forEach((group) => {
        group.postsByMonth.sort((a, b) => b.month - a.month);
      });
      setPostByYear(groupedPosts);
    }
  };

  useEffect(() => {
    if (posts && posts.length > 0) {
      groupPostsByYearAndMonth();
    }
  }, [posts]);


  if (state.state === 'loading') {
    return <div>Loading...</div>
  }

  return (
    <>
      {postByYear?.map((postByYear) => (
        <div key={postByYear.year}>
          <h3 style={{marginBottom: '8px'}}>{postByYear.year}</h3>
          {postByYear.postsByMonth.map((postByMonth) => (
            <PostArchivedItem 
              key={postByMonth.month}
              month={postByMonth.month}
              postsLenght={postByMonth.posts.length}
            />
          ))}
        </div>
      ))}
    </>
  )
}

export default PostArchivedList