export type SelectLanguageProps = {
  handleMenuVisible: () => void;
};

export type LanguageProps = {
  label: LanguageLabels;
  value: LanguageCodes
};

export enum LanguageCodes {
  EN = 'en',
  ES = 'es'
}

export enum LanguageLabels {
  EN = 'EN',
  ES = 'ES'
}