import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { backgroundDarkColor, Spacing } from "theme";

export const NewsLetterWrapper = styled.section.attrs((prps)=>({
    className: 'newsletter-wrapper'
}))`
    background: ${backgroundDarkColor};
    padding: 159px 0;

    @media (max-width: ${Breakpoints.lg}) {
        padding: ${Spacing.xLargeSpacing} 0;
    }
`;

export const NewsLetterHolder = styled.div.attrs((prps)=>({
    className: 'newsletter-holder'
}))`
    display: flex;
    align-items: center;
    gap: 150px;

    @media (max-width: ${Breakpoints.lg}){
        flex-direction: column;
        gap: 30px;
    }
`;

export const NewsLetterContentWrapper = styled.div.attrs((prps)=>({
    className: 'newsletter-content-wrapper'
}))`
    flex: 1;
`;

export const NewsLetterFormWrapper = styled.div.attrs((prps)=>({
    className: 'newsletter-form-wrapper'
}))`
    flex: 1;
`;

export const NewsLetterInputWrapper = styled.div.attrs((prps)=>({
    className: 'newsletter-input-wrapper'
}))`
    display: flex;
    align-items: center;

    @media (max-width: ${Breakpoints.lg}) {
        display: block;
    }
`;

export const NewsLetterChecktWrapper = styled.div.attrs((prps)=>({
    className: 'newsletter-check-wrapper'
}))`
    margin-top: ${Spacing.baseSpacing};
`;

