import React from "react";
import { SectionTitle} from "atoms/text/Text";
import { List } from "../parquesEolicos/ParquesEolicos.components";
import { useTranslation } from "react-i18next";
import ServiceTitle from "molecules/serviceTitle/ServiceTitle";
import { EdificiosIcon } from "src/icons";

const Infraestructura: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ServiceTitle
        caption={t('electricalInfrastructure.subHeader.caption')}
        title={t('electricalInfrastructure.subHeader.title1')}
        higlight={t('electricalInfrastructure.subHeader.title2')}
        hasBreakLine={false}
      />
      <EdificiosIcon width={49} height={49} />
      <SectionTitle>{t('electricalInfrastructure.infrastructure.title')}</SectionTitle>
      <List>
        <li>
          {t('electricalInfrastructure.infrastructure.list1.item1')}
        </li>
        <li>
          {t('electricalInfrastructure.infrastructure.list1.item2')}
        </li>
        <li>
          {t('electricalInfrastructure.infrastructure.list1.item3')}
        </li>
        <li>
          {t('electricalInfrastructure.infrastructure.list1.item4')}
        </li>
        <li>
          {t('electricalInfrastructure.infrastructure.list1.item5')}
        </li>
        <li>
          {t('electricalInfrastructure.infrastructure.list1.item6')}
        </li>
      </List>
      <List>
        <li>
          {t('electricalInfrastructure.infrastructure.list2.item1')}
        </li>
      </List>
    </>
  );
};

export default Infraestructura;
