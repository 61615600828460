import React, { useContext, useEffect, useState } from 'react';
import PostItem from 'molecules/postItem/PostItem';
import { PostDetailBottomCategoryHolder, PostDetailBottomCategoryWrapper, PostDetailBottomFunctionsHolder, PostDetailBottomHolder, PostDetailBottomTagsWrapper, PostDetailBottomWrapper, PostDetailBreadcumWrapper, PostDetailWrapper } from './PostDetail.components';
import { usePrismicDocumentByUID } from '@prismicio/react';
import { useNavigate, useParams } from 'react-router-dom';
import { SText } from 'atoms/text/Text';
import Loader from 'atoms/loader/Loader';
import { Spacing, primaryColor, secondaryColor } from 'theme';
import TagItem from 'atoms/tagItem/TagItem';
import Post from 'screens/post/Post';
import { LinkSimple } from 'phosphor-react';
import Swal from 'sweetalert2';
import { SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import { RichText } from 'prismic-reactjs';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { AlternateLanguageProps } from './PostDetail.types';
import * as prismic from '@prismicio/client';
import { set } from 'date-fns';
import { t } from 'i18next';

const PostDetail: React.FC = () => {
  const params = useParams();
  const {i18n} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const [uidPost, setUidPost] = useState<string | undefined>(params.uidPost);
  const [alternateLanguages, setAlternateLanguages] = useState<AlternateLanguageProps[]>([]);
  const [post, setPost] = useState<any | undefined>();
  const navigate = useNavigate();
  const {handleSearchCategoryId} = useContext(SearchPostContext);
  
  const [documentPrismic, {state}] = usePrismicDocumentByUID('post', uidPost!, {
    lang: getPrismicLanguage(languageCode),
    fetchLinks: ['post_author.name', 'post_category.name', 'post_tag.name']
  });

  useEffect(()=>{
    if (alternateLanguages && alternateLanguages.length > 0) {
      const prismicLanguage = getPrismicLanguage(languageCode);
      console.log('prismicLanguage =>', prismicLanguage);
      const uidPostAlternateLanguage = alternateLanguages.find((alternateLanguage) => alternateLanguage.lang === prismicLanguage)?.uid;
      if (uidPostAlternateLanguage) {
        console.log('uidPostAlternateLanguage =>', uidPostAlternateLanguage);
        setUidPost(uidPostAlternateLanguage);
      }
    } else {
      console.log('uidPost =>', params.uidPost);
      console.log('documentPrismic =>', documentPrismic);
      setUidPost(params.uidPost);
    }
  },[languageCode, alternateLanguages]);
  
  useEffect(()=>{
    setAlternateLanguages(documentPrismic?.alternate_languages);
    setPost(documentPrismic?.data);
  },[documentPrismic]);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(window.location.href);
    Swal.fire({
      text: t('blog.linkCopySuccess'),
      icon: 'success',
    });
  };

  const handleCategorySelected = ()=> {
    handleSearchCategoryId && handleSearchCategoryId(post?.category.id, post?.category.data.name);
    navigate('/Blog');
  }

  const navToBlog = () => {
    handleSearchCategoryId && handleSearchCategoryId('', '');
    navigate('/Blog');
  };

  return (
    <>
    {post ? (
      <>
        <PostDetailBreadcumWrapper>
        <SText fontWeight='bold' color={primaryColor}>
          <span onClick={navToBlog}>Blog {'>'}</span> <span onClick={handleCategorySelected}>{post?.category.data.name}</span> {'>'} <span>{RichText.asText(post?.title)}</span>
        </SText>
      </PostDetailBreadcumWrapper>
      <PostDetailWrapper>
        <PostItem
          isHomeItem={false}
          key={post?.id} 
          topImage={post?.top_image.url}
          category={post?.category.data.name}
          showCategoryImage={false}
          createdAt={post?.posted_on}
          author={post?.author.data.name}
          title={post?.title}
          image={post.image_content.url}
          contentTop={post?.content_top}
          quote={post.quote}
          content={post.content}
        />
        <PostDetailBottomWrapper>
          <PostDetailBottomHolder>
            <PostDetailBottomCategoryWrapper
              style={{padding: `${Spacing.smallSpacing} 0`}}
            >
              <PostDetailBottomCategoryHolder>
                <SText
                  onClick={handleCategorySelected}
                  marginBottom='0'
                >
                  {`${post?.category?.data?.name}`}
                </SText>
              </PostDetailBottomCategoryHolder>
              <PostDetailBottomFunctionsHolder>
                <LinkSimple onClick={handleCopyClick} size={24} />
              </PostDetailBottomFunctionsHolder>
              {/* <SText
                marginBottom='0'
              >
                {`${post?.created_at}`}
              </SText> */}
            </PostDetailBottomCategoryWrapper>
            
          </PostDetailBottomHolder>
        </PostDetailBottomWrapper>
        <PostDetailBottomWrapper>
          <PostDetailBottomHolder>
            <PostDetailBottomTagsWrapper
              style={{padding: `${Spacing.xLargeSpacing} 0`}}
            >
              {
                post?.post_tags?.map((postTag: any)=>{
                  return(
                    <div
                      key={postTag.tag.id}
                    >
                      {
                        postTag.tag.data &&
                          <TagItem 
                            key={postTag.tag.id}
                            tagId={postTag.tag.id}
                            tag={postTag.tag.data.name} 
                          />
                      }
                    </div>
                  )
                })
              }
            </PostDetailBottomTagsWrapper>
          </PostDetailBottomHolder>
        </PostDetailBottomWrapper>
      </PostDetailWrapper>
      </>
    ) : 
    <PostDetailWrapper
      style={{padding: `${Spacing.xLargeSpacing}`}}
    >
      <SText
        color={secondaryColor}
        fontWeight='bold'
      >
        {t('blog.canNotFoundContent')}
      </SText>
    </PostDetailWrapper>
    }
    {
      state === 'loading' &&
      <Loader />
    }
    </>
  )
}

export default PostDetail