import React from 'react';
import Clients from 'molecules/clients/Clients';
import MoreAboutReyco from 'molecules/moreAboutReyco/MoreAboutReyco';
import MoveWorld from 'molecules/moveWorld/MoveWorld';
import ValueAdded from 'molecules/valueAdded/ValueAdded';
import WhatWeDo from 'molecules/whatWeDo/WhatWeDo';
import HomeSlider from 'organisms/homeSlider/HomeSlider';
import OurServices from 'templates/ourServices/OurServices';
import NewsLetter from 'organisms/newsLetter/NewsLetter';
import LastPostsCarousel from 'organisms/lastPostsCarousel/LastPostsCarousel';
import BuildingFuture from 'molecules/buildingFuture/BuildingFuture';

const Home: React.FC = () => {
  return (
    <>
      <HomeSlider />
      <BuildingFuture />
      <WhatWeDo />
      <OurServices />
      <MoveWorld />
      {/* <SquareCtas /> */}
      <ValueAdded />
      <Clients />
      <MoreAboutReyco />
      <NewsLetter />
      <LastPostsCarousel />
    </>
  )
}

export default Home