import { SText } from 'atoms/text/Text';
import { imgVacanteTecnicoMantenimiento } from 'images';
import SubsectionTitle from 'molecules/subsectionTitle/SubsectionTitle';
import VacancyItem from 'molecules/vacancyItem/VacancyItem';
import React from 'react';
import { Container, Content } from 'src/design/containers/Container';
import { VacanciesContentWrapper, MainVacancyHolder, VacanciesWrapper, VacanciesHolderSwiper } from './Vacancies.components';
import SButton from 'atoms/button/Button';
import { useNavigate } from 'react-router-dom';
import { PATH_NAMES } from 'organisms/mainMenu/MainMenu.types';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import { useVanciesController } from './Vacancies.controller';
import { ButtonProps } from 'atoms/button/Button.type';
import { Autoplay } from 'swiper/modules';

const Vacancies: React.FC = () => {
  const {t} = useTranslation();
  const {
    openVacancies,
    captionColor,
    titleColor,
    textColor,
    buttonVariant,
    buttonWidth,
    isShowMainVacancy,
  } = useVanciesController();
  const navigate = useNavigate();

  return (
    <VacanciesWrapper>
      <Container style={{position: 'relative'}}>
        <Content>
          <MainVacancyHolder>
            <VacanciesContentWrapper>
              <SubsectionTitle 
                caption={t('careers.vacancies.caption')}
                captionColor={captionColor}
                titleA={t('careers.vacancies.title1')}
                titleB={t('careers.vacancies.title2')}
                titleColorA={titleColor}
                titleColorB={titleColor}
              />
              <SText
                color={textColor}
              >
                {t('careers.vacancies.paragraph1')}
              </SText>
              <SButton
                style={{width: buttonWidth}}
                title='Aplicar'
                onClick={()=>{
                  navigate(PATH_NAMES.CONTACT);
                }}
                variant={buttonVariant as ButtonProps['variant']}
              >
                {t('careers.vacancies.buttonText')}
              </SButton>
            </VacanciesContentWrapper>
            {isShowMainVacancy ? (
              <VacanciesContentWrapper>
              <VacancyItem 
                img={imgVacanteTecnicoMantenimiento}
                vacancy={t('careers.vacancies.vacancy1')}
              />
            </VacanciesContentWrapper>
            ) : null}
          </MainVacancyHolder>
        </Content>
        
        <VacanciesHolderSwiper>  
          <Swiper
            autoplay
            loop
            spaceBetween={16}
            modules={[Autoplay]}
            breakpoints={{
              320: {
                slidesPerView: 'auto',
                centeredSlides: true,
              },
              768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
              },
              1280: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
            }}
          >
          {
            openVacancies.map(({img, vacancy})=>{
              return(
                <SwiperSlide>
                  <VacancyItem 
                    key={vacancy}
                    img={img}
                    vacancy={vacancy}
                  />  
                </SwiperSlide>
              )
            })
          }
          </Swiper>
        </VacanciesHolderSwiper>

        {/* <VacanciesHolder>
          {
            openVacancies.map(({img, vacancy})=>{
              return(
                <VacancyItem 
                  key={vacancy}
                  img={img}
                  vacancy={vacancy}
                />  
              )
            })
          }
        </VacanciesHolder> */}
      </Container>
    </VacanciesWrapper>
  )
}

export default Vacancies