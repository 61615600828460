import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { secondaryColor } from "theme";
import { List } from "./ParquesEolicos.components";
import { useTranslation } from "react-i18next";


const Supervision: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <SectionTitle>{t('windFarms.supervision.title')}</SectionTitle>
      <SText>
        {t('windFarms.supervision.paragraph1')}
      </SText>
      <SText color={secondaryColor} marginBottom="0">
        {t('windFarms.supervision.list1.title')}
      </SText>
      <List>
        <li>
          {t('windFarms.supervision.list1.item1')}
        </li>
        <li>
          {t('windFarms.supervision.list1.item2')}
        </li>
        <li>
          {t('windFarms.supervision.list1.item3')}
        </li>
      </List>
      <SText color={secondaryColor} marginBottom="0">
        {t('windFarms.supervision.list2.title')}
      </SText>
      <List>
        <li>
          {t('windFarms.supervision.list2.item1')}
        </li>
        <li>
          {t('windFarms.supervision.list2.item2')}
        </li>
        <li>
          {t('windFarms.supervision.list2.item3')}
        </li>
        <li>
          {t('windFarms.supervision.list2.item4')}
        </li>
        <li>
          {t('windFarms.supervision.list2.item5')}
        </li>
      </List>
    </>
  );
};

export default Supervision;
