import styled from "styled-components";
import { Spacing, blackColor, whiteColor } from "theme";

export type InputProps = {
  isMobile?: boolean;
};

const Input = styled.input.attrs(props => ({
  placeholder: props.placeholder,
  type: props.type,
}))<InputProps>`
  background-color: transparent;
  border: 1px ${({isMobile}) => isMobile ? blackColor : whiteColor} solid;
  padding: 16px;
  font-size: 16px;
  width: 100%;
  height: 52px;
  margin-bottom: ${Spacing.baseSpacing};
  color: ${({isMobile}) => isMobile ? blackColor : whiteColor};
  
  ::placeholder {
    color: ${({isMobile}) => isMobile ? blackColor : whiteColor};
  }
`;

export default Input;

