import React from 'react'
import { InputProps } from "atoms/input/Input";
import styled from "styled-components";
import { Spacing, blackColor, whiteColor } from "theme";
import ReactSelect, { ActionMetaBase, SingleValue } from 'react-select';
import { getContainerStyles } from './Select.styles';


  // background-color: transparent;
  // border: 1px ${({isMobile}) => isMobile ? blackColor : whiteColor} solid;
  // color: ${({isMobile}) => isMobile ? blackColor : whiteColor};
  // font-size: 16px;
  // height: 52px;
  // margin-bottom: ${Spacing.baseSpacing};
  // outline: none;
  // padding-left: 16px;
  // width: 100%;

export type SelectProps = {
  options: SelectOption[];
  onChange: ((newValue: SingleValue<SelectOption>, actionMeta: ActionMetaBase<SelectOption>) => void) | undefined;
  isMobile?: boolean;
  placeholder?: string;
  defaultInputValue?: SelectOption;
  isLanguageSelect?: boolean;
};

export type SelectOption = {
  value: string;
  label: string;
};

const Select: React.FC<SelectProps> = ({options, onChange, isMobile, placeholder, defaultInputValue, isLanguageSelect}) => {
  return (
    <ReactSelect
      defaultValue={defaultInputValue}
      styles={getContainerStyles(isMobile, isLanguageSelect)}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable={false}
    />
  )
}

export default Select

