import React from 'react';
import { SectionTitle, SText } from 'atoms/text/Text';
import { imgInfraestructuraElectrica, imgPanelesSolares, imgSectorEolico, imgTorresMeteorologicas } from 'images';
import ServiceItem from 'molecules/serviceItem/ServiceItem';
import { Content } from 'src/design/containers/Container';
import { ColorBlackText } from 'theme';
import { OurServicesContentHolder, OurServicesTitlesWrapper, OurServicesHolder, OurServicesWrapper, OurServicesTitlesHolder } from './OurServices.components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';
import { PATH_NAMES } from 'organisms/mainMenu/MainMenu.types';
import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper/modules';

const OurServices: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <OurServicesWrapper>
      <OurServicesHolder>
        <Content className='title'>  
          <OurServicesTitlesWrapper>
            <OurServicesTitlesHolder>
              <SectionTitle
                marginBottom='45px'
                fontWeight='bold'
              >
                {t('home.ourServices.title')}
              </SectionTitle>
            </OurServicesTitlesHolder>
          </OurServicesTitlesWrapper>
        </Content>
        <Content className='text'>
          <OurServicesContentHolder>
              <SText
                color={ColorBlackText}
              >
                {t('home.ourServices.paragraph')}
              </SText>
            </OurServicesContentHolder>
        </Content>
        <Swiper
          autoplay
          modules={[Autoplay]}
          style={{width: '100%'}}
          loop
          spaceBetween={16}
          breakpoints={{
            320: {
              slidesPerView: 'auto',
              centeredSlides: true,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1280: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
        >
          <SwiperSlide>
            <ServiceItem
              onClick={()=>{navigate(PATH_NAMES.WIND_FARMS)}}
              service={t('home.ourServices.services.windFarms')}
              image={imgSectorEolico}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceItem 
              onClick={()=>{navigate(PATH_NAMES.SOLAR_PARKS)}}
              service={t('home.ourServices.services.solarFarms')}
              image={imgPanelesSolares}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceItem 
              onClick={()=>{navigate(PATH_NAMES.ELECTRICAL_INFRASTRUCTURE)}}
              service={t('home.ourServices.services.electricalInfrastructure')}
              image={imgInfraestructuraElectrica}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceItem 
              onClick={()=>{navigate(PATH_NAMES.METEOROLOGICAL_TOWERS)}}
              service={t('home.ourServices.services.meteorologicalTowers')}
              image={imgTorresMeteorologicas}
            />
          </SwiperSlide>
          {/* <SwiperSlide>
            <ServiceItem 
              service='Energías renovables'
              image={imgEnergiasRenovables}
            />
          </SwiperSlide>
          <SwiperSlide>
            <ServiceItem 
              service='Meteorología renovable'
              image={imgEstacionMeteorologica}
            />
          </SwiperSlide> */}
        </Swiper>
      </OurServicesHolder>
    </OurServicesWrapper>
  )
}

export default OurServices