import styled from "styled-components";
import { secondaryColor, Spacing } from "theme";

export const QuoteWrapper = styled.div.attrs((props)=>({
    className: 'quote-wrapper'
}))`
    padding: ${Spacing.smallSpacing};
    border-left: solid 7px ${secondaryColor};
    p{
        font-size: 22px;
        font-weight: bold;
        font-family: 'Archivo', sans-serif;
    }
`;