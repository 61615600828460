import styled from "styled-components";
import { blackColor, secondaryColor, Spacing } from "theme";

type PaginationPostsItemProps = {
    active: boolean;
};

export const PaginationPostsWrapper = styled.div.attrs((props)=>({
    className: 'pagination-post-wrapper'
}))`
    padding-bottom: 145px;
`;

export const PaginationPostsItemsWrapper = styled.div.attrs((props)=>({
    className: 'pagination-post-items-wrapper'
}))`
    display: flex;
    gap: ${Spacing.baseSpacing};
    justify-content: center;
`;

export const PaginationPostsItem = styled.span.attrs((props)=>({
    className: 'pagination-post-item'
}))<PaginationPostsItemProps>`
    background: 'transparent';
    color: ${props => props.active && secondaryColor};
    display: block;
    width: 50px;
    height: 40px;
    border: solid 1px ${props => props.active ? secondaryColor : blackColor};
    text-align: center;
    line-height: 40px;
    cursor: pointer;
`;

export const PaginationNavItem = styled.span.attrs((props)=>({
    className: 'pagination-nav-item'
}))`
    display: block;
    height: 40px;
    width: 129px;
    border: solid 1px ${blackColor};
    text-align: center;
    line-height: 40px;
    padding: 0 ${Spacing.smallSpacing};
    cursor: pointer;
`;