import React, { MouseEvent, useEffect, useState } from "react";
import { Container } from "src/design/containers/Container";
import {
  CloseIcon,
  FalseLink,
  Link,
  LogoWrapper,
  MainMenuWrapper,
  MainMenuWrapperFixed,
  MainMenuWrapperRelative,
  MenuLine,
  MenuLink,
  MenuMobile,
  MenuMobileWrapper,
  MenuWrapper,
  SCaretDown,
  SCaretUp,
  SelectLanguageWrapper,
  SubMenuLink,
  SubMenuWrapper,
} from "./MainMenu.components";
import Logo from "atoms/logo/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import { activeLink, link } from "./mainMenu.styles";
import { Spacing, secondaryColor, whiteColor } from "theme";
import "./main-menu.css"
import { isMobileDevice } from "utils/isMobileDevice";
import SelectLanguage from "molecules/selectLanguage/SelectLanguage";
import { useMainMenuController } from "./MainMenu.controller";
import { LABELS_MAIN_MENU, PATH_NAMES } from "./MainMenu.types";

const MainMenu: React.FC = () => {
  const {mainMenuOptions} = useMainMenuController();
  const [menuMobileVisible, setMenuMobileVisible] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState<LABELS_MAIN_MENU | string |  undefined>(undefined);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobile = isMobileDevice();

  useEffect(() => {
    if(window.innerWidth > 991){
      $(".has-submenu").on("mouseover", function (this) {
        $(this).children(".submenu-wrapper").show();
      });
      $(".has-submenu").on("mouseleave", function (this) {
        $(this).children(".submenu-wrapper").hide();
      });
    }
  }, []);

  useEffect(() => {
    setMenuMobileVisible(false);
  }, [navigate]);

  const handleMenuVisible = () => {
    setMenuMobileVisible(!menuMobileVisible);
  };

  useEffect(()=>{
    if(menuMobileVisible && window.innerWidth < 991){
      $(".menu-wrapper").animate({"right": "0%"});
    }
    if(!menuMobileVisible && window.innerWidth < 991){
      $(".menu-wrapper").animate({"right": "-100%"});
    }
  },[menuMobileVisible]);

  useEffect(() => {
    if (
      pathname === PATH_NAMES.ABOUT_US ||
      pathname === PATH_NAMES.CERTIFICATIONS
    ) {
      $(".false-link.Acerca.de").css("color", secondaryColor);
      $(".false-link.Servicios").css("color", whiteColor);
      $(".false-link.About").css("color", secondaryColor);
      $(".false-link.Services").css("color", whiteColor);
    }
    if (
      pathname === PATH_NAMES.WIND_FARMS ||
      pathname === PATH_NAMES.SOLAR_PARKS ||
      pathname === PATH_NAMES.ELECTRICAL_INFRASTRUCTURE ||
      pathname === PATH_NAMES.METEOROLOGICAL_TOWERS
    ) {
      $(".false-link.Servicios").css("color", secondaryColor);
      $(".false-link.Acerca.de").css("color", whiteColor);
      $(".false-link.Services").css("color", secondaryColor);
      $(".false-link.About").css("color", whiteColor);
    }
    if (
      pathname === PATH_NAMES.PROJECTS ||
      pathname === PATH_NAMES.CAREERS ||
      pathname === PATH_NAMES.CONTACT ||
      pathname === PATH_NAMES.BLOG ||
      pathname === PATH_NAMES.ROOT
    ) {
      $(".false-link.Acerca.de").css("color", whiteColor);
      $(".false-link.Servicios").css("color", whiteColor);
      $(".false-link.About").css("color", whiteColor);
      $(".false-link.Services").css("color", whiteColor);
    }
  }, [pathname]);

  const hanldeLinkClick = (e: MouseEvent, submenuName: LABELS_MAIN_MENU | string)=> {
    submenuOpen === submenuName ? setSubmenuOpen(undefined) : setSubmenuOpen(submenuName);
  }

  return (
    <MainMenuWrapperRelative>
      <MainMenuWrapperFixed>
        <Container style={{padding: isMobile ? `0 ${Spacing.smallSpacing}` : undefined}}>
          <MainMenuWrapper>
            <LogoWrapper>
              <Logo
                onClick={() => {
                  navigate("/");
                }}
                alt="Reyco Renovables"
              />
              <MenuMobileWrapper>
                <MenuMobile
                  onClick={handleMenuVisible}
                  className="menu-mobile"
                  color={whiteColor}
                  size={32}
                />
              </MenuMobileWrapper>
            </LogoWrapper>
              <MenuWrapper
                style={
                  menuMobileVisible ? { display: "inline-block" } : undefined
                }
              >
                <div style={{display: "flex", justifyContent: "end"}}>
                  <CloseIcon
                    onClick={handleMenuVisible}
                    className="menu-mobile"
                    color={secondaryColor}
                    size={32}
                  />
                </div>
                {mainMenuOptions.map((item, index) => {
                  return (
                    <MenuLink
                      key={`${index}-${item.text}`}
                      onClick={(e) => hanldeLinkClick(e, item.text)}
                      className={`${
                        item.submenu ? "menu-link has-submenu" : "menu-link"
                      }`}
                    >
                      {item.path ? (
                        <Link
                          to={item.path}
                          style={({ isActive }) => (isActive ? activeLink : link)}
                        >
                          {item.text}
                        </Link>
                      ) : (
                        <FalseLink className={`false-link ${item.text}`}>
                          {item.text}
                        </FalseLink>
                      )}
                      {item.submenu && (
                        <SubMenuWrapper style={{display: item.text === submenuOpen ? 'block' : 'none'}}>
                          {item.submenu.map((submenu, index) => {
                            return (
                              <SubMenuLink key={`${index}-${submenu.text}`}>
                                <Link
                                  style={({ isActive }) =>
                                    isActive ? activeLink : link
                                  }
                                  to={submenu.path}
                                >
                                  {submenu.text}
                                </Link>
                              </SubMenuLink>
                            );
                          })}
                        </SubMenuWrapper>
                      )}
                      {
                        item.submenu && window.innerWidth < 991 ? 
                          item.text === submenuOpen ? (<SCaretUp
                          className="icon-caret-down"
                          style={{position: "absolute", right: "16px", top:0}}
                          color={whiteColor}
                          size={24}
                        />) : (<SCaretDown
                          className="icon-caret-up"
                          style={{position: "absolute", right: "16px", top:0}}
                          color={whiteColor}
                          size={24}
                        />) : null
                      }
                    </MenuLink>
                  );
                })}
                <SelectLanguageWrapper>
                  <SelectLanguage handleMenuVisible={handleMenuVisible} />
                </SelectLanguageWrapper>
                <MenuLine />
              </MenuWrapper>
          </MainMenuWrapper>
        </Container>
      </MainMenuWrapperFixed>
    </MainMenuWrapperRelative>
  );
};

export default MainMenu;
