import styled from "styled-components";
import { secondaryColor } from "theme";

export const PostArchivedItemWrapper = styled.div.attrs((props)=>({
    className: 'post-archived-item-wrapper'
}))``;

export const PostArchivedItemHolder = styled.div.attrs((props)=>({
    className: 'post-archived-item-holder'
}))`
    display: flex;

    & p {
        margin-bottom: 8px;
    }

    &:hover p {
        color: ${secondaryColor};
    }
`;

export const DateItemWrapper = styled.div.attrs((props)=>({
    className: 'date-item-wrapper'
}))`
    flex: 1;
    cursor: pointer;

    p {
        text-transform: capitalize;
    }
`;

export const CountPostsWrapper = styled.div.attrs((props)=>({
    className: 'count-posts-wrapper'
}))``;