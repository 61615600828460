import styled from "styled-components";
import { secondaryColor } from "theme";

export const SocialIconWrapper = styled.div.attrs((props)=>({
    className: 'social-icon-wrapper'
}))`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  &:hover svg {
    fill: ${secondaryColor};
    color: ${secondaryColor};
  }
`;

export const Icon = styled.img.attrs(()=>({
    className: 'social-icon'
}))`
  cursor: pointer;
  height: auto;
  width: 22px;
  object-fit: contain;
`;