import styled from "styled-components";
import { ContainerType } from "custom-types/design/containers/Containers";
import { Spacing } from "theme";
import { Breakpoints } from "src/theme/breakpoints";

export const FullContainer = styled.div.attrs<{className: string}>((props) => ({
  className: 'full-container',
  style: props.style,
}))<ContainerType>`
  margin: 0 auto;
  background-color: ${props => props.backgroundColor || 'transparent'};
  height: ${props => props.height || 'auto'};
  width: 100%;
`;

export const ContainerMax = styled.div.attrs<{className: string}>((props)=>({
  className: 'container-max',
  style: props.style,
}))<ContainerType>`
  margin: 0 auto;
  background-color: ${props => props.backgroundColor || 'transparent'};
  height: ${props => props.height || 'auto'};
  padding: 0 ${Spacing.smallSpacing};
  max-width: 1690px;
`;

export const Container = styled.div.attrs<{className: string}>((props)=>({
  className: 'container',
  style: props.style,
}))<ContainerType>`
  margin: 0 auto;
  background-color: ${props => props.backgroundColor || 'transparent'};
  height: ${props => props.height || 'auto'};
  padding: 0 ${Spacing.smallSpacing};
  max-width: 1888px;
  
  @media (max-width: ${Breakpoints.lg}) {
    padding: 0;
  }
`;

export const Content = styled.div.attrs<{className: string}>((props)=>({
  className: 'content',
  style: props.style,
}))<ContainerType>`
  background-color: ${props => props.backgroundColor || 'transparent'};
  height: ${props => props.height || 'auto'};
  padding: 0;

  @media (max-width: ${Breakpoints.lg}) {
    padding: 0 ${Spacing.baseSpacing};
  }
`;