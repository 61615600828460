import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

type ImageContentWrapperProps = {
  backgroundColorSection?: string;
};

type ImageContentImgWrapperProps = {
  imagePlace: string;
  isSwitchMobile?: boolean;
};

export const ImageContentWrapper = styled.section.attrs((props)=>({
  className: 'image-content-wrapper'
}))<ImageContentWrapperProps>`
  padding: 90px 0;
  background-color: ${props => props.backgroundColorSection || 'transparent'};

  @media (max-width: ${Breakpoints.lg}) {
    padding: 0 0 ${Spacing.xLargeSpacing};
  }
`;

export const ImageContentHolder = styled.div.attrs((props)=>({
  className: 'image-content-holder'
}))<{isFlexReverse?: boolean}>`
  display: flex;
  align-items: stretch;
  gap: 8%;

  @media (max-width: ${Breakpoints.lg}){
    flex-direction: ${props => props.isFlexReverse ? 'column-reverse' : 'column'};
  }
`;

export const ImageContentImgWrapper = styled.div.attrs((props)=>({
  className: 'image-content-img-wrapper'
}))<ImageContentImgWrapperProps>`
  flex: 1;
  text-align: center;

  @media (max-width: ${Breakpoints.lg}){
    text-align: center;
    display: ${props => props.isSwitchMobile ? 'none' : 'block'};
  }
`;

export const ImageContentImg = styled.img.attrs<{imagePlace: 'left' | 'right'}>((props)=>({
  className: `image-content-img-${props.imagePlace}`
}))<ImageContentImgWrapperProps>`
  width: 100%;
  height: auto;
  position: relative;
  object-fit: cover;

  @media (max-width: ${Breakpoints.lg}){
    height: auto !important;
  }
`;

export const ContentWrapper = styled.div.attrs((props)=>({
  className: 'content-wrapper'
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

export const TitleWrapper = styled.div.attrs((props)=>({
  className: 'title-wrapper'
}))`
`;