import React from "react";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import ContactForm from "organisms/contactForm/ContactForm";
import {
  contactHeaderImg,
  imgContactHeaderMobile,
  imgContactoHeader,
  imgInstalacionPanelSolar,
} from "images";
import Map from "organisms/map/Map";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import ImageWithContent from "molecules/imageWidthContent/ImageWithContent";
import { backgroundDarkColor } from "theme";
import OurHeadquarters from "organisms/ourHeadquarters/OurHeadquarters";
import { isMobileDevice } from "utils/isMobileDevice";
import { whiteColor } from '../../theme/colors';
import { useTranslation } from "react-i18next";

const Contact: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <>
      <ScreenHeader
        image={isMobile ? imgContactHeaderMobile : contactHeaderImg}
        title={t("contact.header.title")}
        subtitle={t("contact.header.subtitle")}
        sectionName={SectionName.CONTACTO}
      />
      <ImageWithContent
        imageId="contact-form"
        placeImage="left"
        backgroundColorContent={isMobile ? whiteColor : backgroundDarkColor}
        image={isMobile ? undefined :imgInstalacionPanelSolar}
        title={<></>}
        content={<ContactForm />}
      />
      <OurHeadquarters />
      <Map />
    </>
  );
};

export default Contact;
