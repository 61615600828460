import styled from "styled-components";
import { ColorWhite, Spacing } from "theme";

const InputSearchText = styled.input.attrs((props)=>({
  className: 'input-search-text',
  placeholder: props.placeholder,
}))`
  background: ${ColorWhite};
  border: 0;
  height: 51px;
  padding-left: ${Spacing.smallSpacing};
  width: 100%;
  outline: none;
  padding-left: ${Spacing.xLargeSpacing};
`;

export default InputSearchText;