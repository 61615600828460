import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { backgroundDarkColor, Spacing } from "theme";

export const VacancyItemWrapper = styled.div.attrs((props)=>({
    className: 'vacancy-item-wrapper'
}))`
    width: 100%;
    height: 379px;
    position: relative;
    margin: 0 auto;

    @media (max-width: ${Breakpoints.lg}){
        width: 280px;
        margin-bottom: ${Spacing.xLargeSpacing};
    }
`;

export const VacancyItemHolder = styled.div.attrs((props)=>({
    className: 'vacancy-item-holder'
}))`
    width: 280px;
    position: relative;
    margin: 0 auto;

    @media (max-width: ${Breakpoints.lg}){
        margin-bottom: ${Spacing.xLargeSpacing};
    }
`;

export const VacancyItemImageWrapper = styled.div.attrs((props)=>({
    className: 'vacancy-item-image-wrapper'
}))`
    width: 100%;
    height: 100%;
`;

export const VacancyItemImage = styled.img.attrs((props)=>({
    className: 'vacancy-item-image'
}))`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const VacancyItemTextWrapper = styled.div.attrs((props)=>({
    className: 'vacancy-item-text-wrapper'
}))`
    background: ${backgroundDarkColor};
    width: 100%;
    height: 93px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        width: 70%;
        margin-bottom: 0;
        text-align: center;
    }
`;