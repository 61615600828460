import React from 'react';
import { imgBrochure, imgBrochureHeader, imgBrochureSpanish, imgBrochureEnglish, imgBrochureSpanishMobile } from 'images';
import ScreenHeader from 'molecules/screenHeader/ScreenHeader';
import { BrochureHolder, BrochureWrapper } from './Brochure.components';
import { Container } from 'src/design/containers/Container';
import BrochureItem from 'molecules/brochureItem/BrochureItem';
import { SectionName } from 'custom-types/design/molecules/ScreenHeader';
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { isMobileDevice } from 'utils/isMobileDevice';

const Brochure: React.FC = () => {
  const {i18n, t} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const isMobile = isMobileDevice();

  const handleNavBrochure = ()=> {
    switch (languageCode) {
      case LanguageCodes.ES:
        window.open(process.env.REACT_APP_LINK_REYCO_BROCHURE_ES, '_blank');
        break;

      case LanguageCodes.EN:
        window.open(process.env.REACT_APP_LINK_REYCO_BROCHURE_EN, '_blank');
        break;
    
      default:
        break;
    }
  }

  const getBackGroundImage = (): string => {
    switch (languageCode) {
      case LanguageCodes.ES:
        return imgBrochureSpanish;
      case LanguageCodes.EN:
        return imgBrochureEnglish;
      default:
        return imgBrochureEnglish;
    }
  };

  const getMobileImage = (): string => {
    switch (languageCode) {
      case LanguageCodes.ES:
        return imgBrochureSpanishMobile;
      case LanguageCodes.EN:
        return imgBrochureEnglish;
      default:
        return imgBrochureEnglish;
    }
  };

  return (
    <>
      <ScreenHeader
        image={imgBrochure}
        title='Blog'
        imgTitle={imgBrochureHeader}
        sectionName={SectionName.BROCHURE}
      />
      <BrochureWrapper>
        <Container>  
          <BrochureHolder>
            <BrochureItem 
              imgBackground={getBackGroundImage()}
              title={t('brochure.title')}
              text={t('brochure.paragraph1')}
              textButton={t('brochure.buttonText')}
              onClickButton={handleNavBrochure}
              imageMobile={isMobile ? getMobileImage() : undefined}
            />
          </BrochureHolder>
        </Container>
      </BrochureWrapper>
    </>
  )
}

export default Brochure