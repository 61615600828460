import styled from "styled-components";
import { Breakpoints } from "src/theme/breakpoints";

export const ReycoSwiperWrapper = styled.div.attrs((props)=>({
    className: 'reyco-swipper-wrapper'
}))`
  padding: 350px 0 190px 0;
  
  .swiper-pagination {
    text-align: left;
    z-index: 10000;
    bottom: 30%;
  }

  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 55px;
    height: 55px;
    background-color: transparent;
    border-bottom: solid 6px #D82520;
    border-radius: 0;
  }

  .swiper-pagination-bullet{
    width: 55px;
    height: 55px;
    background-color: transparent;
    border-bottom: solid 3px #707070;
    border-radius: 0;
  }

  @media (max-width: 1240px){
    
    .swiper {
      padding-bottom: 80px;
    }

    .swiper-pagination {
      text-align: left;
      z-index: 10000;
      bottom: 0;
    }
  }

  @media (max-width: ${Breakpoints.lg}){
    
    padding: 100px 0;
  }
`;

export const ReycoSwiperSliderWrapper = styled.div.attrs((props)=>({
    className: 'reyco-swiper-slider-wrapper'
}))`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;

  @media (max-width: 1240px){
    grid-template-columns: 1fr;
  }
`;

export const ReycoSwiperSliderContentWrapper = styled.div.attrs((props)=>({
  className: 'reyco-swiper-slider-content-wrapper'
}))`
  flex: 1;
`;