import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing, secondaryColor } from "theme";

export const DecorationLine = styled.div`
  width: 89px;
  height: 5px;
  background: ${secondaryColor};
  position: absolute;
  left: 0;
  bottom: 0;

  @media (max-width: ${Breakpoints.lg}) {
    left: ${Spacing.xLargeSpacing};
  }
`;