import { ImageServiceType } from 'custom-types/design/atoms/ImageService';
import React from 'react';
import styled from 'styled-components';

const ImageServiceWrapper = styled.div`
  height: 60px;
  width: 60px;
`;

const ImageService = styled.img`
  height: 60px;
  width: 60px;
`;

const SImageService: React.FC<ImageServiceType> = ({image, alt, wrapperStyles, imageStyles}) => {
  return (
    <ImageServiceWrapper style={{...wrapperStyles}}>
      <ImageService style={{...imageStyles}} src={image} alt={alt} />
    </ImageServiceWrapper>
  )
}

export default SImageService