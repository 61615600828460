import { TypePostItem } from "custom-types/design/molecules/PostItem";
import styled from "styled-components";
import { ColorWhite, Spacing } from "theme"; 

type PostItemWrapperProps = {
    type?: TypePostItem;
};

export const PostItemWrapper = styled.div.attrs((props)=>({
    className: 'post-item-wrapper'
}))<PostItemWrapperProps>`
    width: ${props => props.type === TypePostItem.CAROUSEL ? 'auto' : 'auto'};
    background: ${ColorWhite};
    margin-bottom: ${Spacing.xxxxLargeSpacing};
`;

export const PostItemImageWrapper = styled.div.attrs((props)=>({
    className: 'post-item-image-wrapper'
}))<PostItemWrapperProps>`
    position: relative;
    height: ${props => props.type === TypePostItem.CAROUSEL ? '261px' : '399px'};

    .category-label-wrapper{
        position: absolute;
        bottom: 0;
        left: ${props => props.type === TypePostItem.CAROUSEL ? '0' : Spacing.xxLargeSpacing};

        p {
            margin-bottom: 0;
        }
    }
`;

export const PostItemImage = styled.img.attrs((props)=>({
    className: 'post-item-image'
}))`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const PostItemContentWrapper = styled.div.attrs((props)=>({
    className: 'post-item-content-wrapper'
}))`
    padding-bottom: ${Spacing.xxLargeSpacing};
    padding-top: ${Spacing.xxLargeSpacing};
`;

export const PostItemContentHolder = styled.div.attrs((props)=>({
    className: 'post-item-content-holder',
    props
}))`
    padding-left: ${Spacing.xxLargeSpacing};
    padding-right: ${Spacing.xxLargeSpacing};
`;

export const PostItemTitleWrapper = styled.div.attrs((props)=>({
    className: 'post-item-title-wrapper',
}))`
    cursor: pointer;
`;

export const PostItemContentTopHolder = styled.div.attrs((props)=>({
    className: 'post-item-content-top-holder',
    props
}))`
    p {
        font-size: 16px;
        margin-bottom: ${Spacing.baseSpacing};
    }
`;

export const PostItemContentCentralHolder = styled.div.attrs((props)=>({
    className: 'post-item-content-central-holder',
    props
}))`
    p {
        font-size: 16px;
        margin-bottom: ${Spacing.baseSpacing};
    }
`;

export const PostItemImageContentWrapper = styled.div.attrs((props)=>({
    className: 'post-item-image-content-wrapper',
    props
}))`
    margin: ${Spacing.xLargeSpacing} 0;
`;