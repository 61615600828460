import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const WorkingEnergyItemWrapper = styled.div.attrs((props)=>({
  className: 'working-energy-item-wrapper'
}))`
  width: 22%;
  margin-top: 100px;
  opacity: 0;

  @media (max-width: ${Breakpoints.lg}){
    width:100%;
    margin-bottom: ${Spacing.xxLargeSpacing};
  }
`;