import { useTranslation } from "react-i18next";
import { LABELS_MAIN_MENU, PATH_NAMES } from "./MainMenu.types";

export const useMainMenuController = () => {
  const {t} = useTranslation();

  const mainMenuOptions = [
    {
      text: t(`mainMenu.labels.${LABELS_MAIN_MENU.ABOUT}`),
      path: "",
      submenu: [
        {
          text: t(`mainMenu.labels.${LABELS_MAIN_MENU.ABOUT_US}`),
          path: PATH_NAMES.ABOUT_US
        },
        {
          text: t(`mainMenu.labels.${LABELS_MAIN_MENU.CERTIFICATIONS}`),
          path: PATH_NAMES.CERTIFICATIONS
        }
      ]
    },
    {
      text: t(`mainMenu.labels.${LABELS_MAIN_MENU.PROJECTS}`),
      path: PATH_NAMES.PROJECTS,
    },
    {
      text: t(`mainMenu.labels.${LABELS_MAIN_MENU.SERVICES}`),
      path: "",
      submenu: [
        {
          text: t(`mainMenu.labels.${LABELS_MAIN_MENU.WIND_FARMS}`),
          path: PATH_NAMES.WIND_FARMS
        },
        {
          text: t(`mainMenu.labels.${LABELS_MAIN_MENU.SOLAR_PARKS}`),
          path: PATH_NAMES.SOLAR_PARKS
        },
        {
          text: t(`mainMenu.labels.${LABELS_MAIN_MENU.ELECTRICAL_INFRASTRUCTURE}`),
          path: PATH_NAMES.ELECTRICAL_INFRASTRUCTURE
        },
        {
          text: t(`mainMenu.labels.${LABELS_MAIN_MENU.METEOROLOGICAL_TOWERS}`),
          path: PATH_NAMES.METEOROLOGICAL_TOWERS
        }
      ]
    },
    {
      text: t(`mainMenu.labels.${LABELS_MAIN_MENU.CAREERS}`),
      path: PATH_NAMES.CAREERS,
    },
    {
      text: t(`mainMenu.labels.${LABELS_MAIN_MENU.CONTACT}`),
      path: PATH_NAMES.CONTACT
    },
    {
      text: t(`mainMenu.labels.${LABELS_MAIN_MENU.BLOG}`),
      path: PATH_NAMES.BLOG
    },
  ];

  return {
    mainMenuOptions,
  };
};