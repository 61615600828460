import * as React from "react";
import { SVGProps } from "react";
const SvgResiduos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="residuos_svg__Capa_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 170.73 164.94"
    {...props}
  >
    <defs>
      <style>{".residuos_svg__cls-1{fill:#d82620}"}</style>
    </defs>
    <g id="residuos_svg__Capa_1-2">
      <path
        className="residuos_svg__cls-1"
        d="M170.73 162.77c-.8 1.93-2.41 2.17-4.24 2.16-18.78-.03-37.56-.02-56.34-.02H4.8c-.78 0-1.56-.01-2.33-.1-1.4-.17-2.16-1.06-2.4-2.39-.11-.59-.07-1.22-.07-1.83V95.25c0-3.51.78-4.29 4.32-4.29 13 0 26 .01 39.01-.04.8 0 1.8-.3 2.37-.82 8.51-7.82 16.96-15.7 25.43-23.57.2-.19.46-.32.78-.53-.94-.86-1.74-1.53-2.46-2.27-7.29-7.54-4.05-20.25 5.97-23.3 1.14-.35 1.57-.84 1.4-1.96-.04-.27-.04-.56 0-.83.36-2.55-.23-4.19-2.96-5.44-3.53-1.62-5.29-4.99-5.47-8.93-.14-2.94-.08-5.89-.04-8.83.02-2.05 1.04-3.06 3.08-3.13 1.71-.06 3.42-.01 5.37-.01V0h5.72v11.3c1.89 0 3.54-.03 5.19 0 2.32.05 3.32 1.07 3.34 3.4.02 2.61.07 5.23-.01 7.83-.17 5.11-2.6 8.73-7.37 10.67-.87.35-1.19.76-1.17 1.69.06 2.28.04 4.56.01 6.83-.03 2.62-.79 3.42-3.42 3.71-4.01.45-7.11 3.22-7.74 6.91-.68 4 1.1 7.43 4.91 9.34.35-.3.73-.62 1.11-.94 1.76-1.49 3.08-1.54 4.66.11.87.92 1.52.79 2.39.17 2.09-1.5 3.4-3.47 3.7-6.06.33-2.77 1.37-4.02 3.17-3.85 1.94.19 2.77 1.67 2.49 4.46-.4 3.98-2.16 6.83-6.46 10.41.37.4.72.81 1.1 1.19 7.54 7.55 15.1 15.08 22.62 22.65.81.82 1.63 1.14 2.77 1.14 17.61-.03 35.23-.01 52.84-.04 1.8 0 3.32.29 4.1 2.13v69.69ZM5.78 96.74v62.36h96.51V96.74H5.78Zm102.42 62.33h56.76v-62.3H108.2v62.3Zm-4.42-68.19c-.15-.3-.18-.42-.25-.49-7.22-7.23-14.43-14.46-21.66-21.67-1-1-3.71-.91-4.82.11-7.53 6.98-15.06 13.97-22.58 20.96-.3.28-.55.61-.97 1.09h50.28ZM74.03 17.07c0 1.95-.06 3.92.02 5.88.12 2.9 2.44 5.2 5.32 5.39 2.8.19 5.56-1.9 5.88-4.77.24-2.12.05-4.28.05-6.5H74.04Z"
      />
      <path
        className="residuos_svg__cls-1"
        d="M68.32 127.94c0-5.44-.02-10.88.02-16.31 0-.79.1-1.82.59-2.32.62-.63 1.72-1.2 2.53-1.11.81.1 1.69.93 2.21 1.67.39.56.33 1.49.33 2.25.02 10.54.01 21.08.01 31.63 0 .39.02.78-.02 1.16-.19 1.85-1.36 2.96-3.01 2.87-1.58-.09-2.62-1.21-2.63-3.04-.04-4.72-.01-9.43-.02-14.15v-2.66ZM22.74 128.01v15.65c0 .55.02 1.11-.06 1.66-.2 1.45-1.04 2.32-2.5 2.45-1.53.14-2.56-.61-2.94-2.1-.16-.63-.19-1.31-.19-1.97-.01-10.49 0-20.98 0-31.46 0-.39-.02-.78.01-1.16.16-1.87 1.3-3.02 2.92-2.98 1.64.04 2.73 1.19 2.74 3.11.03 5.6.01 11.21.01 16.81ZM91.05 127.92v15.98c0 .55.03 1.13-.09 1.66-.31 1.32-1.16 2.14-2.55 2.23-1.47.09-2.44-.65-2.87-2.02-.16-.52-.16-1.09-.16-1.64v-32.29c0-.44-.01-.89.06-1.33.26-1.5 1.47-2.48 2.92-2.41 1.38.07 2.45 1.07 2.65 2.53.07.49.04 1 .04 1.5v15.81ZM51.24 127.81v-15.65c0-.66-.02-1.35.14-1.99.31-1.26 1.18-2 2.48-2.09 1.38-.09 2.36.56 2.81 1.86.19.56.25 1.2.25 1.8.01 10.76.01 21.53 0 32.29 0 .6-.05 1.23-.23 1.8-.41 1.32-1.37 2-2.76 1.93-1.38-.06-2.29-.81-2.57-2.17-.14-.64-.12-1.32-.12-1.99v-15.81ZM39.83 128.11v15.48c0 .72 0 1.46-.16 2.15-.3 1.27-1.2 1.98-2.5 2.05-1.38.07-2.39-.57-2.78-1.89-.22-.73-.24-1.53-.24-2.3-.01-10.43-.01-20.86 0-31.29 0-2.85.93-4.22 2.86-4.22 1.93 0 2.84 1.33 2.84 4.22v15.81ZM139.41 127.93c0 5.44.03 10.88-.03 16.31-.01.89-.24 1.94-.75 2.62-1.24 1.63-3.87 1.14-4.59-.81-.26-.7-.29-1.52-.29-2.28-.02-10.54-.01-21.08-.01-31.63 0-.39-.02-.78.02-1.16.15-1.76 1.27-2.9 2.83-2.9 1.64 0 2.81 1.18 2.82 3.04.04 5.6.01 11.21.01 16.81ZM147.99 127.94v-15.82c0-.55-.02-1.12.07-1.66.25-1.44 1.13-2.28 2.58-2.37 1.38-.09 2.37.58 2.78 1.9.21.67.25 1.42.25 2.13.01 10.54.01 21.09 0 31.63 0 .55 0 1.12-.11 1.65-.32 1.59-1.43 2.47-2.92 2.38-1.48-.08-2.48-1.1-2.63-2.71-.05-.5-.03-1-.03-1.5v-15.65ZM125.19 128.03v15.65c0 .66 0 1.34-.15 1.98-.3 1.36-1.23 2.09-2.61 2.13-1.38.04-2.35-.64-2.72-1.98-.19-.68-.21-1.42-.21-2.14-.01-10.49 0-20.97 0-31.46v-.83c.11-2.13 1.2-3.35 2.94-3.3 1.68.05 2.72 1.22 2.74 3.3.04 5.55.01 11.1.02 16.64Z"
      />
    </g>
  </svg>
);
export default SvgResiduos;
