import React from 'react';
import { PostColumnWrapper, PostViewHolder, PostViewWrapper } from './PostView.components';
import PostDetail from 'organisms/postDetail/PostDetail';
import BlogSidebar from 'organisms/blogSidebar/BlogSidebar';
import { Container } from 'src/design/containers/Container';

const PostView: React.FC = () => {
    
  return (
    <PostViewWrapper>
        <Container>    
            <PostViewHolder>
                <PostColumnWrapper>
                    <PostDetail />
                </PostColumnWrapper>
                <PostColumnWrapper>
                    <BlogSidebar />
                </PostColumnWrapper>
            </PostViewHolder>
        </Container>
    </PostViewWrapper>
  )
}

export default PostView