import React, { useEffect } from "react";
import { Caption, SectionTitle, SSpan, SText } from "atoms/text/Text";
import {
  careersHeaderImg,
  careersHeaderMobileImg,
  imgIconRedCrossCheckPerson,
  imgIconRedHand,
  imgIconRedTranslate,
  imgIconRedWorld,
  imgParqueEolicoFuturoV,
  imgTrabajadoresSectorEolicoCarreras,
} from "images";
import ChangingWorld from "molecules/changingWorld/ChangingWorld";
import ImageContent from "molecules/ImageContent/ImageContent";
import ImageWithContent from "molecules/imageWidthContent/ImageWithContent";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import { secondaryColor, whiteColor, Spacing, backgroundDarkColor, blackColor, ColorRed } from "theme";
import { useNavigate } from "react-router-dom";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import {
  EnergyTeamHolder,
  EnergyTeamTitle,
  EnergyTeamWrapper,
  ReycoSwiperSliderContentWrapper,
  ReycoSwiperSliderWrapper,
  ReycoSwiperWrapper
} from "./Careers.components";
import { WorkingEnergyListWrapper } from "organisms/workingEnergy/WorkingEnergy.components";
import WorkingEnergyItem from "molecules/workingEnergyItem/WorkingEnergyItem";
import gsap from "gsap";
import { Container, Content } from "src/design/containers/Container";
import Vacancies from "organisms/vacancies/Vacancies";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import SubsectionTitle from "molecules/subsectionTitle/SubsectionTitle";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const Careers: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = isMobileDevice();

  useEffect(()=>{
    gsap.to('.working-energy-item-wrapper', {marginTop: 0, opacity: 1, stagger: 0.5, scrollTrigger: {
      trigger: '.working-energy-item-wrapper',
      start: 'top center'
    }});
  },[]);

  return (
    <>
      <ScreenHeader
        image={isMobile ? careersHeaderMobileImg : careersHeaderImg}
        title={t('careers.header.title')}
        subtitle={t('careers.header.subtitle')}
        sectionName={SectionName.CARRERAS}
      />
      <ImageContent
        isSwitchMobile
        imageId="parque-eolico-futuro"
        isShowDecorationLine={false}
        imagePlace="right"
        image={imgParqueEolicoFuturoV}
        imageMaxWidth="737px"
        imageHeight="884px"
        title={
          <div style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}>
            <Caption>
              {t('careers.workSector.caption')}
            </Caption>
            <SectionTitle>
              {t('careers.workSector.title1')}<br />
              <SSpan
                variant="sectionTitle"
                color={secondaryColor}
                fontWeight="bold"
              >
                {t('careers.workSector.title2')}
              </SSpan>
            </SectionTitle>
          </div>
        }
        content={
          <>
            <SText style={{ marginBottom: Spacing.baseSpacing }}>
              {t('careers.workSector.paragraph1')}
            </SText>
            <SText style={{ marginBottom: Spacing.baseSpacing }}>
              {t('careers.workSector.paragraph2')}
            </SText>
            <SText style={{ marginBottom: Spacing.baseSpacing }}>
              {t('careers.workSector.paragraph3')}
            </SText>
          </>
        }
      />
      <EnergyTeamWrapper>
        <Container>
          <Content>
            <EnergyTeamHolder>
              <EnergyTeamTitle><span>{t('careers.energyTeam.title1')}</span><br /> {t('careers.energyTeam.title2')}</EnergyTeamTitle>
              <SText
                style={{
                  marginTop: Spacing.xxxxLargeSpacing, 
                  textAlign: isMobile ? "left" : "center",
                  maxWidth: isMobile ? "100%" : "70%",
                }}
              >
                {t('careers.energyTeam.paragraph1')}
              </SText>
              <WorkingEnergyListWrapper
                style={{marginTop: Spacing.xxxxLargeSpacing}}
              >
              <WorkingEnergyItem
                image={imgIconRedWorld}
                title={t('careers.energyTeam.item1.title')}
                text={t('careers.energyTeam.item1.text')}
              />
              <WorkingEnergyItem 
                image={imgIconRedCrossCheckPerson}
                title={t('careers.energyTeam.item2.title')}
                text={t('careers.energyTeam.item2.text')}
              />
              <WorkingEnergyItem 
                image={imgIconRedTranslate}
                title={t('careers.energyTeam.item3.title')}
                text={t('careers.energyTeam.item3.text')}
              />
              <WorkingEnergyItem 
                image={imgIconRedHand}
                title={t('careers.energyTeam.item4.title')}
                text={t('careers.energyTeam.item4.text')}
              />
              </WorkingEnergyListWrapper>
            </EnergyTeamHolder>
          </Content>
        </Container>
      </EnergyTeamWrapper>
      <Vacancies />
      <ReycoSwiperWrapper>
        <Container>
          <Content>
            <Swiper
              autoplay
              loop
              className="swipper-holder"
              pagination={{clickable: true}}
              modules={[Pagination, Autoplay]}
            >
              <SwiperSlide>
                <ReycoSwiperSliderWrapper>
                  <ReycoSwiperSliderContentWrapper> 
                    <SubsectionTitle 
                      caption={t('careers.swiper.swiper1.caption')}
                      captionColor={secondaryColor}
                      titleA={t('careers.swiper.swiper1.title1')}
                      titleB={t('careers.swiper.swiper1.title2')}
                      titleColorB={blackColor}
                    />
                  </ReycoSwiperSliderContentWrapper>
                  <ReycoSwiperSliderContentWrapper>
                    <SText>
                      {t('careers.swiper.swiper1.paragraph1')}
                    </SText>
                    <SText
                    >
                      {t('careers.swiper.swiper1.paragraph2')}
                    </SText>
                  </ReycoSwiperSliderContentWrapper>
                </ReycoSwiperSliderWrapper>
              </SwiperSlide>
              <SwiperSlide>
                <ReycoSwiperSliderWrapper>
                  <ReycoSwiperSliderContentWrapper>
                    <SubsectionTitle 
                      caption={t('careers.swiper.swiper2.caption')}
                      captionColor={ColorRed}
                      titleA={t('careers.swiper.swiper2.title1')}
                      titleB={t('careers.swiper.swiper2.title2')}
                      titleColorB={blackColor}
                    />
                  </ReycoSwiperSliderContentWrapper>
                  <ReycoSwiperSliderContentWrapper>
                    <SText>
                      {t('careers.swiper.swiper2.paragraph1')}
                    </SText>
                    <SText
                    >
                      {t('careers.swiper.swiper2.paragraph2')}
                    </SText>
                  </ReycoSwiperSliderContentWrapper>
                </ReycoSwiperSliderWrapper>
              </SwiperSlide>
              <SwiperSlide>
                <ReycoSwiperSliderWrapper>
                  <ReycoSwiperSliderContentWrapper>
                    <SubsectionTitle 
                      caption={t('careers.swiper.swiper3.caption')}
                      captionColor={ColorRed}
                      titleA={t('careers.swiper.swiper3.title1')}
                      titleB={t('careers.swiper.swiper3.title2')}
                      titleColorB={blackColor}
                    />
                  </ReycoSwiperSliderContentWrapper>
                  <ReycoSwiperSliderContentWrapper>
                    <SText>
                      {t('careers.swiper.swiper3.paragraph1')}
                    </SText>
                    <SText
                    >
                      {t('careers.swiper.swiper3.paragraph2')}
                    </SText>
                  </ReycoSwiperSliderContentWrapper>
                </ReycoSwiperSliderWrapper>
              </SwiperSlide>
            </Swiper>
          </Content>
        </Container>
      </ReycoSwiperWrapper>
      <ImageWithContent
        isSwitchMobile
        imageId="trabajadores-sector-eolico-carreras"
        placeImage="left"
        backgroundColorContent={backgroundDarkColor}
        image={imgTrabajadoresSectorEolicoCarreras}
        title={
          <div style={isMobile ? {paddingTop: Spacing.xLargeSpacing} : undefined}>
            <SectionTitle
              color={whiteColor}
              fontWeight="bold">
                {t('careers.together.title')}
            </SectionTitle>
          </div>
        }
        content={
          <>
            <SText
              color={whiteColor}
            >
              {t('careers.together.paragraph1')}
            </SText>
            <SText
              color={whiteColor}
            >
              {t('careers.together.paragraph2')}
            </SText>
          </>
        }
        showButton={false}
      />
      <ChangingWorld />
    </>
  );
};

export default Careers;
