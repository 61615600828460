import styled from "styled-components";
import { blackColor, ColorWhite, secondaryColor, Spacing } from "theme";

export const PostDetailBreadcumWrapper = styled.section.attrs((props)=>({
    className: 'post-detail-breadcum-wrapper',
}))`
    padding: ${Spacing.xLargeSpacing} 0;

    & span {
        cursor: pointer;
        font-weight: normal;
    }

    & span:hover {
        color: ${secondaryColor};
    }
`;

export const PostDetailWrapper = styled.section.attrs((props)=>({
    className: 'post-detail-wrapper'
}))`
    background: ${ColorWhite};
`;

export const PostDetailBottomWrapper = styled.div.attrs((props)=>({
    className: 'post-detail-bottom-wrapper'
}))`
    padding: 0 ${Spacing.xxLargeSpacing}
`;

export const PostDetailBottomHolder = styled.div.attrs((props)=>({
    className: 'post-detail-bottom-holder'
}))``;

export const PostDetailBottomCategoryWrapper = styled.div.attrs((props)=>({
    className: 'post-detail-bottom-category-wrapper'
}))`
    display: flex;
    border-top: solid 1px ${blackColor};
    align-items: center;
`;

export const PostDetailBottomCategoryHolder = styled.div.attrs((props)=>({
    className: 'post-detail-bottom-category-holder'
}))`
    flex: 1;

    & > p {
        cursor: pointer;
    }   

    & > p:hover {
        color: ${secondaryColor};
    }   
`;

export const PostDetailBottomFunctionsHolder = styled.div.attrs((props)=>({
    className: 'post-detail-bottom-functions-holder'
}))`
    padding: 0 ${Spacing.tinySpacing};

    & svg {
        cursor: pointer;
    }

    & svg:hover {
        color: ${secondaryColor};
    }
`;
export const PostDetailBottomTagsWrapper = styled.div.attrs((props)=>({
    className: 'post-detail-bottom-tags-wrapper'
}))`
    display: flex;
    border-top: solid 1px ${blackColor};
    flex-wrap: wrap;
`;