import React, { useContext } from 'react';
import useSearchPosts from 'hooks/useSearchPosts';
import { useNavigate } from 'react-router-dom';
import PostItem from 'molecules/postItem/PostItem';
import { SText } from 'atoms/text/Text';
import Loader from 'atoms/loader/Loader';
import { FiltersType, SearchPostContext } from 'context/searchPostContext/SearchPostContext';
import ResultsText from './ResultsText';
import { useTranslation } from 'react-i18next';

const PostListTag: React.FC = () => {
  const {t} = useTranslation();
  const {searchTag} = useContext(SearchPostContext);
    const {loading, postsTagFounded} = useSearchPosts();
    const navigate = useNavigate();
    
    const handlePostClick = (uid: string | null)=> {
        navigate(`/Blog/${uid}`);
    }

  return (
    <>
      {
        searchTag?.tag &&
          <ResultsText 
            text={`Resultados para la etiqueta: ${searchTag.tag}`}
            filterType={FiltersType.BY_TAG}
          />
      }
      {
        searchTag && postsTagFounded.length ?
          postsTagFounded?.map((post)=>{
            return(
              <PostItem
                  key={post.id} 
                  topImage={post.data.top_image.url}
                  category={post.data.category.data.name}
                  createdAt={post.data.posted_on}
                  author={post.data.author.data.name}
                  title={post.data.title}
                  contentTop={post.data.content_top[0].text}
                  textButton={t('blog.readMore')}
                  onClickButton={()=>{handlePostClick(post.uid)}}
              />
            )
          })
        :
          <SText>
            No hay resultados disponibles.
          </SText>
      }
      {
        loading &&
          <Loader /> 
      }
    </>
  )
}

export default PostListTag