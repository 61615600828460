import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';

dayjs.locale('es-mx');


export const getYear = (): string => {
    return dayjs().format('YYYY');
};

export const getPostDate = (date: string, languageCode: LanguageCodes): string => {
    const locale = languageCode === LanguageCodes.ES ? es : enUS;
    const dateFormat = languageCode === LanguageCodes.ES ? 'dd MMMM yyyy' : 'PPP';
    return format(new Date(date), dateFormat, { locale });
}

export const getDateArchivedPosts = (pastMonth: number): Date =>{
    return dayjs().subtract(pastMonth, 'month').toDate();
}

export const getDateArchivedMonth = (date: Date): string => {
    return dayjs(date).format('MMMM YYYY');
}

export const getMonthName = (monthNumber: number, languageCode: LanguageCodes): string => {
    const locale = languageCode === LanguageCodes.ES ? es : enUS;
    return format(new Date(monthNumber, monthNumber-1), 'MMMM', { locale });
};

