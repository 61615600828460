export enum Spacing {
  tinySpacing = '8px',
  smallSpacing = '16px',
  baseSpacing = '24px',
  largeSpacing = '32px',
  xLargeSpacing = '40px',
  xxLargeSpacing = '48px',
  xxxLargeSpacing = '56px',
  xxxxLargeSpacing = '64px',
}

