import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";

export const TagListSideBarWrapper = styled.div.attrs((props)=>({
    className: 'tag-list-sidebar-wrapper'
}))`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: ${Breakpoints.xl}){
        grid-template-columns: 1fr;
    }
    
    @media (max-width: ${Breakpoints.lg}){
        grid-template-columns: 1fr 1fr;
    }
`;