import React from 'react';
import { SText } from 'atoms/text/Text';
import { BlogSideBarListProps } from 'custom-types/design/organisms/BlogSideBarList';
import { Spacing } from 'theme';
import { BlogSideBarListTitleWrapper, BlogSideBarListWrapper } from './BlogSideBarList.components';

const BlogSideBarList: React.FC<BlogSideBarListProps> = ({title, list}) => {
  return (
    <BlogSideBarListWrapper>
        <BlogSideBarListTitleWrapper>
            <SText
            fontWeight='bold'
            marginBottom={Spacing.tinySpacing}
            >
              {title}
            </SText>
            <hr />
        </BlogSideBarListTitleWrapper>
        {list}
    </BlogSideBarListWrapper>
  )
}

export default BlogSideBarList