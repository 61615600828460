import {RichTextBlock} from 'prismic-reactjs';

export enum TypePostItem {
    CAROUSEL = 'carousel'
}

export type PostItemProps = {
    type?: TypePostItem;
    topImage: string;
    category: string;
    showCategoryImage?: boolean;
    createdAt: string;
    author: string;
    title: RichTextBlock[];
    contentTop: string | RichTextBlock[]; 
    quote?: RichTextBlock[];
    image?: string;
    content?: RichTextBlock[];
    textButton?: string;
    onClickButton?: ()=>void;
    isHomeItem?: boolean;
};