import { NavLink } from 'react-router-dom';
import { Breakpoints } from 'src/theme/breakpoints';
import styled from 'styled-components';
import { backgroundLightColor, primaryColor, secondaryColor } from 'theme';

export const FooterWrapper = styled.section.attrs((props)=>({
  className: 'footer-wrapper'
}))`
  background-color: ${backgroundLightColor};
`;

export const FooterHolder = styled.div.attrs((props)=>({
  className: 'footer-holder'
}))`
  display: grid;
  grid-template-columns: 3fr 1fr;

  @media (max-width: ${Breakpoints.lg}) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }
`;

export const OfficesWrapper = styled.div.attrs((props)=>({
  className: 'offices-wrapper'
}))`

  @media (max-width: ${Breakpoints.lg}) {
    order: 2;
  }
`;

export const NavLogoWrapper = styled.div.attrs((props)=>({
  className: 'nav-logo-wrapper'
}))`
  display: flex;
  
  @media (max-width: ${Breakpoints.lg}) {
    order: 1;
    margin-bottom: 60px;
  }
`;

export const FooterLogoWrapper = styled.div.attrs((props)=>({
  className: 'footer-logo-wrapper'
}))`
`;

export const CopyRightWrapper = styled.div.attrs((props)=>({
  className: 'copy-right-wrapper'
}))`
  padding: 18px 0px 44px 0px;
  display: flex;
  gap: 100px;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column;
    gap: 64px;
  }
`;

export const CopyRightHolder = styled.div.attrs((props)=>({
  className: 'copy-right-holder'
}))`

@media (max-width: ${Breakpoints.lg}) {
    order: 3;

    & p {
      font-size: 10px;
    }
  }
`;

export const LegalLinksWrapper = styled.div.attrs((props)=>({
  className: 'legal-links-wrapper'
}))`
`;

export const LegalLinksUl = styled.ul.attrs((props)=>({
  className: 'legal-links-ul'
}))`
  list-style: none;
  display: flex;
  gap: 80px;

  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column;
    gap: 0px;
    order: 1;
  }
`;

export const LegalLinkItem = styled.li.attrs((props)=>({
  className: 'legal-link-item'
}))`
  text-decoration: underline;
  cursor: pointer;
`;

export const SocialWrapper = styled.div.attrs((props)=>({
  className: 'social-wrapper'
}))`
  display: flex;
  align-items: baseline;

  @media (max-width: ${Breakpoints.lg}) {
    order: 2;
  }
`;

export const FooterLinksUl = styled.ul`
  list-style: none;
  flex: 1;

  @media (max-width: ${Breakpoints.xxl}) {
    display: none;
  }
`;

export const FooterLinksLi = styled.li`
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: 400;
  flex: 1;
`;

export const FooterLink = styled(NavLink)`
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 400;
  text-decoration: none;
  color: black;

  :hover {
    color: ${secondaryColor};
  }
`;