import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { ColorBlackContainer, Spacing } from "theme";

export const MoreAboutReycoWrapper = styled.section.attrs((props)=>({
  className: 'more-about-reyco-wrapper'
}))`
  padding: ${Spacing.xLargeSpacing} 0;
  background-color: ${ColorBlackContainer};

  @media (max-width: ${Breakpoints.lg}){
    padding: 0 0 ${Spacing.xLargeSpacing};
  }
`;

export const MoreAboutReycoHolder = styled.div.attrs((props)=>({
  className: 'more-about-reyco-holder'
}))`
  display: flex;

  @media (max-width: ${Breakpoints.xl}){
    display: block;
  }
`;

export const MoreAboutReycoImgWrapper = styled.div.attrs((props)=>({
  className: 'more-about-reyco-img-wrapper'
}))`
  width: 509px;
  height: 602px;
  
  @media (max-width: ${Breakpoints.xl}){
    width: 100%;
    height: auto;
  }
`;

export const MoreAboutReycoImg = styled.img.attrs((props)=>({
  className: 'more-about-reyco-img',
  src: props.src
}))`
  width: 100%;
  height: 100%;
  position: relative;
  display: block;
`;

export const MoreAboutReycoContentWrapper = styled.div.attrs((props)=>({
  className: 'more-about-reyco-content-wrapper'
}))`
  flex: 1;
  padding: 5%;

  @media (max-width: ${Breakpoints.lg}) {
    padding: 0;
  }
`;