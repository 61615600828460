import ServiceTitle from "molecules/serviceTitle/ServiceTitle";
import React from "react";
import { Container, Content } from "src/design/containers/Container";
import ServiceSubitem from 'organisms/serviceSubitem/ServiceSubitem';
import Infraestructura from "./Infraestructura";
import { electricInfraHeaderImg, electricInfraHeaderMobileImg, imgServiciosInfraElectrica, imgServiciosInfraElectricaBodega, imgServiciosInfraElectricaCogenerador } from "images";
import { EdificiosIcon } from "src/icons";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { isMobileDevice } from "utils/isMobileDevice";
import { ServiceWrapper } from "./InfraestructuraElectrica.styled";
import { useTranslation } from "react-i18next";

const InfraestructuraElectrica: React.FC = () => {
  const { t } = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <>
      <ScreenHeader
        image={isMobile ? electricInfraHeaderMobileImg : electricInfraHeaderImg}
        title={t('electricalInfrastructure.header.title')}
        subtitle={t('electricalInfrastructure.header.subtitle')}
        sectionName={SectionName.CONTACTO}
      />
      <ServiceWrapper>
        <Container>
          <ServiceSubitem
            imageId="infraestructura-electrica"
            content={
              <Infraestructura />
            }
            img={imgServiciosInfraElectrica}
            imgPosition="right"
          />
          {/* <ServiceSubitem
            content={
              <img style={{maxHeight: "655px", width: "100%"}} src={imgServiciosInfraElectricaCogenerador} />
            }
            img={imgServiciosInfraElectricaBodega}
            imgPosition="left"
          /> */}
        </Container>
      </ServiceWrapper>
    </>
  )
}

export default InfraestructuraElectrica