import React from 'react';
import { imgAerogeneradorPaisaje, imgPerfilesHeader, imgPerfilesProfesionales } from 'images';
import ScreenHeader from 'molecules/screenHeader/ScreenHeader';
import { ReycoSwiperSliderContentWrapper, ReycoSwiperSliderWrapper, ReycoSwiperWrapper } from './ProfesionalProfiles.components';
import { backgroundDarkColor, blackColor, ColorGray, ColorRed, secondaryColor, Spacing, whiteColor } from 'theme';
import { SectionTitle, SText } from 'atoms/text/Text';
import Vacancies from 'organisms/vacancies/Vacancies';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import SubsectionTitle from 'molecules/subsectionTitle/SubsectionTitle';
import { Container } from 'src/design/containers/Container';
import { SectionName } from 'custom-types/design/molecules/ScreenHeader';
import ImageWithContent from 'molecules/imageWidthContent/ImageWithContent';

const ProfesionalProfiles: React.FC = () => {
  return (
    <>
      <ScreenHeader
          image={imgPerfilesProfesionales}
          title='Perfiles profesionales'
          imgTitle={imgPerfilesHeader}
          sectionName={SectionName.PERFILES}
      />
      <ImageWithContent
        backgroundColorContent={backgroundDarkColor}
        placeImage="left"
        image={imgAerogeneradorPaisaje}
        caption='TRABAJA CON NOSOTROS'
        captionColor={secondaryColor}
        title={
          <SectionTitle 
            color={whiteColor}
          >
            Juntos, podemos llevar la<br /> energía más lejos
          </SectionTitle>
        }
        content={
          <>
            <SText
              color={whiteColor}
              style={{marginBottom: Spacing.xxLargeSpacing}}
            >
              En Reyco Renovables promovemos el uso de energía eólica en cada uno de los proyectos en los que participamos de manera internacional. Una fuerza de trabajo unida, materiales de primera categoría y mejora constante es lo que nos permite seguir moviendo el mundo.
            </SText>
            <SText
              color={whiteColor}
              style={{marginBottom: Spacing.baseSpacing}}
            >
                Nuestro compromiso con el medio ambiente es la base de cada servicio que proveemos, buscando un menor impacto al planeta y consolidar a las energías
            </SText>
          </>
        }
      />
      <Vacancies />
      <ReycoSwiperWrapper>
        <Container>
          <Swiper
            autoplay
            loop
            pagination={{clickable: true}}
            modules={[Pagination, Autoplay]}
          >
            <SwiperSlide>
              <ReycoSwiperSliderWrapper>
                <ReycoSwiperSliderContentWrapper>
                  <SubsectionTitle 
                    caption='SOBRE NOSOTROS'
                    captionColor={secondaryColor}
                    titleA='Somos reyco.'
                    titleB='Somos el cambio.'
                    titleColorB={blackColor}
                  />
                </ReycoSwiperSliderContentWrapper>
                <ReycoSwiperSliderContentWrapper>
                  <SText>
                    Por un mundo más sostenible, construyamos el futuro de las energías renovables juntos.
                  </SText>
                  <SText
                  >
                    En Reyco Renovables promovemos el uso de energía eólica en cada uno de los proyectos en los que participamos de manera internacional. Una fuerza de trabajo unida, materiales de primera categoría y mejora constante es lo que nos permite seguir moviendo el mundo.
                  </SText>
                </ReycoSwiperSliderContentWrapper>
              </ReycoSwiperSliderWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <ReycoSwiperSliderWrapper>
                <ReycoSwiperSliderContentWrapper>
                  <SubsectionTitle 
                    caption='SOBRE NOSOTROS'
                    captionColor={ColorRed}
                    titleA='Nuestra prioridad:'
                    titleB='tu seguridad.'
                    titleColorB={blackColor}
                  />
                </ReycoSwiperSliderContentWrapper>
                <ReycoSwiperSliderContentWrapper>
                  <SText>
                    Lo más importante para nosotros es la seguridad y bienestar de cada uno de nuestros colaboradores, cuidando al máximo los componentes y herramientas que usamos en los proyectos, reduciendo riesgos y mejorando procesos internos.
                  </SText>
                  <SText
                  >
                    Nuestro compromiso con el medio ambiente es la base de cada servicio que proveemos, buscando un menor impacto al planeta y consolidar a las energías renovables como la principal fuente de energía en el mundo.
                  </SText>
                </ReycoSwiperSliderContentWrapper>
              </ReycoSwiperSliderWrapper>
            </SwiperSlide>
            <SwiperSlide>
              <ReycoSwiperSliderWrapper>
                <ReycoSwiperSliderContentWrapper>
                  <SubsectionTitle 
                    caption='SOBRE NOSOTROS'
                    captionColor={ColorRed}
                    titleA='Comprometidos con'
                    titleB='el mundo y contigo.'
                    titleColorB={blackColor}
                  />
                </ReycoSwiperSliderContentWrapper>
                <ReycoSwiperSliderContentWrapper>
                  <SText>
                    Buscamos nuevas formas de producir energía, a un menor costo económico y un impacto ambiental bajo en comparación a los combustibles fósiles.
                  </SText>
                  <SText
                  >
                    Nuestro pilar: las energías renovables, hemos desarrollado proyectos por todo el mundo, fomentando el cambio de energía, así como la infraestructura adecuada en diferentes sectores; como la meteorología, comunicaciones y eléctrica, para lograr ser parte del incremento constante en la producción de energías limpias en las últimas décadas.
                  </SText>
                </ReycoSwiperSliderContentWrapper>
              </ReycoSwiperSliderWrapper>
            </SwiperSlide>
          </Swiper>
        </Container>
      </ReycoSwiperWrapper>
    </>
  )
}

export default ProfesionalProfiles