import React from "react";
import { SText, SectionTitle } from "atoms/text/Text";
import { List } from "../parquesEolicos/ParquesEolicos.components";
import { useTranslation } from "react-i18next";
import ServiceTitle from "molecules/serviceTitle/ServiceTitle";
import { TorreElectricaIcon } from "src/icons";

const Instalacion: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ServiceTitle
        caption={t("meteorologicalTowers.subheader.caption")}
        title={t("meteorologicalTowers.subheader.title")}
        higlight=""
        hasBreakLine={false}
        wrapperStyle={{ paddingBottom: "90px" }}
      />
      <SText marginBottom="60px">
        {t("meteorologicalTowers.paragraph1")}
      </SText>
      <TorreElectricaIcon width={49} height={49} />
      <SectionTitle>{t('meteorologicalTowers.installation.title')}</SectionTitle>
      <List>
        <li>
          {t('meteorologicalTowers.installation.list1.item1')}
        </li>
        <li>
          {t('meteorologicalTowers.installation.list1.item2')}
        </li>
        <li>
          {t('meteorologicalTowers.installation.list1.item3')}
        </li>
        <li>
          {t('meteorologicalTowers.installation.list1.item4')}
        </li>
        <li>
          {t('meteorologicalTowers.installation.list1.item5')}
        </li>
        <li>
          {t('meteorologicalTowers.installation.list1.item6')}
        </li>
      </List>
    </>
  );
};

export default Instalacion;
