import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { List } from "./ParquesEolicos.components";
import { useTranslation } from "react-i18next";

const Suministros: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <SectionTitle>{t('windFarms.supplies.title')}</SectionTitle>
      <SText>
        {t('windFarms.supplies.paragraph1')}
      </SText>
      <List>
        <li>
          {t('windFarms.supplies.list1.item1')}
        </li>
        <li>
          {t('windFarms.supplies.list1.item2')}
        </li>
        <li>
          {t('windFarms.supplies.list1.item3')}
        </li>
        <li>
          {t('windFarms.supplies.list1.item4')}
        </li>
        <li>
          {t('windFarms.supplies.list1.item5')}
        </li>
      </List>
    </>
  );
};

export default Suministros;
