import React, { FormEvent, useContext } from "react";
import InputSearchText from "atoms/inputSearch/InputSearchText";
import { ClearSearchIcon, SearchIcon, SearchPostF } from "./SearchPostForm.components";
import { SearchPostContext } from "context/searchPostContext/SearchPostContext";
import { ColorGray, Spacing } from "theme";
import { useTranslation } from "react-i18next";

const SearchPostForm: React.FC = () => {
  const {t} = useTranslation();
  const { searchText, handleSearchText, handleSearchPost, clearSearchByText } =
    useContext(SearchPostContext);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSearchPost && handleSearchPost();
  };

  return (
    <SearchPostF onSubmit={handleSubmit}>
      <SearchIcon
        color={ColorGray}
        size={Spacing.baseSpacing}
      />
      <InputSearchText
        value={searchText}
        placeholder={t('blog.search')}
        onChange={handleSearchText}
      />
      {searchText && (
        <ClearSearchIcon
          onClick={clearSearchByText}
          color={ColorGray}
          size={Spacing.baseSpacing}
        />
      )}
    </SearchPostF>
  );
};

export default SearchPostForm;
