export enum SectionName {
  NOSOTROS = 'nosotros',
  SERVICIOS = 'servicios',
  PARQUES_EOLICOS = 'parques_eolicos',
  SUSTENTABILIDAD = 'sustentabilidad',
  CARRERAS = 'carreras',
  PERFILES = 'perfiles',
  NOTICIAS = 'notocias',
  BROCHURE = 'brochure',
  CONTACTO = 'contacto',
  PROYECTOS = 'proyectos',
};

export type ScreenHeaderType = {
  image: string;
  title: string;
  subtitle?: string;
  imgTitle?: string;
  sectionName: SectionName;
};