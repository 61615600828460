import { imgMapa } from "images";
import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { primaryColor, secondaryColor } from "theme";

export const EnergyTeamWrapper = styled.div.attrs((props)=>({
  className: 'energy-team-wrapper'
}))`
display: flex;
justify-content: center;
padding-bottom: 97px;
`;

export const EnergyTeamHolder = styled.div.attrs((props)=>({
  className: 'energy-team-holder'
}))`
background: url(${imgMapa}) top center no-repeat;
background-size: contain;
height: 575px;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;

@media (max-width: ${Breakpoints.lg}){
  height: auto;
}
`;

export const EnergyTeamTitle = styled.h2.attrs((props)=>({
  className: 'energy-team-title'
}))`
font-size: 120px;
color: ${primaryColor};
line-height: 80px;

span{
    font-size: 80px;
    color: ${secondaryColor}
}

@media (max-width: ${Breakpoints.lg}){
  font-size: 80px;
  line-height: 60px;

  span{
    font-size: 86px;
  }
}

@media (max-width: ${Breakpoints.sm}){
  font-size: 45px;
  line-height: 32px;

  span{
    font-size: 40px;
  }
}
`;

export const ReycoSwiperWrapper = styled.div.attrs((props)=>({
    className: 'reyco-swipper-wrapper'
}))`
  padding: 350px 0 190px 0;

    .swiper {
      width: 80%;
    }
  
    .swiper-pagination {
      text-align: left;
      z-index: 10000;
      bottom: 0%;
      
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 55px;
      height: 55px;
      background-color: transparent;
      border-bottom: solid 6px #D82520;
      border-radius: 0;
    }

    .swiper-pagination-bullet{
      width: 55px;
      height: 55px;
      background-color: transparent;
      border-bottom: solid 3px #707070;
      border-radius: 0;
    }

    @media (max-width: ${Breakpoints.lg}){
      padding: 60px 0 60px 0;

      .swiper {
        width: 100%;
      }

      .swiper-pagination {
        text-align: left;
        z-index: 10000;
      }
    }

    @media (max-width: ${Breakpoints.lg}){
      .swiper-pagination {
        bottom: 0;
      }
    }
`;

export const ReycoSwiperSliderWrapper = styled.div.attrs((props)=>({
    className: 'reyco-swiper-slider-wrapper'
}))`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 1240px){
    grid-template-columns: 1fr;
  }
`;

export const ReycoSwiperSliderContentWrapper = styled.div.attrs((props)=>({
  className: 'reyco-swiper-slider-content-wrapper'
}))`
  flex: 1;
`;