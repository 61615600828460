import  i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import { languageResources } from './languagesResources';

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  lng: navigator.language.includes('es') ? "es" : "en",
  resources: languageResources,
});

export default i18n;