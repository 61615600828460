import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { List } from "../parquesEolicos/ParquesEolicos.components";
import { useTranslation } from "react-i18next";

const Instalacion: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <SectionTitle>{t('solarFarms.installation.title')}</SectionTitle>
      <SText>
        {t('solarFarms.installation.paragraph1')}
      </SText>
      <List>
        <li>
          {t('solarFarms.installation.list1.item1')}
        </li>
        <li>
          {t('solarFarms.installation.list1.item2')}
        </li>
      </List>
    </>
  );
};

export default Instalacion;
