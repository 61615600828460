import React from "react";
import SButton from "atoms/button/Button";
import CategoryLabel from "atoms/categoryLabel/CategoryLabel";
import { SText } from "atoms/text/Text";
import { PostItemProps } from "custom-types/design/molecules/PostItem";
import { secondaryColor, strongGrayColor } from "theme";
import {
  PostItemContentHolder,
  PostItemContentWrapper,
  PostItemImage,
  PostItemImageWrapper,
  PostItemWrapper,
  PostItemContentTopHolder,
  PostItemContentCentralHolder,
  PostItemImageContentWrapper,
  PostItemTitleWrapper,
} from "./PostItem.components";
import { RichText } from "prismic-reactjs";
import "./PostItem.css";
import { cutText } from "./PostItem.utils";
import { getPostDate } from "fixtures/dates";
import Quote from "atoms/quote/Quote";
import { useTranslation } from "react-i18next";
import { LanguageCodes } from "molecules/selectLanguage/SelectLanguage.types";

const PostItem: React.FC<PostItemProps> = ({
  topImage,
  category,
  createdAt,
  author,
  title,
  contentTop,
  quote,
  image,
  content,
  textButton,
  onClickButton,
  type,
  showCategoryImage = true,
  isHomeItem = true,
}) => {
  const {i18n, t} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;

  return (
    <PostItemWrapper type={type}>
      {
        !isHomeItem &&
        <PostItemContentWrapper>
          <PostItemContentHolder>
            {RichText.render(title)}
            <SText color={strongGrayColor} marginBottom="0" variant="caption">
              {`${getPostDate(createdAt, languageCode)} / ${t('blog.writtenBy')} ${author}`}
            </SText>
          </PostItemContentHolder>
        </PostItemContentWrapper>
      }
      <PostItemImageWrapper type={type}>
        <PostItemImage src={topImage} />
        {
          showCategoryImage &&
          <CategoryLabel category={category} typePostItem={type} />
        }
      </PostItemImageWrapper>
      <PostItemContentWrapper>
        <PostItemContentHolder>
          {
            isHomeItem &&
            <>
              <SText color={secondaryColor} marginBottom="0" variant="caption">
                {`${getPostDate(createdAt, languageCode)} / ${author}`}
              </SText>
              <PostItemTitleWrapper onClick={onClickButton}>
                {RichText.render(title)}
              </PostItemTitleWrapper>
            </>
          }
          {typeof contentTop === "string" ? (
            <SText>{cutText(contentTop)}</SText>
          ) : (
            <PostItemContentTopHolder>
              {RichText.render(contentTop)}
            </PostItemContentTopHolder>
          )}
          {quote && <Quote text={quote} />}
          {content && (
            <>
              <PostItemImageContentWrapper>
                <PostItemImage src={image} />
              </PostItemImageContentWrapper>
              <PostItemContentCentralHolder>
                {RichText.render(content)}
              </PostItemContentCentralHolder>
            </>
          )}
          {textButton && onClickButton ? (
            <SButton
              title={textButton}
              onClick={onClickButton}
              variant="primary"
            >
              {textButton}
            </SButton>
          ) : null}
        </PostItemContentHolder>
      </PostItemContentWrapper>
    </PostItemWrapper>
  );
};

export default PostItem;
