import React, { useEffect, useState } from 'react';
import { Caption, SectionTitle} from 'atoms/text/Text';
import { Container, Content } from 'src/design/containers/Container';
import { 
  LastPostsCarouselHolder,
  LastPostsCarouselTitleWrapper, 
  LastPostsCarouselWrapper,
  PostsWrapper,
  StrokedTextWrapper,
} from './LastPostsCarousel.components';
import { ColorRed, grayc9c9c9, Spacing } from 'theme';
import SButton from 'atoms/button/Button';
import { useNavigate } from 'react-router-dom';
import PostItem from 'molecules/postItem/PostItem';
import { Swiper, SwiperClass,  SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { TypePostItem } from 'custom-types/design/molecules/PostItem';
import { HeaderTitle } from 'molecules/screenHeader/ScreenHeader.components';
import { isMobileDevice } from 'utils/isMobileDevice';
import { useTranslation } from 'react-i18next';
import { getPrismicLanguage } from 'utils/i18n/getPrismicLanguage';
import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import { prismicClient } from 'fixtures/prismic';

const LastPostsCarousel: React.FC = () => {
  const {i18n, t} = useTranslation();
  const languageCode = i18n.language as LanguageCodes;
  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);
  const [posts, setPosts] = useState<any[]>();

  useEffect(() => {
    (async () =>{
      const postsFounded = await prismicClient.getAllByType('post',{
        fetchLinks: ['post_author.name', 'post_category.name'],
        orderings: {
          field: 'my.post.posted_on',
          direction: 'desc'
        },
        limit: 6,
        lang: getPrismicLanguage(languageCode)
      });
      setPosts(postsFounded);
    })();
  }, [languageCode]);

  useEffect(() => {
    setTimeout(() => {
      swiperRef && swiperRef.autoplay?.start();
    }, 2000);
  }, [languageCode, swiperRef]);


  const isMobile = isMobileDevice();

  const navigate = useNavigate();

  const handleNavNews = ()=> {
    navigate('/Blog');
  }

  const handlePostClick = (uid: string | null)=> {
    navigate(`/Blog/${uid}`);
  }

  return (
    <LastPostsCarouselWrapper>
      <Container>
        <LastPostsCarouselHolder>
          <Content>
            <LastPostsCarouselTitleWrapper>
              <StrokedTextWrapper>
                <HeaderTitle style={{WebkitTextStroke:  `2px ${grayc9c9c9}`}}>Blog</HeaderTitle>
              </StrokedTextWrapper>
              <Caption>
                  Blog
              </Caption>
              <SectionTitle
                  fontWeight='bold'
                  style={{marginBottom: Spacing.largeSpacing}}
              >
                  {t('home.latestNews.title1')} <br />
                  <SectionTitle color={ColorRed}>
                    {t('home.latestNews.title2')}
                  </SectionTitle> 
              </SectionTitle>
              <SButton 
                  title='Ver más' 
                  onClick={handleNavNews}
                  variant='primary'
                  style={{width: '170px', height: '56px', lineHeight: '100%'}}
              >
                  {t('home.latestNews.buttonText')}
              </SButton>
            </LastPostsCarouselTitleWrapper>
          </Content>
          <PostsWrapper style={{padding: isMobile ?  '0 16px' : undefined}}>
            <Swiper
              onSwiper={setSwiperRef}
              autoplay
              loop
              style={{paddingBottom: '100px'}}
              modules={[Pagination, Autoplay, FreeMode]}
              slidesPerView={1}
              slidesPerGroup={1}
              spaceBetween={36}
              pagination={{clickable: true}}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                1200: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
              }}
            >
                {    
                  posts?.map((post)=>{
                    return(
                      <SwiperSlide
                        style={{cursor: 'pointer'}}
                        key={post.id}
                        onClick={()=>{handlePostClick(post.uid)}}
                      >
                        <PostItem
                          type={TypePostItem.CAROUSEL}
                          key={post.id} 
                          topImage={post.data.top_image.url}
                          category={post.data.category.data.name}
                          createdAt={post.data.posted_on}
                          author={post.data.author.data.name}
                          title={post.data.title}
                          contentTop={post.data.content_top[0].text}
                        />
                      </SwiperSlide>
                  )
                })}
            </Swiper>
          </PostsWrapper>
        </LastPostsCarouselHolder>
      </Container>
    </LastPostsCarouselWrapper>
  )
}

export default LastPostsCarousel