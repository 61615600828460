import React from "react";
import { SectionTitle, SText } from "atoms/text/Text";
import { useTranslation } from "react-i18next";

const InstalacionEerogeneradores: React.FC = () => {
  const {t} = useTranslation();

  return (
    <>
      <SectionTitle>{t('windFarms.installation.title')}</SectionTitle>
      <SText>
        {t('windFarms.installation.paragraph1')}
      </SText>
      <SText>
        {t('windFarms.installation.paragraph2')}
      </SText>
    </>
  );
};

export default InstalacionEerogeneradores;
