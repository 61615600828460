import { Breakpoints } from 'src/theme/breakpoints';
import styled from 'styled-components';

type OfficesListProps = {
    justifyContent?: string;
    gap?: string;
};

type OfficesItemProps = {
    isAnimated?: boolean;
};

export const OfficesWrapper = styled.section.attrs((props)=>({
    className: 'offices-wrapper'
}))`
`;

export const OfficesHolder = styled.div.attrs((props)=>({
    className: 'offices-holder'
}))``;

export const OfficesList = styled.div.attrs((props)=>({
    className: 'offices-List'
}))<OfficesListProps>`
    display: grid;
    gap: ${props => props.gap || '80px'};
    grid-template-columns: repeat(auto-fit, minmax(180px, 264px));
    justify-content: ${props => props.justifyContent || 'center'};

    @media (max-width: ${Breakpoints.lg}){
        gap: 40px;
    }
`;

export const OfficesItem = styled.div.attrs((props)=>({
    className: 'offices-item'
}))<OfficesItemProps>`
    opacity: ${props => props.isAnimated ? 0 : 1};
    position: relative;
    top: ${props => props.isAnimated ? '150px' : '0px'};
`;