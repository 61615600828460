import React, { useState } from 'react';
import { Wrapper } from "@googlemaps/react-wrapper";
import MapComponent from './MapComponent';
import Marker from './Marker';
import { MapHolder, MapWrapper } from './Map.components';

const Map: React.FC = () => {
    const [center] = useState<google.maps.LatLngLiteral>({ lat: 19.424580, lng: -99.161553 });
    const zoom = 15;

  return (
    <MapWrapper>
      <MapHolder>    
        <Wrapper apiKey={process.env.REACT_APP_MAP_API_KEY}>
          <MapComponent 
            center={center}
            zoom={zoom}>
                <Marker
                  position={center} 
                />
            </MapComponent>
        </Wrapper>
      </MapHolder>
    </MapWrapper>
  )
}

export default Map