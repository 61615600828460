import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const ServiceItemWrapper = styled.div.attrs((props) => ({
  className: 'service-item-wrapper'
}))`
  width: 100%;
  height: 596px;
  margin-bottom: ${Spacing.baseSpacing};
  position: relative;
  overflow: hidden;
  cursor: pointer;

  p {
    position: absolute;
    bottom: ${Spacing.baseSpacing};
    padding-left: ${Spacing.smallSpacing}
  }

  @media (max-width: ${Breakpoints.lg}){
      height: 402px;
      }
    }

`;

export const ServiceItemImg = styled.img.attrs(()=> ({
  className: 'service-item-img'
}))`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ServiceItemOverlay = styled.div.attrs(()=> ({
  className: 'service-item-overlay'
}))`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(37,72,145,0.8);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0; 

  p {
    position: relative; 
  }
`;