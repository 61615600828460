import React from "react";
import { Container } from "src/design/containers/Container";
import ServiceSubitem from "organisms/serviceSubitem/ServiceSubitem";
import {
  imgServiciosTorresMeteoroEstacion,
  meteorologicalTowersHeaderImg,
} from "images";
import Instalacion from "./Instalacion";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { ServiceWrapper } from "screens/infraestructuraElectrica/InfraestructuraElectrica.styled";
import { useTranslation } from "react-i18next";

const TorresMeteorologicas: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScreenHeader
        image={meteorologicalTowersHeaderImg}
        title={t("meteorologicalTowers.header.title")}
        subtitle={t("meteorologicalTowers.header.subtitle")}
        sectionName={SectionName.CONTACTO}
      />
      <ServiceWrapper>
        <Container>
          <ServiceSubitem
            imageId="torres-meteorologicas-instalacion"
            content={<Instalacion />
            }
            img={imgServiciosTorresMeteoroEstacion}
            imgPosition="right"
          />
        </Container>
      </ServiceWrapper>
    </>
  );
};

export default TorresMeteorologicas;
