import { imgAeroGeneradorEolico } from 'images';
import { Breakpoints } from 'src/theme/breakpoints';
import styled from 'styled-components';
import { backgroundDarkColor } from 'theme';

export const WhatWeDoWrapper = styled.section.attrs(()=> ({
  className: 'what-we-do-wrapper'
}))`
  background: url(${imgAeroGeneradorEolico}) top center no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @media (max-width: ${Breakpoints.lg}) {
    background: transparent;
  }
`;

export const WhatWeDoHolder = styled.div.attrs(props => ({
  className: 'what-we-do-holder'
}))`
  display: flex;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
  }
`;

export const WhatWeDoContentWrapper = styled.div.attrs(()=> ({
  className: 'what-we-do-content-wrapper'
}))`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 733px;
  justify-content: center;
  

  .count-holder:first-child {
    margin-bottom: 100px;
  }

  @media (max-width: ${Breakpoints.lg}){
    display: block;
    height: auto;

    h2, p {
      color: ${backgroundDarkColor} !important;
    }
  }
`;

export const ImgWhatWeDoMobile = styled.img.attrs(props => ({
  className: 'img-what-we-do-mobile'
}))`
  display: none;

  @media (max-width: ${Breakpoints.lg}) {
    display: block;
    width: 100%;
    margin-bottom: 32px;
  }
`;