import styled from "styled-components";

export const PostsByCategoryItemWrapper = styled.div.attrs((props)=>({
    className: 'posts-by-category-item-wrapper'
}))``;

export const PostsByCategoryItemHolder = styled.div.attrs((props)=>({
    className: 'posts-by-category-item-holder'
}))`
    display: flex;
`;

export const CategoryItemWrapper = styled.div.attrs((props)=>({
    className: 'category-item-wrapper'
}))`
    flex: 1;
    cursor: pointer;
`;

export const CountPostsWrapper = styled.div.attrs((props)=>({
    className: 'count-posts-wrapper'
}))``;