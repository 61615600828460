import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

export const OurExperienceWorldWrapper = styled.div.attrs(() => ({
  className: 'our-experience-world-wrapper'
}))`
  padding: 80px 0px;
`;

export const WorldMapTitleWrapper = styled.div.attrs(() => ({
  className: 'world-map-title-wrapper'
}))`
  text-align: center;

  p {
    width: 70%;
    margin: 0 auto;
  }

  @media (max-width: ${Breakpoints.lg}) {
    text-align: left;
    
    p {
      width: 100%;
    }
  }
`;

export const WorldMapWrapper = styled.div.attrs((props) => ({
  className: 'world-map-wrapper'
}))`
  width: 100%;

    & .worldmap__figure-container {
      text-align: center;
      width: 100%;
      height: 100%;
    }
  
  @media (max-width: ${Breakpoints.lg}) {
    padding: ${Spacing.xLargeSpacing} 0;
  }
`;

export const CountryListWrapper = styled.div.attrs((props) => ({
  className: 'country-list-wrapper',
}))`
  display: flex;
  justify-content: center;
`;

export const CountryListWrapperMobile = styled.div.attrs((props) => ({
  className: 'country-list-wrapper-mobile'
}))`
  display: grid;
  grid-template-columns: repeat(3, 420px);
  grid-template-rows: repeat(3, auto);
  grid-template-areas: 
  "mexico argentina guatemala"
  "mexico argentina dominicana"
  "mexico chile uruguay";
  justify-content: center;

  @media (max-width: ${Breakpoints.xl}) {
    grid-template-columns: repeat(2, 35%);
    grid-template-rows: repeat(3, auto);
    row-gap: 30px;
    grid-template-areas: 
    "mexico guatemala"
    "argentina  dominicana"
    "chile uruguay";

    p {
      margin-bottom: 0px;
    }
  }

  @media (max-width: ${Breakpoints.md}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "mexico"
    "argentina"
    "chile"
    "guatemala"
    "dominicana"
    "uruguay";
  }
`;

export const CountryListHolder = styled.div.attrs<{className: string}>((props) => ({
  className: 'country-list-holder',
  id: "country-list-holder",
}))`

  &.mexico {
    grid-area: mexico;
  }

  &.argentina {
    grid-area: argentina;
  }
  
  &.guatemala {
    grid-area: guatemala;
  }
  
  &.chile {
    grid-area: chile;
  }
  
  &.dominicana {
    grid-area: dominicana;
  }
  
  &.uruguay {
    grid-area: uruguay;
  }

  p {
    margin-bottom: 20px;
  }
`;

export const CountryList = styled.ul.attrs((props) => ({
  className: 'country-list'
}))`
  margin: 0;
  padding: 0;
  list-style-position: inside;
`;