import React from "react";
import SButton from "atoms/button/Button";
import InputNewsLetter from "atoms/input/InputNewsLetter";
import { SectionTitle, SSpan, SText } from "atoms/text/Text";
import CheckBox from "molecules/checkBox/CheckBox";
import { Container, Content } from "src/design/containers/Container";
import { ColorRed, ColorWhite, Spacing } from "theme";
import {
  NewsLetterChecktWrapper,
  NewsLetterContentWrapper,
  NewsLetterFormWrapper,
  NewsLetterHolder,
  NewsLetterInputWrapper,
  NewsLetterWrapper,
} from "./NewsLetter.components";
import useNewsletter from "hooks/useNewsletter";
import Loader from "atoms/loader/Loader";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const NewsLetter: React.FC = () => {
  const {t} = useTranslation();
  const {
    termsAccepted,
    validateForm,
    handleCheck,
    handleEmail,
    email,
    loading,
  } = useNewsletter();
  
  const isMobile = isMobileDevice();

  return (
    <NewsLetterWrapper>
      <Container>
        <NewsLetterHolder>
          <NewsLetterContentWrapper>
            <Content>
              <SectionTitle
                color={ColorWhite}
                fontWeight="bold"
                style={{
                  marginBottom: Spacing.smallSpacing,
                }}
              >
                {t("home.newsletter.title1")}{" "}
                <SSpan
                  color={ColorRed}
                  fontSize="44px"
                  fontWeight="bold"
                  variant="sectionTitle"
                >
                  {t("home.newsletter.title2")}
                </SSpan>
              </SectionTitle>
              <SText color={ColorWhite}>
                {t("home.newsletter.paragraph1")}
              </SText>
            </Content>
          </NewsLetterContentWrapper>
          <NewsLetterFormWrapper>
            <Content>
              <NewsLetterInputWrapper>
                <InputNewsLetter
                  placeholder={t("home.newsletter.inputText")}
                  onChange={handleEmail}
                  value={email}
                />
                <SButton
                  title="Suscribirme"
                  onClick={validateForm}
                  variant="accent"
                  style={{
                    height: "52px", 
                    padding: "12px 22px",
                    width: isMobile ? '100%' : undefined,
                  }}
                >
                  {t("home.newsletter.buttonText")}
                </SButton>
              </NewsLetterInputWrapper>
              <NewsLetterChecktWrapper style={isMobile ? {marginTop: Spacing.baseSpacing} : undefined}>
                <CheckBox
                  isChecked={termsAccepted}
                  text={t("home.newsletter.acceptTyC")}
                  onChange={handleCheck}
                />
              </NewsLetterChecktWrapper>
            </Content>
          </NewsLetterFormWrapper>
        </NewsLetterHolder>
      </Container>
      {loading && <Loader />}
    </NewsLetterWrapper>
  );
};

export default NewsLetter;
