import gsap from "gsap";
import Office from "molecules/office/Office";
import React, { useEffect } from "react";
import { Content } from "src/design/containers/Container";
import { ColorGray } from "theme";
import {
  OfficesHolder,
  OfficesItem,
  OfficesList,
  OfficesWrapper,
} from "./Offices.components";
import { OfficesProps } from "./Offices.types";
import { useOfficesController } from "./Offices.controller";

const Offices: React.FC<OfficesProps> = ({
  justifyContent,
  titleTextColor,
  titleFontSize,
  titleFontWeight,
  subtitleTextColor,
  subtitleFontSize,
  subtitleFontWeight,
  contentFontSize,
  isAnimated,
  gap,
  isShowContactInfo,
}) => {
  const {headquarters} = useOfficesController();

  useEffect(() => {
    gsap.to(".offices-headquarters-wrapper .offices-item", {
      opacity: 1,
      top: 0,
      duration: 0.5,
      scrollTrigger: ".offices-item",
      stagger: 0.5,
    });
  }, []);

  return (
    <OfficesWrapper>
      <Content>
        <OfficesHolder>
          <OfficesList gap={gap} justifyContent={justifyContent}>
            {headquarters.map((office, i) => (
              <OfficesItem
                key={`${i}-${office.headQuarterName}`}
                isAnimated={isAnimated}
              >
                <Office
                  titleTextColor={titleTextColor}
                  titleFontSize={titleFontSize}
                  titleFontWeight={titleFontWeight}
                  subtitleFontSize={subtitleFontSize}
                  subtitleTextColor={subtitleTextColor}
                  subtitleFontWeight={subtitleFontWeight}
                  contentFontSize={contentFontSize}
                  textColor={ColorGray}
                  country={office.country}
                  officeName={office.headQuarterName}
                  address={office.address}
                  tel={office.phone}
                  email={office.email}
                  isShowContactInfo={isShowContactInfo}
                />
              </OfficesItem>
            ))}
          </OfficesList>
        </OfficesHolder>
      </Content>
    </OfficesWrapper>
  );
};

export default React.memo(Offices);
