import React from "react";
import { imgBlogHeader, imgBlogHeaderMobile, imgNoticiasHeader } from "images";
import ScreenHeader from "molecules/screenHeader/ScreenHeader";
import { Container, Content } from "src/design/containers/Container";
import PostList from "organisms/postList/PostList";
import { BlogColumnWrapper, BlogHolder, BlogWrapper } from "./Blog.components";
import BlogSidebar from "organisms/blogSidebar/BlogSidebar";
import PaginationPosts from "molecules/paginationPosts/PaginationPosts";
import { SectionName } from "custom-types/design/molecules/ScreenHeader";
import { SectionTitle } from "atoms/text/Text";
import SearchPostForm from "molecules/searchPostForm/SearchPostForm";
import { isMobileDevice } from "utils/isMobileDevice";
import { useTranslation } from "react-i18next";

const Blog: React.FC = () => {
  const {t} = useTranslation();
  const isMobile = isMobileDevice();

  return (
    <BlogWrapper>
      <ScreenHeader
        image={isMobile ? imgBlogHeaderMobile : imgBlogHeader}
        title={t('blog.header.title')}
        subtitle={t('blog.header.subtitle')}
        sectionName={SectionName.NOTICIAS}
      />
      <Container>
        <Content>
          <BlogHolder>
            <BlogColumnWrapper>
              {isMobile ? (<SearchPostForm />) : null }
              <SectionTitle style={{marginTop: '18px'}}>
                {t('blog.subheader')}
              </SectionTitle>
              <PostList />
            </BlogColumnWrapper>
            <BlogColumnWrapper>
              <BlogSidebar />
            </BlogColumnWrapper>
          </BlogHolder>
          <PaginationPosts />
        </Content>
      </Container>
    </BlogWrapper>
  );
};

export default Blog;
