import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";

interface ServiceTitleWrapperProps {
  isUnderScreenHeader?: boolean;
}

export const ServiceTitleWrapper = styled.div.attrs((props)=>({
  className: "service-title-wrapper"
}))<ServiceTitleWrapperProps>`
  padding-bottom: 150px;

  @media (max-width: ${Breakpoints.lg}){
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const ServiceTitleContenWrapper = styled.div.attrs((props)=>({
  className: "service-title-conten-wrapper"
}))`
  width: 40%;

  @media (max-width: ${Breakpoints.lg}){
    width: 100%;
  }
`;