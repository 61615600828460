import { LanguageCodes } from 'molecules/selectLanguage/SelectLanguage.types';
import type {CountryContext} from 'react-svg-worldmap';
import { secondaryColor, whiteColor } from 'theme';

export enum COUNTRIES_WITH_PROJECTS {
  MX = "MX",
  AR = "AR",
  GT = "GT",
  DO = "DO",
  CL = "CL",
  UY = "UY",
};

export const projectsByCountryCode: COUNTRIES_WITH_PROJECTS[] = [
  COUNTRIES_WITH_PROJECTS.MX,
  COUNTRIES_WITH_PROJECTS.AR,
  COUNTRIES_WITH_PROJECTS.GT,
  COUNTRIES_WITH_PROJECTS.DO,
  COUNTRIES_WITH_PROJECTS.CL,
  COUNTRIES_WITH_PROJECTS.UY,
];

// Spanish translation of country names
const spanishLocalizedCountryDictionary = new Map<string, string>([
  ["mx", "México"],
  ["ar", "Argentina"],
  ["gt", "Guatemala"],
  ["do", "República Dominicana"],
  ["cl", "Chile"],
  ["uy", "Urugay"],
]);

// English translation of country names
const englishLocalizedCountryDictionary = new Map<string, string>([
  ["mx", "Mexico"],
  ["ar", "Argentina"],
  ["gt", "Guatemala"],
  ["do", "Dominican Republic"],
  ["cl", "Chile"],
  ["uy", "Urugay"],
]);

export const mapDefaultStyles = (countryContext: CountryContext, countrySelected?: string) => {
  const {countryCode, countryValue, color} = countryContext;
  return {
    ...countryContext,
    fill: countryCode === countrySelected ? secondaryColor : countryValue ? secondaryColor : color,
    stroke: whiteColor,
    strokeWidth: 1,
    // fillOpacity: countryCode === countrySelected ? 1 : countryValue,
    cursor: 'pointer',
  };
};

export const getTooltipText = (countryContext: CountryContext, languageCode: LanguageCodes): string => {
  const {countryCode} = countryContext;
  return languageCode === LanguageCodes.ES ? 
  `${spanishLocalizedCountryDictionary.get(countryCode.toLocaleLowerCase()) ?? "Unknown"}` 
  : `${englishLocalizedCountryDictionary.get(countryCode.toLocaleLowerCase()) ?? "Unknown"}`;
};