import React from 'react';
import { Caption, SectionTitle } from 'atoms/text/Text';
import { ColorGray, ColorRed } from 'theme';
import { SubsectionTitleProps } from 'custom-types/design/molecules/SubsectionTitle';

const SubsectionTitle: React.FC<SubsectionTitleProps> = ({caption, captionColor, titleA, titleB, titleColorA, titleColorB}) => {
  return (
    <>
      <Caption color={captionColor || ColorGray}>
        {caption}
      </Caption>
      <SectionTitle
        color={titleColorA || undefined}
      >
        {titleA} <br /> <SectionTitle 
          color={titleColorB || ColorRed}
        >
          {titleB}
        </SectionTitle>
      </SectionTitle>
    </>
  )
}

export default SubsectionTitle