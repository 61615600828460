import { TypePostItem } from "custom-types/design/molecules/PostItem";
import styled from "styled-components";
import { backgroundDarkColor } from "theme";

type CategoryLabelWapperProps = {
    typePostItem?: TypePostItem;
};

export const CategoryLabelWapper = styled.div.attrs((props)=>({
    className: 'category-label-wrapper'
}))<CategoryLabelWapperProps>`
    background: ${backgroundDarkColor};
    padding: 17px;
    height: 55px;
    width: ${props => props.typePostItem === TypePostItem.CAROUSEL ? '100%' : '188px'};
`;