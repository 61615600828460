import styled from "styled-components";
import { ColorWhite, Spacing } from "theme";

export const BlogSideBarListWrapper = styled.div.attrs((props)=>({
    className: 'blog-side-bar-list-wrapper'
}))`
    background: ${ColorWhite};
    padding: ${Spacing.xLargeSpacing};
    margin-top: ${Spacing.largeSpacing};
`;

export const BlogSideBarListTitleWrapper = styled.div.attrs((props)=>({
    className: 'blog-sidebar-list-title-wrapper'
}))`
    margin-bottom: ${Spacing.baseSpacing};
`;