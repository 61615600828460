import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { ColorLightGrayB, Spacing, backgroundDarkColor } from "theme";

export const ValueAddedwrapper = styled.section.attrs((props)=>({
  className: 'value-added-wrapper'
}))`
  background-color: ${ColorLightGrayB};
  padding: 100px 0;

  @media (max-width: ${Breakpoints.lg}){
    background-color: ${backgroundDarkColor};
    display: block;
    padding: 100px 0 0;
  }
`;

export const ValueAddedHolder = styled.div.attrs((props)=>({
  className: 'value-added-holder'
}))`
  display: flex;

  @media (max-width: ${Breakpoints.xl}){
    display: block;
  }
`;

export const ValueAddedContentWrapper = styled.div.attrs((props)=>({
  className: 'value-added-content-wrapper'
}))`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const ValueAddedContentHolder = styled.div.attrs((props)=>({
  className: 'value-added-content-holder'
}))`
  

  @media (max-width: ${Breakpoints.xl}){
    padding: 0;
  }
`;

export const ValueAddedImgWrapper = styled.div.attrs((props)=>({
  className: 'value-added-img-wrapper'
}))`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: ${Breakpoints.lg}){
    display: block;
  }
`;

export const ValueAddedImg = styled.img.attrs((props)=>({
  src: props.src
}))`
  width: 491px;
  height: 559px;
  position: relative;

  @media (max-width: ${Breakpoints.xl}){
    width: 80%;
    height: auto;
  }

  @media (max-width: ${Breakpoints.lg}){
    width: 100%;
    height: auto;
  }
`;