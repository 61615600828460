import React from "react";
import { SText, TextOSL, TextOSB, TextOSSB } from "atoms/text/Text";
import { OfficeType } from "custom-types/design/molecules/Office";
import { ColorWhite } from "theme";
import { OfficeWrapper } from "./Office.components";

const Office: React.FC<OfficeType> = ({
  titleTextColor,
  titleFontSize,
  titleFontWeight,
  subtitleTextColor,
  subtitleFontSize,
  subtitleFontWeight,
  contentFontSize,
  country,
  officeName,
  address,
  tel,
  email,
  textColor,
  isShowContactInfo = true,
}) => {
  return (
    <OfficeWrapper>
      <TextOSSB
        color={titleTextColor}
        fontSize={titleFontSize}
        fontWeight={titleFontWeight}
      >
        {country}
      </TextOSSB>
      <TextOSB
        color={subtitleTextColor}
        fontSize={subtitleFontSize}
        fontWeight={subtitleFontWeight}
      >
        {officeName}
      </TextOSB>
      <TextOSL 
        lineHeight="22px"
        fontSize={contentFontSize}
      >
          {address}
        </TextOSL>
      {isShowContactInfo ? (
        <div style={{marginTop: '36px'}}>
          {tel && (
            <SText
              color={textColor || ColorWhite}
              marginBottom="0px"
              fontSize={contentFontSize}
            >
              {tel}
            </SText>
          )}
          {email && (
            <SText 
              color={textColor || ColorWhite}
              marginBottom="0px"
              fontSize={contentFontSize}
            >
              {email}
            </SText>
          )}
        </div>
      ) : null}
    </OfficeWrapper>
  );
};

export default Office;
