import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { Content, FullContainer } from 'src/design/containers/Container';
import { ContentHolder, ContentWrapper, ImageMobile, Img, ImgWrapper } from './ImageWithContent.components';
import SButton from 'atoms/button/Button';
import { ImageWithContentType } from 'custom-types/design/molecules/ImageWithContent';
import { gsap } from 'gsap';
import { SSpan } from 'atoms/text/Text';

const ImageWithContent: React.FC<ImageWithContentType> = ({
  backgroundColorContent, 
  image, 
  placeImage,
  title, 
  caption,
  captionColor,
  content, 
  showButton, 
  textButton, 
  variantButton,
  isAnimated = true,
  onPressButton,
  isSwitchMobile = false,
  imageId,
}) => {
  const imgLeftRef = useRef(null);
  const imgRightRef = useRef(null);

  useLayoutEffect(()=>{
    if(imgLeftRef.current){
      gsap.fromTo(imgLeftRef.current, {left: '-100%', opacity: 0}, {left: 0, opacity: 1, scrollTrigger: {trigger: `#${imageId}`, start: 'top center'}});
    }
    if(imgRightRef.current){
      gsap.fromTo(imgRightRef.current, {right: '-100%', opacity: 0}, {right: 0, opacity: 1, scrollTrigger: {trigger: `#${imageId}`, start: 'top center'}});
    }
  }, [imageId, isAnimated]);

  // useEffect(()=>{
  //   if(isAnimated){
  //     gsap.fromTo(imgRef.current, {opacity: 0, marginLeft: '-100%', duration: 1.5}, {opacity: 1, marginLeft: 0, scrollTrigger: {
  //       trigger: ".content-wrapper",
  //       start: 'top top+=70px',
  //     }});
  //   }
  // },[isAnimated]);

  return (
    <FullContainer>
      <ContentWrapper backgroundColor={backgroundColorContent}>
        {
          image && placeImage === 'left' &&
          <ImgWrapper isSwitchMobile={isSwitchMobile}>
            <Img id={imageId} ref={imgLeftRef} src={image} />
          </ImgWrapper>
        }
        <ContentHolder
          isSwitchMobile={isSwitchMobile}
        >
          {
            caption &&
            <SSpan
              color={captionColor}
              fontSize='14px'
              fontWeight='bold'
            >
            {caption}
            </SSpan>
          }
          <Content>
            {title}
          </Content>
          {isSwitchMobile ? (
            <ImageMobile src={image} alt=''/>
          ) : undefined}
          <Content>
            {content}
            {
              showButton &&
              <SButton
                title={textButton} 
                onClick={onPressButton}
                variant={variantButton || 'primary-white'}
              >
                {textButton || ''}
              </SButton>
            }
          </Content>
        </ContentHolder>

        {
          image && placeImage === 'right' &&
          <ImgWrapper isSwitchMobile={isSwitchMobile}>
            <Img ref={imgRightRef} src={image} />
          </ImgWrapper>
        }
      </ContentWrapper>
    </FullContainer>
  )
}

export default ImageWithContent