import React from 'react';
import { SectionTitle, SText } from 'atoms/text/Text'
import { blackColor, ColorRed, Spacing, whiteColor } from 'theme'
import { MoveWorldWrapper } from './MoveWorld.components'
import { imgAerogeneradorPaisaje } from 'images';
import ImageWithContent from 'molecules/imageWidthContent/ImageWithContent';
import { useNavigate } from 'react-router-dom';
import { isMobileDevice } from 'utils/isMobileDevice';
import { useTranslation } from 'react-i18next';
import { PATH_NAMES } from 'organisms/mainMenu/MainMenu.types';

const MoveWorld: React.FC = () => {
  const {i18n, t} = useTranslation();
  const navigate = useNavigate();
  const isMobile = isMobileDevice();

  return (
    <MoveWorldWrapper>
      <ImageWithContent
        imageId='move-world'
        isSwitchMobile
        backgroundColorContent={isMobile ? whiteColor : undefined}
        isAnimated={false}
        placeImage='left'
        image={imgAerogeneradorPaisaje}
        title={
          <SectionTitle
            fontWeight='bold'
            color={isMobile ? blackColor : whiteColor}
            style={{
              marginBottom: Spacing.largeSpacing,
            }}
          >
            {t('home.moveTheWorld.title1')}<br /> <SectionTitle color={ColorRed}>
              {t('home.moveTheWorld.title2')}
            </SectionTitle>
          </SectionTitle>
        }
        content={
          <>
            <SText
              color={isMobile ? blackColor : whiteColor}
              style={{
                marginBottom: Spacing.largeSpacing
              }}
            >
              {t('home.moveTheWorld.paragraph1')}
            </SText>
            {i18n.exists('home.moveTheWorld.paragraph2') ? (
              <SText
                color={isMobile ? blackColor : whiteColor}
                style={{
                  marginBottom: Spacing.largeSpacing
                }}
              >
                {t('home.moveTheWorld.paragraph2')}
              </SText>
            ) : null}
          </>
        }
        showButton
        textButton={t('home.moveTheWorld.buttonText')}
        variantButton={isMobile ? 'primary' : 'primary-white'}
        onPressButton={()=>{
          navigate(PATH_NAMES.PROJECTS);
        }}
      />
    </MoveWorldWrapper>
  )
}

export default MoveWorld