import { Breakpoints } from "src/theme/breakpoints";
import styled from "styled-components";
import { Spacing } from "theme";

type BrochureItemWrapperProps = {
    imgBackground?: string;
};

export const BrochureItemWrapper = styled.div.attrs((props)=>({
    className: 'brochure-item-wrapper'
}))<BrochureItemWrapperProps>`
    background: url(${props => props.imgBackground}) top center no-repeat;
    background-size: cover;
    margin-bottom: ${Spacing.largeSpacing};
    max-width: 1252px;
    padding: 100px 96px;
    height: 341px;

    @media (max-width: ${Breakpoints.lg}){
        height: auto;
        padding: 0;
    }
`;

export const BrochureItemHolder = styled.div.attrs((props)=>({
    className: 'brochure-item-holder'
}))`
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 127px;

    @media (max-width: ${Breakpoints.lg}){
        grid-template-columns: 1fr;
        gap: 0;
    }
`;

export const BrochureItemContent = styled.div.attrs((props)=>({
    className: 'brochure-item-content'
}))`
    align-self: center;
`;